import { Gap } from "components/Layout";
import { set } from "lodash";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect/dist/core";

const PURPLE = "#7f49f3";

const STAT = {
  finalValue: 21,
  unit: "x",
  subTitle: "Faster",
  description: "Creating concise answers at a speed for better business.",
};

const TEXT =
  "Generative AI is a cutting-edge technology that enables machines to produce content, such as images, text, music, and even videos, that mimic human creativity. By leveraging sophisticated algorithms, generative AI systems learn patterns from vast amounts of data and then generate new content based on those patterns.";

const Container = styled.div`
  display: grid;
  gap: 10px;
  grid-template-columns: 1fr 1fr;
  height: 550px;
`;

const Panel = styled.div`
  padding: 12px 20px;
  background-color: #eaeaea;
  border-radius: 10px;
  line-height: 1.5;
  white-space: pre-wrap;
`;

const PanelTitle = styled.div`
  font-weight: 600;
  padding-bottom: 10px;
  white-space: nowrap;
  color: #497ff3;
  ${props => props.isGpt && `color: ${PURPLE};`}
`;

const Stat = styled.div`
  background-color: white;
  padding: 32px;
  border-radius: 10px;
  display: grid;
  align-content: center;
`;

const StatTitle = styled.div`
  font-size: 100px;
  font-weight: 500;
  * {
    font-size: 100px;
    font-weight: 500;
  }

  display: grid;
  grid-template-columns: 100px auto;
`;

const StatSubTitle = styled.div`
  font-size: 56px;
  font-weight: 500;
`;

const StatFourCols = styled.div`
  display: grid;
  /* grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-items: end; */
`;

const StatDescription = styled.div`
  font-size: 28px;
  margin-top: 10px;
  line-height: 1.2;
`;

const TwoRows = styled.div`
  display: grid;
  gap: 10px;
`;

const PromptText = styled.div`
  font-size: 18px;
  color: #747474;
  text-align: left;
  line-height: 1.5;
  height: 2em;
  * {
    font-size: 18px;
    background-image: linear-gradient(135deg, #497ff3 0%, #497ff388 20%, #497ff3 40%, #497ff388 90%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  background-image: linear-gradient(135deg, #497ff3 0%, #497ff388 20%, #497ff3 40%, #497ff388 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const PromptTextGpt = styled(PromptText)`
  color: #747474;
  * {
    font-size: 18px;
    background-image: none;
    background-clip: none;
    -webkit-text-fill-color: #747474;
  }

  background-image: none;
  background-clip: none;
  -webkit-text-fill-color: #747474;
`;

const MS_PER_LETTER = 1;
const MS_PER_LETTER_GPT = 80;

const MS_PAUSE = 1000;

const SpeedAnimation = () => {
  const [n, setN] = useState(0);
  const [statValue, setStatValue] = useState(0);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
  const statRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (statRef.current) {
        const rect = statRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setHasScrolledIntoView(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!hasScrolledIntoView) {
      setStatValue(0);
      return;
    }

    const intervalId = setInterval(() => {
      setStatValue(prev => {
        if (prev >= STAT.finalValue) {
          clearInterval(intervalId);
          return STAT.finalValue;
        }
        return prev + 1;
      });
    }, 40);

    return () => clearInterval(intervalId);
  }, [hasScrolledIntoView]);

  useEffect(() => {
    const newTypewriter = new Typewriter("#text-bz");
    newTypewriter.changeDelay(MS_PER_LETTER).typeString(TEXT).pauseFor(MS_PAUSE).start();

    const newTypewriterGpt = new Typewriter("#text-gpt");
    newTypewriterGpt
      .changeDelay(MS_PER_LETTER_GPT)
      .typeString(TEXT)
      .pauseFor(MS_PAUSE)
      .callFunction(() => setN(prev => prev + 1))
      .start();

    return () => {
      newTypewriter.stop();
      newTypewriterGpt.stop();
    };
  }, [n]);

  return (
    <Container>
      <Stat ref={statRef}>
        <StatFourCols>
          <StatTitle>
            <div>{statValue}</div>
            <div>{STAT.unit}</div>
          </StatTitle>
          <StatSubTitle>{STAT.subTitle}</StatSubTitle>
        </StatFourCols>
        <Gap />
        <StatDescription>{STAT.description}</StatDescription>
      </Stat>

      <TwoRows>
        <Panel style={{ backgroundColor: "#497ff322" }}>
          <PanelTitle>Boltzbit LLM</PanelTitle>
          <PromptText id="text-bz"></PromptText>
        </Panel>
        <Panel>
          <PanelTitle isGpt>Other GPT</PanelTitle>
          <PromptTextGpt id="text-gpt"></PromptTextGpt>
        </Panel>
      </TwoRows>
    </Container>
  );
};

export default SpeedAnimation;
