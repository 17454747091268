import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { clamp, max, min } from "lodash";
import { BoltzflowLogoInner, BoltzhubLogoInner } from "components/ui/Icons";

const SCROLL_HEIGHT = 2000;

const LongContainer = styled.div`
  position: relative;
  height: ${SCROLL_HEIGHT}px;
`;

const MessageLine = styled.div`
  font-size: 42px;
  font-weight: 700;
  padding: 4px 0;
  transition: opacity 0.4s ease-in-out;
`;

const MessageLinePrimaryColor = styled(MessageLine)`
  color: ${props => props.theme.color.primary};
`;

const Description = styled.div`
  position: absolute;
  z-index: 1;

  font-weight: 500;
  line-height: 1.7;
  font-size: 25px;
`;

const DescriptionUnderlay = styled(Description)`
  position: absolute;
  z-index: 0;
`;

const DesciptionContainer = styled.div`
  position: relative;
  width: 70%;
  height: 300px;
`;

const MessageSectionContainer = styled.div`
  position: relative;
  top: 0;
  display: grid;
  justify-items: center;
  align-content: center;
  width: 100%;
  min-height: 100vh;
  transition: opacity 0.4s ease-in-out;
  margin-top: 52px;
  overflow: hidden;
  width: 100vw;
`;

const StyledBoltzflowLogoInner = styled(BoltzflowLogoInner)`
  opacity: 0.25;
  position: absolute;
  bottom: -89px;
  right: 100px;
  height: 400px;
  fill: url(#SvgGradient);
`;

const StyledBoltzhubLogoInner = styled(BoltzhubLogoInner)`
  opacity: 0.25;
  position: absolute;
  bottom: -89px;
  right: 100px;
  height: 400px;
  fill: url(#SvgGradient);
`;

const mapValue = (domain = [], range = [], value) => {
  const [domainStart, domainEnd] = domain;
  const [rangeStart, rangeEnd] = range;

  const domainLength = domainEnd - domainStart;
  const rangeLength = rangeEnd - rangeStart;

  const domainValue = value - domainStart;
  const ratio = domainValue / domainLength;
  const rangeValue = rangeLength * ratio;
  const valueInRange = rangeStart + rangeValue;

  return clamp(valueInRange, min(range), max(range));
};

const AnimatedContent = ({ messageLine1 = "", messageLine2 = "", description = "" }) => {
  const [progress, setProgress] = useState(0);
  const containerRef = useRef(null);

  const rect = containerRef.current?.getBoundingClientRect() || {};

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, [rect.top]);

  const onScroll = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    const newProgress = mapValue([0, -SCROLL_HEIGHT + window.innerHeight], [0, 1], rect.top);
    setProgress(newProgress);
  };

  const { top } = rect;
  const hasReachedTop = top <= 0;

  let opacityMsg1 = progress >= 0.12 ? 1 : 0;
  let opacityMsg2 = progress >= 0.2 ? 1 : 0;

  const opacityDescriptionUnderlay = mapValue([0.3, 0.5], [0.2, 1], progress);
  let endIndex = Math.round(mapValue([0.4, 0.9], [0, description?.length], progress));
  endIndex = clamp(endIndex, 0, description?.length);

  let bottomStyles = {};
  if (progress >= 1) {
    bottomStyles = {
      position: "absolute",
      top: `calc(${SCROLL_HEIGHT}px - 100vh)`,
      height: "100vh",
    };
  }

  const isHub = window.location.pathname.includes("/hub");

  return (
    <LongContainer ref={containerRef}>
      <MessageSectionContainer style={{ position: hasReachedTop ? "fixed" : "static", ...bottomStyles }}>
        <MessageLine style={{ opacity: opacityMsg1 }}>{messageLine1}</MessageLine>
        <MessageLinePrimaryColor style={{ opacity: opacityMsg2 }}>{messageLine2}</MessageLinePrimaryColor>
        <DesciptionContainer>
          <Description
            style={{ marginTop: "60px" }}
            dangerouslySetInnerHTML={{ __html: description?.slice(0, endIndex) }}
          />
          <DescriptionUnderlay
            style={{ marginTop: "60px", color: "#dfdfdf", opacity: opacityDescriptionUnderlay }}
            dangerouslySetInnerHTML={{ __html: description }}
          />
        </DesciptionContainer>
        {isHub ? <StyledBoltzhubLogoInner /> : <StyledBoltzflowLogoInner />}
      </MessageSectionContainer>
    </LongContainer>
  );
};

export default AnimatedContent;
