import styled from "styled-components";
import { useEffect, useRef, useState } from "react";

import { clamp, max, min } from "lodash";
import OptionsSlidesWithProgressBarMinimal from "components/OptionsSlidesWithProgressBarMinimal";

const mapValue = (domain = [], range = [], value) => {
  const [domainStart, domainEnd] = domain;
  const [rangeStart, rangeEnd] = range;

  const domainLength = domainEnd - domainStart;
  const rangeLength = rangeEnd - rangeStart;

  const domainValue = value - domainStart;
  const ratio = domainValue / domainLength;
  const rangeValue = rangeLength * ratio;
  const valueInRange = rangeStart + rangeValue;

  return clamp(valueInRange, min(range), max(range));
};

const Container = styled.div`
  position: relative;
  top: 0;
  padding: 0;
  min-height: 100vh;
  width: 100%;
  background: ${props => props.background};

  display: flex;
  align-items: center;
`;

const SCROLL_HEIGHT = 2000;

const LongContainer = styled.div`
  position: relative;
  height: ${SCROLL_HEIGHT}px;
`;

const ScrollerMinimal = ({ textAlignment = "left", options, colorScheme }) => {
  const [progressPercentage, setProgressPercentage] = useState(0);
  const containerRef = useRef();

  const onScroll = () => {
    const rect = containerRef.current?.getBoundingClientRect();
    const newProgress = mapValue([0, -SCROLL_HEIGHT + window.innerHeight], [0, 100], rect.top);
    setProgressPercentage(newProgress);
  };

  useEffect(() => {
    window.history.scrollRestoration = "manual";
  }, []);

  useEffect(() => {
    document.addEventListener("scroll", onScroll);
    return () => document.removeEventListener("scroll", onScroll);
  }, []);

  const rect = containerRef.current?.getBoundingClientRect() || {};
  const { top } = rect;
  const hasReachedTop = top < 0;

  let bottomStyles = {};
  if (progressPercentage >= 100) {
    bottomStyles = {
      position: "absolute",
      top: `calc(${SCROLL_HEIGHT}px - 100vh)`,
      height: "100vh",
    };
  }

  return (
    <LongContainer ref={containerRef}>
      <Container
        background={colorScheme?.background}
        style={{ position: hasReachedTop ? "fixed" : "static", opacity: 1, ...bottomStyles }}
      >
        <OptionsSlidesWithProgressBarMinimal
          textAlignment={textAlignment}
          colorScheme={colorScheme}
          options={options}
          progressPercentage={progressPercentage}
          onOptionNavClick={optionIndex => {
            const newScrollY = containerRef?.current?.offsetTop + SCROLL_HEIGHT * (optionIndex / (options.length + 1));
            window.scrollTo({ top: newScrollY });
          }}
        />
      </Container>
    </LongContainer>
  );
};

export default ScrollerMinimal;
