import ButtonDark from "components/ButtonDark";
import { isNil } from "lodash";
import { useRef } from "react";
import { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";
// import Typewriter from "typewriter-effect";
import Typewriter from "typewriter-effect/dist/core";

const Container = styled.div`
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 40px;
  color: white;

  background-image: linear-gradient(
    135deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
`;

const Frame = styled.div`
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 20px;

  border: 0.2px solid #8b8b8b;
  padding: 60px 100px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 4px #ffffff44;
`;

const StaticText = styled.div`
  font-size: 36px;
  font-weight: 200;
  text-align: left;
  color: #9a9a9a;
  line-height: 1.2;
`;

const PromptText = styled.div`
  font-size: 36px;
  font-weight: 200;
  text-align: left;
  width: 800px;
  line-height: 1.5;
  height: 2em;
  * {
    font-size: 36px;
  }
`;

const Tip = styled.div`
  position: absolute;
  top: 52px;
  left: 0;
  opacity: 0;
  font-size: 12px;
  white-space: nowrap;
`;

const TopButtonsContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 90px;
  justify-content: center;
  justify-items: center;
`;

// const Button = styled.button`
//   position: relative;
//   z-index: 1;
//   padding: 12px 60px;
//   color: #fff;
//   border: 1px solid #373737;
//   cursor: pointer;
//   font-size: 20px;
//   font-weight: 200;
//   font-family: "Montserrat", sans-serif;
//   background-color: #000000;
//   box-shadow: 0px 0px 20px 4px ${props => (props.isDisabled ? "#90909044" : "#ffffff44")};

//   margin-top: 40px;
//   justify-self: center;

// `;

const MS_PER_LETTER = 20;
const MS_PAUSE = 3000;
const MS_DELETE = 4;

// const FLOW_BASE_URL = "http://localhost:5000";
// const FLOW_BASE_URL = "https://ocr.boltztest.com";
const FLOW_BASE_URL = "https://flow.boltzbit.com";

const PromptsSection = ({ title, prompts = [], templates = [] }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const containerRef = useRef(null);

  const [isTyping, setIsTyping] = useState(true);
  const [indexBeingTyped, setIndexBeingTyped] = useState(0);

  useEffect(() => {
    const promptIndex = parseInt(searchParams?.get("promptIndex"));
    if (isNaN(promptIndex)) {
      return;
    }

    searchParams.delete("promptIndex");
    navigate(`?${searchParams.toString()}`);

    containerRef.current.scrollIntoView({ behavior: "smooth" });
    setIndexBeingTyped(promptIndex);
  }, [searchParams?.get("promptIndex")]);

  useEffect(() => {
    if (!templates?.length) {
      return;
    }

    setIsTyping(true);
    const newTypewriter = new Typewriter("#prompt-text");
    newTypewriter
      .changeDelay(MS_PER_LETTER)
      .typeString(templates?.[indexBeingTyped]?.prompt)
      .callFunction(() => setIsTyping(false))
      .pauseFor(MS_PAUSE)
      .callFunction(() => setIsTyping(true))
      .deleteAll(MS_DELETE)
      .callFunction(() => {
        setIndexBeingTyped(prev => (prev + 1) % templates?.length);
      })
      .start();

    return () => newTypewriter.stop();
  }, [JSON.stringify(templates), indexBeingTyped, searchParams?.get("promptIndex")]);

  return (
    <Container ref={containerRef}>
      <TopButtonsContainer>
        {templates?.map((template, i) => (
          <ButtonDark
            onClick={() => {
              searchParams.set("promptIndex", i);
              navigate(`?${searchParams.toString()}`);
            }}
            isActive={i === indexBeingTyped}
            key={i}
          >
            {template?.wordDocTemplate?.fileName}
          </ButtonDark>
        ))}
      </TopButtonsContainer>

      <Frame>
        <StaticText>{title}</StaticText>
        <PromptText id="prompt-text"></PromptText>
        <ButtonDark
          style={{
            marginTop: "90px",
            justifySelf: "center",
          }}
          isDisabled={isTyping}
          onClick={() => {
            const templateId = templates?.[indexBeingTyped]?._id;
            window.open(`${FLOW_BASE_URL}/flow-dashboard-templates/${templateId}/resolve`, "_blank");
          }}
        >
          See for yourself
          {/* <Tip>{templates?.[indexBeingTyped]?.prompt}</Tip> */}
        </ButtonDark>
      </Frame>
    </Container>
  );
};

export default PromptsSection;
