import ButtonV1 from "components/ButtonV1";
import ContactUsModal from "components/ContactUsModal";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-items: center;
  padding: 40px 0;
  padding-bottom: 100px;
`;

const JoinBtn = styled(ButtonV1)`
  margin: 0 auto;
  margin-top: 40px;
  z-index: 2;
`;

const SubTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  padding-top: 20px;
  text-align: center;
  z-index: 1;

  color: #424242;

  @media (min-width: 1240px) {
    font-size: 24px;
    line-height: 1.1;
  }
`;

const ContactUsSection = () => {
  return (
    <Container>
      <SubTitle>Coming soon.</SubTitle>
      <ContactUsModal
        product="launch-api"
        fieldNames={["name", "email"]}
        triggerComponent={<JoinBtn isPrimary>Join waitlist</JoinBtn>}
      />
    </Container>
  );
};

export default ContactUsSection;
