import { Gap } from "components/Layout";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  /* @media (min-width: 1240px) {
    display: block;
  } */

  position: relative;
  color: black;
  background-color: white;
  width: 100vw;
`;

const Content = styled.div`
  display: grid;
  align-content: center;
  gap: 60px;
  margin: 0 auto;
  padding: 80px 20px;
  margin: 0 auto;

  @media (min-width: 1240px) {
    padding: 80px 0;
    width: 1200px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 500;
  color: #747474;

  @media (min-width: 1240px) {
    font-size: 51.41px;
    line-height: 56px;
  }
`;

const WordsLine = styled.div`
  display: flex;
  gap: 10px;
  left: 0;
  padding-bottom: 10px;
  max-width: calc(100vw - 40px);
  flex-wrap: wrap;

  @media (min-width: 1240px) {
    max-width: none;
    flex-wrap: nowrap;
    justify-content: center;
  }
`;

const Word = styled.div`
  gap: 10px;
  box-sizing: border-box;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  display: flex;
  align-items: center;
  text-align: center;
  cursor: default;
  transition: color 0.2s, opacity 0.2s;
  z-index: 1;
  :hover {
    color: #497ff3;
    opacity: 1;
  }

  color: #4f4f4f;
  opacity: ${props => props.alpha};

  white-space: nowrap;

  font-size: ${props => 22}px;
  @media (min-width: 1240px) {
    font-size: ${props => 30 * props.m}px;
  }
`;

const Svg = styled.svg`
  position: absolute;
  width: 100vw;
  top: 40px;
  left: 40px;
  z-index: 0;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const Cloud = styled.div`
  margin-top: 40px;
  margin-bottom: 50px;
`;

const BgGrid = () => (
  <Svg viewBox="0 0 1809 1277" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1806.12 123.167L0 119.431M1806.84 385.967L0.717053 382.231M1807.56 648.764L1.43865 645.029M1808.28 911.569L2.15798 907.834M1809 1174.37L2.87503 1170.63M900.416 1258.74L902.321 0M699.405 1259.5L701.31 0.759634M498.397 1260.27L500.302 1.52243M297.383 1261.03L299.288 2.28366M96.372 1261.79L98.2771 3.04329M1709.45 1274.72L1711.36 15.9731M1508.44 1275.48L1510.35 16.7359M1307.43 1276.24L1309.33 17.4971M1106.42 1277L1108.32 18.2567"
      stroke="url(#paint0_linear_3219_2050)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_3219_2050"
        x1="-102.402"
        y1="263.193"
        x2="1796.04"
        y2="1535.45"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#747474" stop-opacity="0.12" />
        <stop offset="0.548889" stop-color="#BDBDBD" stop-opacity="0.13" />
        <stop offset="0.804689" stop-color="#D9D9D9" stop-opacity="0.12" />
        <stop offset="0.993312" stop-color="#DADADA" stop-opacity="0.16" />
      </linearGradient>
    </defs>
  </Svg>
);

const WordCloudSection = ({ title = "", subTitle = "", cloudLines = [], fontSizes = [], alphas = [] }) => {
  return (
    <Container>
      <BgGrid />
      <Content>
        <Title
          // style={{ width: "800px" }}
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Cloud>
          {cloudLines.map((line, i) => (
            <WordsLine key={i}>
              {line.map((word, j) => (
                <Word alpha={alphas[i][j]} m={fontSizes[i][j]} key={j}>
                  {word}
                </Word>
              ))}
            </WordsLine>
          ))}
        </Cloud>
        <Title
          style={{ justifySelf: "end", textAlign: "right" }}
          dangerouslySetInnerHTML={{
            __html: subTitle,
          }}
        />
      </Content>
    </Container>
  );
};

export default WordCloudSection;
