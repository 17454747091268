export const theme = {
  breakpoint: {
    lg: "1240px",
    wide: "1570px",
  },
  shadow: "1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12)",
  color: {
    furthest: "#FFFFFF",
    closer0: "#f0f0f0",
    closest: "#24292F",
    closer2: "#1f232c",
    primary: "#e17a4a",
    secondary: "#591B96",
    feature: "#202C2E",
    highlightGrey: "#d7dbe0",
  },
};

export const themeMain = {
  breakpoint: {
    lg: "1240px",
    wide: "1570px",
  },
  shadow: "4px 4px 8px rgba(255, 255, 255, 0.2), 0 0 2px rgba(255, 255, 255, 0.2)",
  color: {
    furthest: "#FFFFFF",
    closer0: "#f0f0f0",
    closest: "#24292F",
    closer2: "#1f232c",

    primary: "#0D1D34",
    secondary: "#000000",

    feature: "#202C2E",
    highlightGrey: "#d7dbe0",
  },
};

export const uploadPageTheme = {
  breakpoint: {
    lg: "1240px",
    wide: "1570px",
  },
  shadow: "1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12)",
  color: {
    furthest: "#FFFFFF",
    closer0: "#f0f0f0",
    closest: "#24292F",
    closer2: "#1f232c",
    primary: "#0191ff",
    secondary: "#591B96",
    feature: "#6f24ba",
    highlightGrey: "#d7dbe0",
  },
};

export const themeHub = {
  breakpoint: {
    lg: "1240px",
    wide: "1570px",
  },
  shadow: "1px 1px 2px rgba(0, 0, 0, 0.14), 0 0 2px rgba(0, 0, 0, 0.12)",
  color: {
    furthest: "#FFFFFF",
    closer0: "#f0f0f0",
    closest: "#24292F",
    closer2: "#1f232c",
    primary: "#0191ff",
    secondary: "#6f24ba",
    feature: "#202C2E",
    highlightGrey: "#d7dbe0",
  },
};
