import ContactUsModal from "components/ContactUsModal";
import TopBar from "components/TopBar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import ButtonV1 from "components/ButtonV1";

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  padding-top: calc(170px + 60px);
  height: 100vh;

  @media (min-width: 1240px) {
    width: 1200px;
    padding: 0;
    padding-top: calc(170px + 60px);
  }
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 1.1;

  background: linear-gradient(92.77deg, #2e2d2d 17.72%, #70747b 66.83%, #787368 77.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (min-width: 1240px) {
    font-size: 90px;
  }
`;

const SubTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  /* or 82% */
  display: flex;
  align-items: center;
  padding-top: 40px;

  color: #747474;

  @media (min-width: 1240px) {
    font-size: 32px;
    line-height: 1.1;
  }
`;

const JoinBtn = styled(ButtonV1)`
  margin-top: 40px;
`;

const ResearchTitleSection = ({
  title = "",
  subTitle = "",
  isShowingContactUs = false,
  isShowingCareersLink = false,
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <TopBar colorScheme="light" />
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      {isShowingContactUs && <ContactUsModal triggerComponent={<JoinBtn>Join waitlist</JoinBtn>} />}
      {isShowingCareersLink && (
        <a href="/careers" style={{ textDecoration: "none", color: "inherit" }} target="_blank" rel="noreferrer">
          <JoinBtn>Join us</JoinBtn>
        </a>
      )}
    </Container>
  );
};

export default ResearchTitleSection;
