import ButtonV1 from "components/ButtonV1";
import FooterSectionV2 from "components/FooterSectionV2";
import TopBarV2 from "components/TopBarV2";
import { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { deleteAllCookies, getCookieByName } from "utils/common";

const ESSENTIAL_COOKIES = [
  {
    name: "cookies_policy",
    purpose: "This cookie is used to remember a user’s choice about cookies on boltzbit.com",
    expires: "1 year",
  },
];

const ANALYTICS_COOKIES = [
  {
    name: "_ga",
    purpose: "Used by Google Analytics to distinguish users.",
    expires: "2 years",
  },
  {
    name: "_ga_W6WGQKC547",
    purpose: "Used by by Google Analytics to persist session state.",
    expires: "1 day",
  },
];

const Container = styled.div`
  padding-top: 120px;
  background-color: #151617;
  color: white;
  line-height: 1.5;
  display: grid;
  justify-content: center;

  * {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const SubTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
`;

const TwoColumns = styled.div`
  display: grid;
  padding: 20px;
  gap: 40px;
  a {
    color: lightblue;
  }

  @media (min-width: 1200px) {
    padding: 0;
    width: 1200px;
    grid-template-columns: 1fr 3fr;
  }
`;

const Td = styled.td`
  padding: 8px;
  border-bottom: 1px solid #696969;
`;

const Th = styled.th`
  border-bottom: 1px solid #696969;
  padding: 8px;
  text-align: left;
  font-weight: 600;
  min-width: 150px;
`;

const Table = styled.table`
  width: 100%;
`;

const StyledButton = styled.button`
  color-scheme: dark;
  font-family: "Montserrat", sans-serif;
  padding: 12px 8px;
`;

const CookiesPage = () => {
  const [areCookiesAccepted, setAreCookiesAccepted] = useState(false);

  useEffect(() => {
    const cookiesPolicy = getCookieByName("cookies_policy");
    setAreCookiesAccepted(cookiesPolicy === "ACCEPTED");
  }, []);

  const doAcceptAll = () => {
    document.cookie = "cookies_policy=ACCEPTED; path=/; max-age=31536000;";
  };

  const doReject = () => {
    deleteAllCookies();
    document.cookie = "cookies_policy=REJECTED; path=/; max-age=31536000;";
  };

  return (
    <Container>
      <TopBarV2 />
      <TwoColumns>
        <Title>Cookies</Title>
        <div>
          <SubTitle>Essential Cookies</SubTitle>
          <Table>
            <tr>
              <Th>Name</Th>
              <Th>Purpose</Th>
              <Th>Expires</Th>
            </tr>
            {ESSENTIAL_COOKIES.map(cookie => (
              <tr>
                <Td>{cookie.name}</Td>
                <Td>{cookie.purpose}</Td>
                <Td>{cookie.expires}</Td>
              </tr>
            ))}
          </Table>
          <br />
          <br />
          <SubTitle>Analytics Cookies</SubTitle>
          <Table>
            <tr>
              <Th>Name</Th>
              <Th>Purpose</Th>
              <Th>Expires</Th>
            </tr>
            {ANALYTICS_COOKIES.map(cookie => (
              <tr>
                <Td>{cookie.name}</Td>
                <Td>{cookie.purpose}</Td>
                <Td>{cookie.expires}</Td>
              </tr>
            ))}
          </Table>
          <br />
          <br />
          <SubTitle>Do you accept analytics cookies?</SubTitle>
          <br />
          <label style={{ marginRight: "20px" }}>
            <input
              type="radio"
              name="analytics"
              checked={areCookiesAccepted}
              onChange={() => setAreCookiesAccepted(true)}
            />{" "}
            Yes
          </label>
          <label>
            <input
              type="radio"
              name="analytics"
              checked={!areCookiesAccepted}
              onChange={() => setAreCookiesAccepted(false)}
            />{" "}
            No
          </label>
          <br />
          <br />
          <StyledButton
            onClick={() => {
              if (areCookiesAccepted) {
                doAcceptAll();
                return;
              }
              doReject();
            }}
            style={{ colorScheme: "dark" }}
          >
            Save cookie preferences
          </StyledButton>
        </div>
      </TwoColumns>
      <FooterSectionV2 />
    </Container>
  );
};

export default CookiesPage;
