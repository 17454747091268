import ContactUsModal from "components/ContactUsModal";
import TopBar from "components/TopBar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import ButtonV1 from "components/ButtonV1";
import TopBarV2 from "components/TopBarV2";
import { COLOR1, COLOR2 } from "pages/main-v2";

const Container = styled.div`
  position: relative;
  margin: 0 auto;
  padding: 0 20px;
  /* padding-top: calc(170px + 60px); */
  /* height: 100vh; */
  display: grid;
  justify-items: center;
  align-content: center;
  color: white;

  @media (min-width: 1240px) {
    width: 1200px;
    padding: 0;
    margin-bottom: 0px;
    /* padding-top: calc(170px + 60px); */
  }

  @media (max-width: 1240px) {
    ${props => props.mobileStyle}
  }
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 1.1;

  z-index: 1;

  text-align: center;

  @media (min-width: 1240px) {
    font-size: 60px;
    margin-top: -150px;
  }

  .gradient-text {
    font-size: inherit;
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const SubTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  padding-top: 40px;
  text-align: center;
  z-index: 1;

  color: #dadada;

  @media (min-width: 1240px) {
    font-size: 24px;
    line-height: 1.1;
  }
`;

const JoinBtn = styled(ButtonV1)`
  margin: 0 auto;
  margin-top: 40px;
  z-index: 2;
`;

const Img = styled.img`
  position: absolute;
  width: 100vw;
  bottom: 0px;
`;

const MessageWithBackground = ({
  title = "",
  subTitle = "",
  isShowingContactUs = false,
  style = {
    height: "100vh",
  },
  mobileStyle = {},
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container style={style} mobileStyle={mobileStyle}>
      <TopBarV2 colorScheme="dark" backgroundColor="black" />
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <SubTitle
        dangerouslySetInnerHTML={{
          __html: subTitle,
        }}
      />
      <Img src="/images/waves-squished.png" />
      {isShowingContactUs && (
        <ContactUsModal
          product="launch-careers"
          fieldNames={["name", "email", "message"]}
          triggerComponent={<JoinBtn isPrimary>Get in touch</JoinBtn>}
        />
      )}
    </Container>
  );
};

export default MessageWithBackground;
