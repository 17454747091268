import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import { getWebsiteContentsVersion } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";

const SECTIONS = [
  {
    type: "video",
    messageLine1: "Automate",
    typewriterMessages: ["Data Analysis", "Churn Rate Analysis"],
    messageLine2: "with Your Boltzhub GPT.",
    logo: "hub",
    videoSrc: "/images/boltzhub.mp4",
  },
  {
    type: "animated-content",
    messageLine1: "Only you can build",
    messageLine2: "your ML solution.",
    description:
      "Boltzbit Code-LLM translates <b>natural language command into code to extract data and tailor ML Solutions</b> for your product. Detect outliers, predict trends or identify fraud without the hassle of manual coding.",
  },
  {
    type: "scroller",
    colorScheme: {
      background: "linear-gradient(to bottom, #003b68 0%, #2b0e48 90%)",
      heading: "#ffffff",
      progress: "#ffffff",
      inactiveOptionLink: "#b8b8b8",
    },
    options: [
      {
        imageSources: ["/images/hub/scroller1/slide1/1.png", "/images/hub/scroller1/slide1/2.png"],
        optionName: "Your Auto ML",
        heading: "GPT-Powered Auto ML",
        subHeading: "No Code, Hassle Free.",
        text: "Leveraging pre-trained data and contextual understanding, GPT-powered auto ML produces high-quality code, eliminating the hassle and complexity of manual coding.",
      },
      {
        imageSources: ["/images/hub/scroller1/slide2/1.png", "/images/hub/scroller1/slide2/2.png"],
        optionName: "Your Instant Deployment ",
        heading: "Deploy any ML Code instantly.",
        subHeading: "Automatic results for everyone.",
        text: "Our No Code/Low Code environment empowers non-technical Data Analysts to effortlessly transform data into valuable insights, enabling efficient decision-making without coding knowledge.",
      },
      {
        imageSources: ["/images/hub/scroller1/slide3/1.png", "/images/hub/scroller1/slide3/2.png"],
        optionName: "Your Solution",
        heading: "Seamlessly integrates with your product.",
        subHeading: "Flexibility is our priority.",
        text: "Deploying is straightforward, whether on our infrastructure or yours. Our platform seamlessly integrates into your existing product, regardless of your technical setup.",
      },
    ],
  },
  {
    type: "columns",
    heading: "THE FIRST ML CODE GPT",
    subHeading: "",
    columns: [
      {
        icon: "processor",
        heading: "Pre-Processing Properties",
        text: "Natural language to code can produce efficient, end-to-end solutions <b>to save time on decoding and debugging.</b>",
      },
      {
        icon: "gear",
        heading: "Machine Learning GPT",
        text: "Boltzhub-GPT deep learning models analyse vast amounts of data, identify patterns, and make intelligent predictions.",
      },
      {
        icon: "comment_tick",
        heading: "AI Automated Model",
        text: "<b>Eliminating the need for manual intervention</b> by automating tasks such as model training, testing, and deployment.",
      },
    ],
  },
  {
    type: "scroller",
    textAlignment: "right",
    colorScheme: {
      background: "linear-gradient(to bottom, #3f146a 0%, #004e8a 90%)",
      heading: "#ffffff",
      progress: "#ffffff",
      inactiveOptionLink: "#b8b8b8",
    },
    options: [
      {
        imageSources: ["/images/hub/scroller2/slide1/1.png", "/images/hub/scroller2/slide1/2.png"],
        optionName: "Classification",
        heading: "Classification",
        subHeading: "Fit your AI to your problem.",
        text: "Efficiently classify documents using natural language to generate reliable analytical summaries. Our ML can extract key information enabling easy access to research for informed decisions.",
      },
      {
        imageSources: ["/images/hub/scroller2/slide2/1.png", "/images/hub/scroller2/slide2/2.png"],
        optionName: "Outlier Detection",
        heading: "Outlier Detection",
        subHeading: "Fit your ML Solution to your problem.",
        text: "Traditional methods of detecting fraudulent activities are frequently inaccurate. Our advanced fraud detection use case, powered by ML and real-time data analysis, can identify outliers and anomalies effectively.",
      },
      {
        imageSources: ["/images/hub/scroller2/slide3/1.png", "/images/hub/scroller2/slide3/2.png"],
        optionName: "Forecasting",
        heading: "Forecasting",
        subHeading: "Fit your AI to your problem.",
        text: "Forecasting can supercharge your productivity. Stay ahead of customer behaviour and reduce churn rates by automating the next best action to your problems.",
      },
      // {
      //   imageSources: ["/images/classification.png"],
      //   optionName: "Classification",
      //   heading: "Classification",
      //   subHeading: "Fit your AI to your problem.",
      //   text: "<b>Use Case Challenge:</b> Accurately <b>identifying and classifying their customers</b> based on various attributes and characteristics. This leads to challenges in understanding customer behaviour, resulting in missed opportunities for effective customer engagement and revenue growth.<br/><br/> <b>Your Solution:</b> Analysing customer data, such as demographics, purchase history, and online behaviour, the platform <b>accurately identifies and classifies customers into distinct segments.</b> This enables businesses to personalize marketing campaigns, tailor product offerings, and optimize customer experiences, leading to more effective customer engagement, increased customer loyalty, and improved revenue generation.",
      // },
    ],
  },
  {
    type: "customers",
    logos: [
      // "/images/google-cloud-logo.png",
      "/images/aws-logo.png",
      "/images/intel-logo.png",
      // "/images/cambridge-logo.png",
    ],
  },
  // {
  //   type: "testimonials-simple",
  //   heading: "Clients",
  //   slides: [
  //     {
  //       text: "The best AI tool for business",
  //       logo: "/images/aws-logo.png",
  //       name: "John Doe",
  //       company: "Company & Co",
  //     },
  //     {
  //       text: "Blabus porem ipsum dolor sit amet, conseceur altrices.",
  //       logo: "/images/intel-logo.png",
  //       name: "Adam Smith",
  //       company: "Company 2",
  //     },
  //   ],
  // },
  // {
  //   type: "table",
  //   headerBg: "linear-gradient(to right, #3769c8 0%, #95E1E6 100%)",
  //   rows: [
  //     ["", "Individual", "Premium", "Business", "Enterprise"],
  //     ["Private AI Model", "tick", "tick", "tick", "tick"],
  //     ["Data Source: PDF, Excel, Website", "tick", "tick", "tick", "tick"],
  //     ["Data Source: Email", "", "tick", "tick", "tick"],
  //     ["Report Generation in Search and Word", "tick", "tick", "tick", "tick"],
  //     ["Report Generation in Excel, PowerPoint and Dashboard", "", "tick", "tick", "tick"],
  //     ["Source Verification", "", "", "tick", "tick"],
  //     ["Page Limit", "", "", "Unlimited", "Unlimited"],
  //     ["Customised GPT", "", "CPU", "GPU", "GPU"],
  //     ["Maximum Users", "1", "5", "ASK SALES", "ASK SALES"],
  //     ["Price", "ASK SALES", "ASK SALES", "ASK SALES", "ASK SALES"],
  //   ],
  // },
  {
    type: "columns-and-timeline",
    heading: "Work with us.",
    columns: [
      // {
      //   icon: "calendar_down",
      //   heading: "Individual & Premium",
      //   text: "Register online with a 14 day free-trial​ & start right away!",
      // },
      {
        // icon: "calendar_up",
        // heading: "Business & Enterprise",
        text: "Get in touch with our experts. We can customise your GPT to you.",
      },
    ],
    timeline: {
      heading: "Business & Enterprise Timelines",
      headingBg: "linear-gradient(to right, #6f24ba 0%, #0191ff 100%)",
      stages: [
        {
          heading: "Engagement",
          text: "We spend time to understand your problem and how to build a solution for you",
          subText: "2 hours",
        },
        {
          heading: "Development",
          text: "We build your solution and create a ML Solution for your business logic",
          subText: "4 weeks",
        },
        {
          heading: "Evaluation",
          text: "We test your solution to ensure that it works perfectly for your needs.",
          subText: "2 weeks",
        },
        {
          heading: "Deployment",
          text: "We deploy your ML solution to your infrastructure. Our team provide professional services and ongoing technical support to guarantee an easy transition to your AI workflow.",
          subText: "2 weeks",
        },
      ],
    },
  },
  {
    type: "startnow",
  },
];

const HubLandingPage = () => {
  const [searchParams] = useSearchParams();
  const [sections, setSections] = useState([]);

  useEffect(() => {
    doPopulateLatestSections();
  }, []);

  const doPopulateLatestSections = async () => {
    const { data } = await getWebsiteContentsVersion("latest", { token: searchParams?.get("token") });
    setSections(data?.jsonContent || []);
  };

  // if (sections?.length === 0) {
  //   return null;
  // }

  // const token = searchParams.get("token");
  // if (token !== "e8f44f7a-bb23-4360-b19b-56739f5c1b9e") {
  //   return null;
  // }

  return <SectionsViewer sections={SECTIONS} />;
};

export default HubLandingPage;
