import ButtonV1 from "components/ButtonV1";
import { Gap } from "components/Layout";
import { BORDER_TOP_GREY, COLOR1, COLOR2 } from "pages/main-v2";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";

const BORDER_STYLE = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(53, 154, 255, 0) 0%, rgba(15, 18, 25, 0.9) 46.5%, #1b1f26 100%) padding-box,
  linear-gradient(to bottom, ${BORDER_TOP_GREY}, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const Container = styled.div`
  margin: 0 auto;
  padding: 100px 0;
  z-index: 1;
  position: relative;
  color: white;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const Content = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  row-gap: 120px;
  column-gap: 40px;
`;

const ColumnTitle = styled.div`
  font-family: Montserrat;

  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  line-height: 1.2;

  .gradient-text {
    font-size: inherit;
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
  }
`;

const Column = styled.div`
  display: grid;
  gap: 20px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 100px 60px;
  align-content: start;

  /* :hover { */
  /* background: linear-gradient(#2c2f39, #2c2f39) padding-box, linear-gradient(to right, white, #929292) border-box; */
  ${ColumnTitle} {
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
    /* } */
  }

  background: linear-gradient(#2c2f39, #2c2f39) padding-box, linear-gradient(to right, ${COLOR1}, ${COLOR2}) border-box;
  border-radius: 10px;
  border: 1px solid transparent;

  ${BORDER_STYLE}
`;

const ColumnText = styled.div`
  font-family: Montserrat;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.8;
`;

const StyledButtonV1 = styled(ButtonV1)`
  margin-top: 40px;
  padding: 10px 40px;
  height: auto;
  justify-self: center;
  font-size: 20px;
`;

const HorizontalLine = styled.div`
  height: 1px;
  background: linear-gradient(90deg, #70747b 0%, #999 100%);
  width: 100%;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 51.41px;
  font-weight: 500;
  line-height: 56px;
  /* width: 500px; */
  color: #747474;
  padding: 40px 0;
`;

const ThreeCardsSectionV2 = ({ isButtonHidden = false, title, columns }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        <Columns>
          <Column>
            <ColumnTitle dangerouslySetInnerHTML={{ __html: columns?.[0]?.title }} />
            <ColumnText dangerouslySetInnerHTML={{ __html: columns?.[0]?.text }} />
          </Column>
          <div />
          <div />
          <Column>
            <ColumnTitle dangerouslySetInnerHTML={{ __html: columns?.[1]?.title }} />
            <ColumnText dangerouslySetInnerHTML={{ __html: columns?.[1]?.text }} />
          </Column>
          {/* <Column>
            <ColumnTitle dangerouslySetInnerHTML={{ __html: columns?.[2]?.title }} />
            <ColumnText dangerouslySetInnerHTML={{ __html: columns?.[2]?.text }} />
          </Column> */}
        </Columns>
      </Content>
    </Container>
  );
};

export default ThreeCardsSectionV2;
