import { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";

import {
  getFlowDashboardTemplates,
  getFlowDatabaseTemplates,
  getWebsiteContentsVersion,
} from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import styled from "styled-components";
import SectionsViewerMobile from "components/SectionsViewerMobile";

const SECTIONS = [
  {
    type: "video",
    messageLine1: "Reliable",
    typewriterMessages: ["Sales Pipeline", "Trading Strategy"],
    messageLine2: "with Your Boltzflow Gen-AI Platform.",
    videoSrc: "/images/boltzflow.mp4",
  },
  {
    type: "animated-content",
    messageLine1: "Custom AI is",
    messageLine2: "best for business.",
    description:
      "Build deeply informed summaries and validate with one click. Upload your PDF Reports, Excels, Emails, and Websites, <b>safely and securely</b> to automate your business tasks. <b>Your Private Boltzflow Gen-AI</b> is created by you.",
  },
  {
    type: "scroller",
    colorScheme: {
      background: "linear-gradient(to bottom, #301354 0%, #412214 90%)",
      heading: "#ffffff",
      progress: "#abc9ff",
      inactiveOptionLink: "#b8b8b8",
    },
    options: [
      {
        imageSources: [
          "/images/scroller1/slide1/1.png",
          "/images/scroller1/slide1/2.png",
          "/images/scroller1/slide1/3.png",
        ],
        optionName: "Your Insights",
        heading: "Insights extracted from your documents.",
        subHeading: "Your platform, for your business.",
        text: "Build reports, databases or excels, <b>verify your sources and adapt your content in working document format</b> with your Boltzflow-AI Assistant.",
      },
      {
        imageSources: [
          "/images/scroller1/slide2/1.png",
          "/images/scroller1/slide2/2.png",
          "/images/scroller1/slide2/3.png",
        ],
        optionName: "At a Glance",
        heading: "Visualise your data in seconds.",
        subHeading: "Dynamic workflows made by you.",
        text: "Integrate <b>unlimited data sources</b> to Boltzflow and visualise your working data in customisable dashboards and pipelines. Instant understanding with a glance.",
      },
      {
        imageSources: ["/images/scroller1/slide3/1.png", "/images/scroller1/slide3/2.png"],
        optionName: "Your Automations",
        heading: "Easy Automations for your workflow.",
        subHeading: "Tailored solutions for your needs.",
        text: "Automations <b>streamline workflows, optimise efficiency, enhance productivity, and empower organisations</b> for seamless and intelligent operations.",
      },
    ],
  },
  {
    type: "columns",
    heading: "UNIQUE TECHNOLOGY, UNIQUE BENEFITS",
    subHeading: "",
    columns: [
      {
        icon: "processor",
        heading: "Efficient Solutions for Enterprise",
        text: "Our technology provides exceptional AI efficiency at 50x lower cost. The best for speed and accuracy.",
      },
      {
        icon: "gear",
        heading: "Factual Technology you can rely on",
        text: "Our unique technology designed to provide auditable results that you can trust, produced from <b>your own AI model.</b>",
      },
      {
        icon: "comment_tick",
        heading: "Secure Data for your company",
        text: "We have selected cloud service providers with the highest standard, to guarantee your data security.",
      },
    ],
  },
  {
    type: "scroller",
    textAlignment: "right",
    colorScheme: {
      background: "linear-gradient(to bottom, #412214 0%, #301354 100%)",
      heading: "#ffffff",
      progress: "#abc9ff",
      inactiveOptionLink: "#b8b8b8",
    },
    options: [
      {
        imageSources: [
          "/images/scroller2/slide1/1.png",
          "/images/scroller2/slide1/2.png",
          "/images/scroller2/slide1/3.png",
        ],
        optionName: "Asset Management",
        heading: "Asset Management",
        subHeading: "Fit your AI to your problem.",
        text: "Multiple sources in spreadsheets and databases to assess the health of your investment is time-consuming. Instantly extract and process deal information, automating tedious hours of work.",
      },
      {
        imageSources: ["/images/scroller2/slide2/1.png", "/images/scroller2/slide2/2.png"],
        optionName: "ESG Analysis",
        heading: "ESG Analysis",
        subHeading: "Fit your AI to your problem.",
        text: "Collecting and reading sustainability analysis takes days at a time. Capture ESG benchmarks and determine ESG scores with one automated pipeline.",
      },
      {
        imageSources: ["/images/scroller2/slide3/1.png", "/images/scroller2/slide3/2.png"],
        optionName: "Traders & Brokers",
        heading: "Traders & Brokers",
        subHeading: "Fit your AI to your problem.",
        text: "Receiving large volumes of confidential market data leads to reduced accuracy in trade confirmation. Our email parser can  ensure factual and reliable deal screening.",
      },
    ],
  },
  {
    type: "customers",
    logos: [
      // "/images/google-cloud-logo.png",
      "/images/aws-logo.png",
      "/images/intel-logo.png",
      // "/images/cambridge-logo.png",
    ],
  },
  // {
  //   type: "testimonials-simple",
  //   heading: "Clients",
  //   slides: [
  //     {
  //       text: "The best AI tool for business",
  //       logo: "/images/aws-logo.png",
  //       name: "John Doe",
  //       company: "Company & Co",
  //     },
  //     {
  //       text: "Blabus porem ipsum dolor sit amet, conseceur altrices.",
  //       logo: "/images/intel-logo.png",
  //       name: "Adam Smith",
  //       company: "Company 2",
  //     },
  //   ],
  // },
  // {
  //   type: "table",
  //   rows: [
  //     ["", "Individual", "Premium", "Business", "Enterprise"],
  //     ["Private AI Model", "tick", "tick", "tick", "tick"],
  //     ["Data Source: PDF, Excel, Website", "tick", "tick", "tick", "tick"],
  //     ["Data Source: Email", "", "tick", "tick", "tick"],
  //     ["Report Generation in Search and Word", "tick", "tick", "tick", "tick"],
  //     ["Report Generation in Excel, PowerPoint and Dashboard", "", "tick", "tick", "tick"],
  //     ["Source Verification", "", "", "tick", "tick"],
  //     ["Page Limit", "", "", "Unlimited", "Unlimited"],
  //     ["Customised GPT", "", "CPU", "GPU", "GPU"],
  //     ["Maximum Users", "1", "5", "ASK SALES", "ASK SALES"],
  //     ["Price", "ASK SALES", "ASK SALES", "ASK SALES", "ASK SALES"],
  //   ],
  // },
  {
    type: "columns-and-timeline",
    heading: "Work with us.",
    columns: [
      // {
      //   icon: "calendar_down",
      //   heading: "Individual & Premium",
      //   text: "Register online with a 14 day free-trial​ & start right away!",
      // },
      {
        // icon: "calendar_up",
        // heading: "Business & Enterprise",
        text: "Get in touch with our experts. We can customise the your GPT to you.",
      },
    ],
    timeline: {
      heading: "Business & Enterprise Timelines",
      headingBg: "linear-gradient(to right, #591B96 0%, #e17a4a 100%)",
      stages: [
        {
          heading: "Engagement",
          text: "We spend time to understand your problem and how to build a solution for you",
          subText: "2 hours",
        },
        {
          heading: "Development",
          text: "We build your solution and create a ML Solution for your business logic",
          subText: "4 weeks",
        },
        {
          heading: "Evaluation",
          text: "We test your solution to ensure that it works perfectly for your needs.",
          subText: "2 weeks",
        },
        {
          heading: "Deployment",
          text: "We deploy your ML solution to your infrastructure. Our team provide professional services and ongoing technical support to guarantee an easy transition to your AI workflow.",
          subText: "2 weeks",
        },
      ],
    },
  },
  {
    type: "startnow",
  },
];

const DesktopContainer = styled.div`
  display: none;
  @media (min-width: ${props => props.theme.breakpoint.lg}) {
    display: block;
  }
`;

const MobileContainer = styled.div`
  display: block;
  @media (min-width: ${props => props.theme.breakpoint.lg}) {
    display: none;
  }
`;

const LandingPageStart = () => {
  const [searchParams] = useSearchParams();
  const [sections, setSections] = useState([]);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateLatestSections();
    doPopulateTemplates();
  }, []);

  const doPopulateLatestSections = async () => {
    const { data } = await getWebsiteContentsVersion("latest", { token: searchParams?.get("token") });
    setSections(data?.jsonContent || []);
  };

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    console.log({ data });
    setTemplates(data || []);
  };

  // if (sections?.length === 0) {
  //   return null;
  // }

  // const token = searchParams.get("token");
  // if (token !== "e8f44f7a-bb23-4360-b19b-56739f5c1b9e") {
  //   return null;
  // }

  return (
    <>
      <MobileContainer>
        <SectionsViewerMobile sections={SECTIONS} />
      </MobileContainer>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} />
      </DesktopContainer>
    </>
  );
};

export default LandingPageStart;
