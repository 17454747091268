import { Gap } from "components/Layout";
import { COLOR1, COLOR2 } from "pages/main-v2";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
  color: white;
  align-content: center;
  margin-top: -10px;
  padding-bottom: 200px;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 80px;
  padding: 0px 20px;
  margin: 0 auto;

  @media (min-width: 1240px) {
    grid-template-columns: 1fr 1fr;
    row-gap: 94px;
    column-gap: 0px;
    width: 1200px;
    padding: 0;
  }
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 29px;
  color: #ffffff;
`;

const Text = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
  letter-spacing: -0.016em;
  background: linear-gradient(88.86deg, ${COLOR2} 17.72%, ${COLOR1} 47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
  padding-left: 60px;
`;

const RightText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;
  color: #ffffff;
  padding-right: 60px;
`;

const StyledA = styled.a`
  color: white;
  text-decoration: underline;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
`;

const StyledLink = styled.a`
  width: max-content;
  font-weight: 600;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
  text-align: right;
  justify-self: end;
  color: #487ff3;
  text-decoration: none;
  :hover {
    opacity: 0.8;
  }
`;

const HorizontalLine = styled.div`
  height: 1px;
  grid-column: span 2;
  background: linear-gradient(
    90deg,
    rgba(73, 127, 243, 0) 0%,
    ${COLOR2} 25.5%,
    #ffffff 50.5%,
    ${COLOR1} 75%,
    rgba(56, 208, 218, 0) 100%
  );
`;

const MessageSectionV2 = ({ title, leftTitle = "", text, isLinkVisible = false }) => {
  return (
    <Container>
      <Content>
        <HorizontalLine />
        <Text style={{ textAlign: "left" }}>{leftTitle}</Text>
        <div>
          <RightText
            style={leftTitle ? { textAlign: "left" } : {}}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
          <Gap height="24px" />
          <StyledLink style={{ gridColumn: 2, justifySelf: "start" }} href="/research" target="_blank">
            Learn more ▸
          </StyledLink>
        </div>
        <HorizontalLine />
      </Content>
    </Container>
  );
};

export default MessageSectionV2;
