import styled from "styled-components";

import { Gap, Heading, SubHeading } from "components/Layout";
import { BoltzflowLogoInner, BoltzhubLogoInner, CommentTickIcon, GearIcon, ProcessorIcon } from "components/ui/Icons";
import { range } from "lodash";

const iconNameToComponent = {
  processor: <ProcessorIcon />,
  gear: <GearIcon />,
  comment_tick: <CommentTickIcon />,
};

const ColumnsSectionContainer = styled.div`
  position: relative;
  display: grid;
  justify-content: center;
  align-content: center;
  width: 100vw;
  overflow: hidden;
  height: 100vh;
  transition: opacity 0.4s ease-in-out;
`;

const ColumnsContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  max-width: 1250px;
  row-gap: 40px;
  column-gap: 60px;
  justify-items: center;
`;

const ColumnIcon = styled.div`
  padding-bottom: 10px;
  svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const ColumnText = styled.div`
  line-height: 1.5;
  text-align: center;
`;

const ColumnSubHeading = styled(SubHeading)`
  line-height: 1.2;
  text-align: center;

  ${props =>
    props.isGradientColor &&
    `
  background-image: linear-gradient(to right, ${props.theme.color.secondary}, ${props.theme.color.primary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  `};
`;

const StyledBoltzflowLogoInner = styled(BoltzflowLogoInner)`
  height: 400px;
  opacity: 0.25;
  position: absolute;
  bottom: -100px;
  left: -140px;
  fill: url(#SvgGradient);
`;

const StyledBoltzhubLogoInner = styled(BoltzhubLogoInner)`
  height: 400px;
  opacity: 0.25;
  position: absolute;
  bottom: -100px;
  right: -80px;
  fill: url(#SvgGradient);
`;

const Columns = ({ heading = "", subHeading = "", columns = [] }) => {
  const isHub = window.location.pathname.includes("/hub");

  return (
    <ColumnsSectionContainer>
      <SubHeading>{subHeading}</SubHeading>
      <Gap height="0px" />
      <Heading style={{ fontSize: "48px", textAlign: "center" }}>{heading}</Heading>
      <Gap height="80px" />
      <ColumnsContainer>
        {range(columns.length).map(i => (
          <ColumnIcon>{iconNameToComponent?.[columns?.[i]?.icon]}</ColumnIcon>
        ))}
        {range(columns.length).map(i => (
          <ColumnSubHeading isGradientColor={i === 1}>{columns?.[i]?.heading}</ColumnSubHeading>
        ))}
        {range(columns.length).map(i => (
          <ColumnText
            dangerouslySetInnerHTML={{
              __html: columns?.[i]?.text,
            }}
          />
        ))}
      </ColumnsContainer>
      {isHub ? <StyledBoltzhubLogoInner /> : <StyledBoltzflowLogoInner />}
    </ColumnsSectionContainer>
  );
};

export default Columns;
