import { Gap } from "components/Layout";
import { BORDER_TOP_GREY, COLOR1, COLOR2 } from "pages/main-v2";
import styled from "styled-components";

const BORDER_STYLE_WHITE = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%) padding-box,
    linear-gradient(to bottom, #d1d1d1, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const Container = styled.div`
  color: #424242;
  position: relative;

  display: none;
  justify-content: center;
  align-items: start;
  align-content: start;
  min-height: 100vh;
  width: 1200px;
  margin: 0 auto;

  align-items: start;

  margin-bottom: 100px;

  @media (min-width: 1240px) {
    display: grid;
  }
`;

const Title = styled.div`
  text-align: justify;
  font-family: Montserrat;
  font-size: 56px;
  font-style: normal;
  font-weight: 600;
  text-align: center;

  background: linear-gradient(88.57deg, ${COLOR2} 17.21%, ${COLOR1} 62.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const Description = styled.div`
  line-height: 1.4;
  text-align: center;
  width: 600px;
  justify-self: center;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
  align-content: start;
  align-items: start;
  /* justify-content: center; */
`;

const TeamName = styled.div`
  font-family: Montserrat;
  font-weight: 600;
  text-align: left;

  font-size: 36px;
  padding: 0px 40px;

  background: linear-gradient(132.27deg, #424242 48.26%, #7a7979 64.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const ShortDescription = styled.div`
  line-height: 1.5;
  padding: 0px 40px;
  padding-bottom: 40px;
`;

const Team = styled.div`
  display: grid;
  gap: 60px;

  box-sizing: border-box;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%);
  border-radius: 24px;
  height: 100%;

  ${BORDER_STYLE_WHITE}
`;

const Column = styled.div``;

const WaveGraphic = styled.img`
  width: 100%;
  margin-top: -220px;
`;

const ImgTeam = styled.img`
  height: 310px;
  width: 100%;
  object-fit: cover;
  border-radius: 24px 24px 0px 0px;
`;

const TeamsSectionV2 = ({ title = "", description = "", teams = [] }) => {
  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Gap />
        <Description>{description}</Description>
        <Gap height="160px" />
        <Columns>
          {teams?.map(team => (
            <Team>
              <ImgTeam src={team?.imageSrc} />
              <TeamName>{team?.title}</TeamName>
              <ShortDescription>{team.description}</ShortDescription>
            </Team>
          ))}
        </Columns>
      </Container>
      <WaveGraphic src="/images/waves-about-us.png" />
    </>
  );
};

export default TeamsSectionV2;
