import FooterSectionV2 from "components/FooterSectionV2";
import TopBarV2 from "components/TopBarV2";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 120px;
  background-color: #151617;
  color: white;
  line-height: 1.5;
  display: grid;
  justify-content: center;

  * {
    font-size: 14px;
  }

  strong {
    font-weight: 600;
  }
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const TwoColumns = styled.div`
  display: grid;
  padding: 20px;
  gap: 40px;
  a {
    color: lightblue;
  }

  @media (min-width: 1200px) {
    padding: 0;
    width: 1200px;
    grid-template-columns: 1fr 3fr;
  }
`;

const TermsPage = () => {
  return (
    <Container>
      <TopBarV2 />
      <TwoColumns>
        <Title>TERMS & CONDITIONS</Title>
        <div class="col-md-8">
          <p>
            Thank you for visiting <strong>www.boltzbit.com</strong> (the “<strong>Site</strong>”) which is operated by{" "}
            <strong>Boltzbit Limited</strong> (CRN: 12398587) of Office 202 221 Pentonville Road, London, England, N1
            9UZ (“<strong>us</strong>” or “<strong>we</strong>”). These terms and conditions, together with any
            documents referred to within (the “<strong>Terms</strong>”) govern the use of our Site. By using our Site,
            you agree to comply with these Terms.
          </p>

          <ol>
            <li>
              ACCESS TO OUR SITE
              <ol>
                <li>
                  We permit access to our Site to you on a temporary basis, and we reserve the right to withdraw your
                  access or amend the content we provide on our Site without notice. We will not be liable if for any
                  reason our Site and content is unavailable at any time or for any period.
                </li>
                <li>From time to time, we may restrict access to some parts of our Site, or our entire Site.</li>
                <li>
                  [Certain areas of the Site may be restricted to account holders only. If you create an account, you
                  must treat such account information, including your password, as confidential and must not disclose it
                  to any third party. You must promptly notify us if you suspect anyone else has obtained access to your
                  account.][A1] We will only use your personal information as set out in our{" "}
                  <a href="/privacy-policy">privacy policy.</a>
                </li>
                <li>
                  Your right to access and use the Site will be terminated immediately if you breach any of these Terms.
                </li>
              </ol>
            </li>
            <br />
            <li>
              USE OF OUR SITE CONTENT
              <ol>
                <li>
                  We are the owner or the licensee of all intellectual property rights in our Site, and in the material
                  published on it. Those works are subject to copyright and are protected by worldwide intellectual
                  property laws. All such rights are reserved.
                </li>
                <li>
                  You may print off one copy, and may download extracts, of any page(s) from our Site solely for the
                  purposes of evaluating our products and services for your own internal business purposes, on the basis
                  no content is modified in any way and any proprietary notices are not removed.
                </li>
              </ol>
            </li>
            <br />

            <li>
              INFORMATION ON THIS SITE
              <ol>
                <li>
                  The content on our Site (including any links to other sites and resources) is provided “as is” for
                  general information only. It is not intended to amount to advice on which you should rely.
                </li>
                <li>
                  Although we make reasonable efforts to update the information on our Site, we make no representations,
                  warranties or guarantees, whether express or implied, that the content on our Site is accurate,
                  complete or up to date.
                </li>
                <li>
                  We are not responsible for any content which is not published by us and have no control over content
                  of any third party sites or resources linked to our Site.
                </li>
              </ol>
            </li>
            <br />

            <li>
              CONDUCT OF USERS
              <ol>
                <li>
                  You acknowledge that you are solely responsible for interactions with other users of this Site (if
                  any), and that you are solely responsible for all content you publish or post to this Site or transmit
                  to other users of this Site.
                </li>
                <li>
                  You must not use any part of the content on our Site for commercial purposes without obtaining a
                  licence to do so from us or our licensors.
                </li>
                <li>
                  You agree not to post, distribute or reproduce in any way any copyright material, trademarks or other
                  proprietary information without obtaining the prior consent of the owner of such proprietary rights.
                </li>
                <li>
                  You will not submit any content that contains offensive, obscene or otherwise unlawful references,
                  offensive language, or other material that we may consider could bring us or the Site into disrepute.
                </li>
                <li>
                  You agree not to transmit any chain letters, spam letters, or junk email to other users, and not to
                  participate in mischievous or malicious behaviour which causes damage or may cause damage to us or
                  this Site or any of the computer systems on which this Site resides.
                </li>
                <li>
                  You agree not to knowingly introduce viruses, trojans, worms, logic bombs or other material that is
                  malicious or technologically harmful. You must not attempt to gain unauthorised access to this Site,
                  the server on which this Site is stored or any server, computer or database connected to this Site.
                  You must not attack this Site via a denial-of-service attack or a distributed denial-of-service
                  attack.
                </li>
              </ol>
            </li>
            <br />

            <li>
              OUR RESPONSIBILITY FOR LOSS OR DAMAGE SUFFERED BY YOU
              <ol>
                <li>
                  Nothing in these Terms excludes or limits our liability to you for death or personal injury caused by
                  our negligence, for fraud or fraudulent misrepresentation and where it would otherwise be unlawful to
                  do so.
                </li>
                <li>
                  Subject to the foregoing, we exclude all implied conditions, warranties, representations or other
                  terms that may apply to our site or any content on it and will not be liable to you for any loss or
                  damage, whether in contract, tort (including negligence), breach of statutory duty, or otherwise, even
                  if foreseeable, arising under or in connection with:
                  <ol>
                    <li style={{ listStyleType: "none" }}>
                      <ol>
                        <li>use of, or inability to use, our Site; or</li>
                        <li>use of or reliance on any content displayed on our Site.</li>
                      </ol>
                    </li>
                  </ol>
                </li>
                <li>
                  In particular, we will not be liable for loss of profits, sales, business, or revenue; business
                  interruption; loss of anticipated savings; loss of business opportunity, goodwill or reputation; or
                  any indirect or consequential loss or damage.
                </li>
                <li>
                  We do not guarantee that our Site will be secure or free from bugs or viruses. You should use your own
                  virus protection software.
                </li>
              </ol>
            </li>
            <br />

            <li>
              CHANGES TO THE SITE AND THESE TERMS
              <ol>
                <li>
                  We may update and change our Site from time to time to reflect changes to our services, our users'
                  needs, changes in the law, regulatory requirements and our business priorities, which may also require
                  changes to these Terms. Every time you wish to use this Site, please check these Terms to ensure you
                  understand the terms that apply at that time.
                </li>
              </ol>
            </li>
            <br />

            <li>
              APPLICABLE LAW
              <ol>
                <li>
                  These Terms and any dispute or claim (including non-contractual disputes or claims) arising out of or
                  in connection with them or their subject matter or formation shall be governed by and construed in
                  accordance with the law of England and Wales. Each party irrevocably agrees that the courts of England
                  and Wales shall have exclusive jurisdiction to settle any such dispute or claim.
                </li>
              </ol>
            </li>
          </ol>
          <p>To contact us, please email info@boltzbit.com.</p>
          <p>
            <br />
            These terms and conditions were last updated on July 2024.
          </p>
        </div>
      </TwoColumns>
      <FooterSectionV2 />
    </Container>
  );
};

export default TermsPage;
