import styled from "styled-components";

const Container = styled.div`
  font-weight: 700;
  font-size: 48px;
  line-height: 80px;
  padding: 40px;

  display: grid;
  justify-content: center;
  text-align: center;
  padding-top: 150px;
`;

const Logos = styled.div`
  padding-top: 60px;
  display: flex;
  gap: 100px;
  flex-wrap: wrap;
`;

const Logo = styled.img`
  height: 50px;
`;

const Customers = ({ logos }) => (
  <Container>
    Our Partners
    <Logos>
      {logos.map((src, i) => (
        <Logo src={src} key={i} />
      ))}
    </Logos>
  </Container>
);

export default Customers;
