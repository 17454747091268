import ContactUsModal from "components/ContactUsModal";
import TopBar from "components/TopBar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import ButtonV1 from "components/ButtonV1";
import TopBarV2 from "components/TopBarV2";
import { COLOR1, COLOR2 } from "pages/main-v2";

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  padding-top: calc(170px + 60px);
  /* height: 100vh; */
  display: grid;
  justify-items: center;
  align-content: center;

  @media (min-width: 1240px) {
    width: 1200px;
    padding: 0;
    height: 100vh;
    /* padding-top: calc(170px + 60px); */
  }

  @media (max-width: 1240px) {
    ${props => props.mobileStyle}
    padding-top: 140px;
  }
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 36px;
  line-height: 1.1;
  margin-top: 60px;

  background: linear-gradient(92.77deg, #2e2d2d 17.72%, #70747b 66.83%, #787368 77.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-align: center;

  @media (min-width: 1240px) {
    font-size: 60px;
  }

  .gradient-text {
    font-size: inherit;
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const SubTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  padding-top: 40px;
  text-align: center;

  color: #424242;

  @media (min-width: 1240px) {
    font-size: 24px;
    line-height: 1.1;
  }
`;

const JoinBtn = styled(ButtonV1)`
  margin: 0 auto;
  margin-top: 40px;
`;

const ResearchTitleSectionV2 = ({
  title = "",
  subTitle = "",
  isShowingContactUs = false,
  isShowingCareersLink = false,
  style = {},
  mobileStyle = {},
}) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container style={style} mobileStyle={mobileStyle}>
      <TopBarV2 colorScheme="dark" backgroundColor="black" />
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <SubTitle
        dangerouslySetInnerHTML={{
          __html: subTitle,
        }}
      />
      {isShowingContactUs && <ContactUsModal triggerComponent={<JoinBtn>Join waitlist</JoinBtn>} />}
      {isShowingCareersLink && (
        <a href="/careers" style={{ textDecoration: "none", color: "inherit" }} target="_blank" rel="noreferrer">
          <JoinBtn isPrimary>Join us</JoinBtn>
        </a>
      )}
    </Container>
  );
};

export default ResearchTitleSectionV2;
