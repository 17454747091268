import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import SectionsViewerMobileMinimal from "components/SectionsViewerMobileMinimal";
import { CommentTickIcon, GearIcon, ProcessorIcon } from "components/ui/Icons";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "under-construction",
  },
];

const DesktopContainer = styled.div`
  display: none;
  background: linear-gradient(178.34deg, #161718 -2.94%, #14181f 90.88%, #000d78 621.32%);
`;

const MobileContainer = styled.div``;

const Container = styled.div`
  @media (min-width: 1240px) {
    ${DesktopContainer} {
      display: block;
    }
    ${MobileContainer} {
      display: none;
    }
  }
`;

const SolutionsPage = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "0ced6987-57da-4c35-938d-418acc5d328a") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
      <MobileContainer>
        <SectionsViewerMobileMinimal sections={SECTIONS} templates={templates} isFooterHidden />
      </MobileContainer>
    </Container>
  );
};

export default SolutionsPage;
