import styled from "styled-components";

import { theme } from "theme";
import { ArrowLeftIcon, CommentIcon } from "components/ui/Icons";
import { SubHeading } from "components/Layout";
import ProgressBar from "components/ProgressBar";
import { useState } from "react";

const minmax = (min, max, x) => Math.min(Math.max(x, min), max);

const isSlideVisible = (slideIndex, progressPercentage, numSlides) => {
  const percentagePerSlide = Math.ceil(100 / numSlides);
  const visibleSlideIndex = Math.floor(progressPercentage / percentagePerSlide) - 1;

  return visibleSlideIndex === slideIndex;
};

const OuterContainer = styled.div`
  position: relative;
  @media (min-width: 768px) {
    margin: 100px 110px 100px 0;
    padding: 0 25px 25px 0;
  }
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  grid-template-rows: repeat(6, auto);
  grid-template-areas:
    "icon ."
    "heading ."
    "text ."
    "name ."
    "company ."
    "arrowLeft arrowRight";

  align-items: center;
  padding: 40px;
  background: linear-gradient(
    89.88deg,
    ${props => props.theme.color.secondary} 0.1%,
    ${props => props.theme.color.primary} 99.9%
  );

  @media (min-width: 768px) {
    padding: 60px 100px;
    grid-template-columns: 1fr auto auto;
    grid-template-areas:
      "icon . ."
      "heading . ."
      "text . ."
      "name . ."
      "company . ."
      "progressBar arrowLeft arrowRight";
  }
`;

const StyledCommentIcon = styled(CommentIcon)`
  grid-area: icon;
  height: 60px;
  fill: ${props => props.theme.color.primary};
  padding-bottom: 20px;
`;

const WhatClientsSayHeading = styled(SubHeading)`
  grid-area: heading;
  font-size: 36px;
  font-weight: 650;
  padding-bottom: 60px;
`;

const SlideText = styled.div`
  grid-area: text;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  max-width: 800px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const Name = styled.div`
  grid-area: name;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
`;

const Company = styled.div`
  grid-area: company;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  padding-bottom: 60px;
`;

const SlideProgressBar = styled(ProgressBar)`
  grid-area: progressBar;
  height: 2px;
  width: 100%;
  max-width: 400px;
`;

const ArrowLeft = styled(ArrowLeftIcon)`
  grid-area: arrowLeft;
  cursor: pointer;
  padding: 0 10px;
  transition: fill 0.2s;

  :hover {
    fill: ${props => props.theme.color.secondary};
  }
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
  grid-area: arrowRight;
`;

const BottomSlantedRect = styled.div`
  display: none;
  width: calc(100% + 0px);
  height: 25px;
  position: absolute;
  bottom: 0;
  left: -12.5px;

  background: linear-gradient(
    90deg,
    ${props => props.theme.color.secondary} 66.24%,
    ${props => props.theme.color.primary} 100.08%
  );
  transform: matrix(1, 0, 1, 1, 0, 0);

  @media (min-width: 768px) {
    display: block;
  }
`;

const RightSlantedRect = styled.div`
  display: none;
  height: calc(100% - 25px);
  width: 25px;
  position: absolute;
  top: 12.5px;
  right: 0;

  background: linear-gradient(
    180deg,
    ${props => props.theme.color.primary} 1.32%,
    ${props => props.theme.color.secondary} 76.03%
  );
  transform: matrix(1, 1, 0, 1, 0, 0);

  @media (min-width: 768px) {
    display: block;
  }
`;

const Testimonials = ({ heading, slides }) => {
  const progressPerSlide = Math.ceil(100 / slides?.length);
  const [progressPercentage, setProgressPercentage] = useState(progressPerSlide);

  return (
    <OuterContainer>
      <Container>
        <StyledCommentIcon />
        <WhatClientsSayHeading>{heading}</WhatClientsSayHeading>
        {slides.map((slide, i) => (
          <SlideText isVisible={isSlideVisible(i, progressPercentage, slides?.length)} key={i}>
            {slide.text}
          </SlideText>
        ))}
        {slides.map((slide, i) => (
          <Name isVisible={isSlideVisible(i, progressPercentage, slides?.length)} key={i}>
            {slide.name}
          </Name>
        ))}
        {slides.map((slide, i) => (
          <Company isVisible={isSlideVisible(i, progressPercentage, slides?.length)} key={i}>
            {slide.company}
          </Company>
        ))}
        <SlideProgressBar color={theme.color.primary} progressPercentage={progressPercentage} />
        <ArrowLeft
          onClick={() =>
            setProgressPercentage(prevProgress => minmax(progressPerSlide, 100, prevProgress - progressPerSlide))
          }
        />
        <ArrowRight
          onClick={() =>
            setProgressPercentage(prevProgress => minmax(progressPerSlide, 100, prevProgress + progressPerSlide))
          }
        />
      </Container>
      <BottomSlantedRect />
      <RightSlantedRect />
    </OuterContainer>
  );
};

export default Testimonials;
