import styled from "styled-components";

const ButtonSquareContainer = styled.button`
  position: relative;
  font-size: 14px;
  padding: 8px 20px;
  background-color: ${props => props.theme.color.closest};
  color: ${props => props.theme.color.furthest};
  font-family: "Montserrat";
  transition: background-color 0.1s, color 0.1s;
  font-weight: 600;
  cursor: pointer;

  display: grid;
  /* grid-template-columns: ${props => (props?.isIconOnly ? "auto" : "20px auto 20px")}; */
  align-items: center;
  gap: 10px;

  ${props => props.isDisabled && "pointer-events: none; opacity: 0.5;"}

  border: 2px solid ${props => props.theme.color.closer1};

  ${props =>
    props.variant === "highlighted" &&
    `
    border: 2px solid;
    border-image: linear-gradient(
      to right,
      ${props.theme.color.primary},
      ${props.theme.color.secondary}
    ) 1;
  `};

  svg {
    fill: ${props => props.theme.color.closest};
  }

  :hover {
    color: ${props => props.theme.color.furthest};
    background-color: ${props => props.theme.color.primary};
    ${props =>
      props.variant === "highlighted" &&
      `background: linear-gradient(
      to right,
      ${props.theme.color.primary},
      ${props.theme.color.secondary}
    );`}

    ${props => props.variant !== "highlighted" && `border: 2px solid ${props.theme.color.primary}`};
    svg {
      fill: ${props => props.theme.color.furthest};
    }
  }
`;

const IconContainer = styled.div`
  display: flex;
  align-content: center;
  justify-content: center;
`;

const CornerRect = styled.div`
  width: 2px;
  height: 2px;
  position: absolute;
  background-color: ${props => props.theme.color.primary};
`;

const Button = ({ value, variant = "default", onClick, icon, className, isDisabled, onMouseOver, onMouseLeave }) => {
  return (
    <ButtonSquareContainer
      isIconOnly={icon && !value}
      onMouseOver={onMouseOver}
      onMouseLeave={onMouseLeave}
      onClick={onClick}
      variant={variant}
      className={className}
      isDisabled={isDisabled}
    >
      {/* {variant === "default" && (
        <>
          <CornerRect style={{ top: "-2px", left: "-2px" }} />
          <CornerRect style={{ top: "-2px", right: "-2px" }} />
          <CornerRect style={{ bottom: "-2px", left: "-2px" }} />
          <CornerRect style={{ bottom: "-2px", right: "-2px" }} />
        </>
      )} */}
      {/* <IconContainer>{icon}</IconContainer> */}
      {value}
    </ButtonSquareContainer>
  );
};

export default Button;
