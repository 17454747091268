import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

import { getUnsubscribe } from "api/services/marketingService";
import { BoltzbitLogo } from "components/ui/Icons";

const Container = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
`;

const TopContainer = styled.div`
  top: 0;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(2, auto);
  gap: 22px;
  padding: 20px 20px;
  align-items: center;
  z-index: 100;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out;
`;

const FlexLink = styled.a`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
`;

const NavLink = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  :hover {
    opacity: 0.8;
  }
`;

const Msg = styled.div`
  padding: 20px;
`;

const UnsubscribePage = () => {
  const [isConfirming, setIsConfirming] = useState(true);
  const [error, setError] = useState(null);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    doConfirmEmail();
  }, []);

  const doConfirmEmail = async () => {
    setIsConfirming(true);
    const accessToken = searchParams?.get("accessToken");
    const email = searchParams?.get("email");
    const { error } = await getUnsubscribe({ email, accessToken });
    setError(error);
    setIsConfirming(false);
  };

  let msgContent = <Msg>Please wait...</Msg>;
  if (!isConfirming && !error) {
    msgContent = (
      <>
        <Msg>You have been unsubscribed.</Msg>
        <Msg>Sorry to see you go.</Msg>
      </>
    );
  }
  if (error) {
    msgContent = <Msg style={{ color: "salmon" }}>Something went wrong. Please try again later.</Msg>;
  }

  return (
    <Container>
      <TopContainer>
        <FlexLink href="https://boltzbit.com/">
          <BoltzbitLogo style={{ fill: "black", height: "28px" }} />
          <Title>Boltzbit</Title>
        </FlexLink>
        <NavLink href="/hub">Boltzhub</NavLink>
        <NavLink href="/flow">Boltzflow</NavLink>
      </TopContainer>
      {msgContent}
    </Container>
  );
};

export default UnsubscribePage;
