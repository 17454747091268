import styled from "styled-components";

const Container = styled.div`
  margin: 0 auto;
  margin-bottom: 40px;
  padding: 0 20px;

  @media (min-width: 1240px) {
    padding: 0;
    width: 1200px;
  }
`;

const Title = styled.div`
  font-size: 32px;
  font-weight: 500;
  margin: 0 auto;
  padding-bottom: 40px;

  @media (min-width: 1240px) {
    width: 1350px;
    font-size: 48px;
  }
`;

const Papers = styled.div`
  display: flex;
  gap: 20px;
  margin-top: 40px;
  margin: 0 auto;
  flex-direction: column;

  @media (min-width: 1240px) {
    flex-direction: row;
  }
`;

const PaperTitle = styled.div`
  font-size: 20px;
  font-weight: 500;
  line-height: 1.2;
  color: black;
`;

const Paper = styled.a`
  background-color: #ffffff;
  padding: 40px 20px;
  height: 200px;
  border-radius: 20px;
  align-content: start;
  gap: 20px;

  display: grid;
  text-decoration: none;
  * {
    text-decoration: none;
  }

  :hover {
    ${PaperTitle} {
      color: #497ff3;
    }
  }

  @media (min-width: 1240px) {
    width: 400px;
  }
`;

const Date = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: black;
`;

const ResearchPapersSection = ({ title = "", papers = [] }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Papers>
        {papers?.map(paper => (
          <Paper href={paper?.url} target="_blank">
            <Date>{paper?.date}</Date>
            <PaperTitle>{paper?.title}</PaperTitle>
          </Paper>
        ))}
      </Papers>
    </Container>
  );
};

export default ResearchPapersSection;
