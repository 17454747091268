import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import SectionsViewerMobileMinimal from "components/SectionsViewerMobileMinimal";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "research-title-v2",
    title: "<span class='gradient-text'>We innovate</span> to make<br/> a brighter GenAI future",
    subTitle: "We build the future, one GenAI solution at a time",
    isShowingCareersLink: true,
  },
  {
    type: "message-graphic",
    leftTitle: "Our Goal",
    text: "GenAI should be <span style='font-weight: 600; font-size: inherit;'>accessible and usable for all organizations.</span> Powerful technologies must be in the hands of the many.",
  },
  {
    type: "message-list",
    title: "Our Values",
    text: "We believe in the future where AI and humans <br /> work hand in hand to make great things happen.",
    columns: [
      {
        title: "Follow vision",
        text: "Our vision is the bridge that links the reality now to the future.",
      },
      {
        title: "Be Bold",
        text: "We are brave to make changes, failures and commit to big ideas.",
      },
      {
        title: "Move Fast",
        text: "We love MVPs, as they are the only way to make vision reality.",
      },
    ],
  },
  {
    type: "people-v2",
    title: "Leadership Team",
    people: [
      {
        name: "Dr. Yichuan Zhang",
        role: "CEO & Co-founder",
        shortDescription: "Ex-Google<br/>University of Cambridge",
        imageSrc: "/images/yichuan.png",
        url: "https://uk.linkedin.com/in/dr-yichuan-zhang-46074955",
      },
      {
        name: "Dr. Jinli Hu",
        role: "CTO & Co-founder",
        shortDescription: "Ex-Microsoft<br/>University of Edinburgh",
        imageSrc: "/images/jinli.png",
        url: "https://uk.linkedin.com/in/arkjinli",
      },
      {
        name: "Ollie Gough",
        role: "COO",
        shortDescription: "Ex-Sylvera<br/>Imperial College London",
        imageSrc: "/images/ollie.png",
        url: "https://uk.linkedin.com/in/ollie-gough-75950990",
      },
    ],
  },
  {
    type: "teams-v2",
    title: "Our Teams",
    description:
      "We are researchers, engineers, and operational leaders, with experience spanning a variety of disciplines, all working together to build reliable GenAI systems.",
    teams: [
      {
        title: "Research",
        imageSrc: "/images/teams-research.jpeg",
        description:
          "Our research team is made up of experts in machine learning, computer vision, and natural language processing, who are constantly pushing the boundaries of what’s possible with GenAI.",
      },
      {
        title: "Business",
        imageSrc: "/images/teams-business.jpeg",
        description:
          "Our business team is responsible for building relationships with our customers, ensuring that they have the support they need to get the most out of our GenAI solutions.",
      },
      {
        title: "Product",
        imageSrc: "/images/teams-product.jpeg",
        description:
          "The product team is responsible for turning our research into real-world solutions, working closely with our customers to understand their needs and build products that solve their problems.",
      },
      {
        title: "Engineering",
        imageSrc: "/images/teams-engineering.jpeg",
        description:
          "The engineering team is responsible for building and maintaining the infrastructure that powers our GenAI solutions, ensuring that they are reliable, scalable, and secure.",
      },
    ],
  },
  {
    type: "footer-v2",
  },
];

const DesktopContainer = styled.div`
  /* display: none; */
  background: linear-gradient(
    180deg,
    #ffffff 0%,
    #ebebeb 23%,
    #ffffff 33%,
    #ededed 49.5%,
    #ffffff 64.89%,
    #dedede 86%,
    #f4f4f4 100%
  );
`;

const MobileContainer = styled.div``;

const Container = styled.div`
  /* @media (min-width: 1240px) {
    ${DesktopContainer} {
      display: block;
    }
    ${MobileContainer} {
      display: none;
    }
  } */
`;

const AboutUsPageV2 = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "bzabc") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
      {/* <MobileContainer>
        <SectionsViewerMobileMinimal sections={SECTIONS} templates={templates} isFooterHidden />
      </MobileContainer> */}
    </Container>
  );
};

export default AboutUsPageV2;
