import ButtonV1 from "components/ButtonV1";
import { Gap } from "components/Layout";
import { PaperClipIcon, SendIcon } from "components/ui/Icons";
import { BORDER_TOP_GREY, COLOR1, COLOR2 } from "pages/main-v2";
import styled from "styled-components";

const BORDER_STYLE = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(53, 154, 255, 0) 0%, rgba(15, 18, 25, 0.9) 46.5%, #1b1f26 100%) padding-box,
  linear-gradient(to bottom, ${BORDER_TOP_GREY}, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const Container = styled.div`
  display: grid;
  position: relative;
  padding: 20px;
  @media (min-width: 1240px) {
    display: grid;
    padding: 0;
    padding-top: 40px;
  }

  color: white;
  justify-content: center;
  padding-top: 20px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 51.41px;
  font-weight: 600;
  line-height: 56px;
  letter-spacing: -1.1200000047683716px;
  text-align: center;

  @media (min-width: 1240px) {
    width: 900px;
  }

  span {
    font-size: inherit;
  }

  .gradient-text {
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
  }
`;

const SubTitle = styled.div`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 300;
  line-height: 28.6px;
  text-align: center;
  color: #bdbdbd;

  @media (max-width: 1240px) {
    font-size: 18px;
  }
`;

const InputContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Input = styled.input`
  background: #2e2d2d;
  border: 0.5px solid rgba(167, 167, 167, 0.56);
  box-shadow: 0px 0px 10px #acc7ec;
  border-radius: 10px;
  height: 53px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #ffffff;
  outline: none;
  padding: 0 20px;
  width: 100%;
`;

const BottomButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;

  @media (max-width: 1240px) {
    gap: 10px;
  }
`;

const BottomButton = styled.div`
  background: #161819;
  border: 0.5px solid #3a3b4b;
  box-shadow: 0px 0px 20px #1e1e1e;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;

  padding: 8px;
  font-weight: 400;
  font-size: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background: linear-gradient(90deg, #747474 0%, #dadada 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  @media (max-width: 1240px) {
    font-size: 12px;
    line-height: 1.2;
    padding: 4px;
  }
`;

const TopContent = styled.div`
  z-index: 1;
  display: grid;
  justify-content: center;
  padding-top: 40px;
  gap: 20px;
  border-radius: 20px;
  /* background: radial-gradient(60% 80% at 50% -18%, #30323c 0%, #30323c 60%, rgba(0, 0, 0, 0) 100%); */

  @media (min-width: 1240px) {
    width: 1200px;
  }
`;

const ImageSection = styled.div`
  position: relative;

  @media (min-width: 1240px) {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 100px;
    justify-content: center;
    grid-auto-flow: dense;
  }

  @media (max-width: 1240px) {
    display: flex;
    flex-direction: column-reverse;
  }
`;

const ImageSections = styled.div`
  display: grid;
  position: relative;
  /* justify-content: center; */

  /* background: linear-gradient(142.05deg, #16181a 31.69%, #151f2c 85.46%); */
  border-radius: 40px;
  margin: 0 auto;

  @media (min-width: 1240px) {
    width: 1200px;
  }
`;

const ImageContent = styled.img`
  width: 100%;
  z-index: 1;
  /* image 16 */

  box-sizing: border-box;

  ${BORDER_STYLE}

  @media (min-width: 1240px) {
    width: 520px;
  }
`;

const ImageSticker = styled.img`
  height: 135px;
  position: absolute;
  left: -80px;
  z-index: 0;
  top: 68px;
`;

const TextContent = styled.div`
  width: 500px;
  border-radius: 24px;
  align-self: center;

  display: grid;
  align-content: center;
  justify-content: center;
  gap: 28px;
  padding: 40px;

  ${BORDER_STYLE}

  @media (max-width: 1240px) {
    width: 100%;
  }
`;

const SectionTitle = styled.div`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 1.2;
  text-align: left;

  background: linear-gradient(88.86deg, ${COLOR2} 17.72%, ${COLOR1} 47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const SectionDescription = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-weight: 200;
  line-height: 30px;
  color: #d9d9d9;
`;

const BacklitPanel = styled.div`
  position: absolute;
  width: 1000px;
  height: 383px;
  z-index: 0;

  left: 50%;
  transform: translateX(-50%);
  top: calc(320px - 60px);

  @media (max-width: 1240px) {
    display: none;
  }
`;

const PanelRect = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  background-color: black;
  border-radius: 24px;

  ${BORDER_STYLE};
  /* background: linear-gradient(0deg, rgba(21, 22, 23, 0) 0%, rgba(15, 18, 25, 0.9) 46.5%, #1b1f26 100%) padding-box,
    linear-gradient(to bottom, ${BORDER_TOP_GREY}, #ffffff00) border-box;
  border: 1px solid transparent; */
`;

const Backlight = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 1600px;
  height: 339px;

  background: linear-gradient(
    14.79deg,
    ${COLOR1} -0.75%,
    ${COLOR1} 10.66%,
    ${COLOR2} 25.22%,
    rgba(73, 127, 243, 0.1) 44.11%
  );
  filter: blur(37.2px);
  border-radius: 24px;
  transform: rotate(-180deg);

  @media (max-width: 1240px) {
    display: none;
  }
`;

const Svg1 = styled.svg`
  position: absolute;
  left: 50%;
  transform: translateX(-58px);
  top: 250px;

  @media (max-width: 1240px) {
    display: none;
  }
`;

const SvgLeftToRight = () => (
  <Svg1 width="450" height="349" viewBox="0 0 450 349" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3766_288)">
      <path
        d="M6 7H35.7536C48.4562 7 58.7536 17.2975 58.7536 30V260C58.7536 272.703 69.0511 283 81.7536 283H420C432.703 283 443 293.297 443 306V342.5"
        stroke="url(#paint0_angular_3766_288)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3766_288"
        x="0.2"
        y="0.7"
        width="449.1"
        height="347.6"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.415296 0 0 0 0 0.912516 0 0 0 0 0.912516 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3766_288" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3766_288" result="shape" />
      </filter>
      <radialGradient
        id="paint0_angular_3766_288"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(58 142) rotate(-2.85415) scale(67.6588 160.555)"
      >
        <stop stop-color="white" />
        <stop offset="0.2" stop-color="#497FF3" />
        <stop offset="0.505" stop-color="white" />
        <stop offset="0.65" stop-color="#3EB4E3" />
        <stop offset="0.835" stop-color="#38D0DA" />
        <stop offset="1" stop-color="#38D0DA" />
      </radialGradient>
    </defs>
  </Svg1>
);

const Svg2 = styled.svg`
  position: absolute;
  position: absolute;
  left: 50%;
  transform: translateX(-58px);
  top: 900px;

  @media (max-width: 1240px) {
    display: none;
  }
`;

const SvgRightToLeft = () => (
  <Svg2 width="450" height="348" viewBox="0 0 450 348" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g filter="url(#filter0_d_3766_4810)">
      <path
        d="M6 341.5H35.7536C48.4562 341.5 58.7536 331.203 58.7536 318.5V85.5C58.7536 72.7975 69.0511 62.5 81.7536 62.5H420C432.703 62.5 443 52.2025 443 39.5V6"
        stroke="url(#paint0_angular_3766_4810)"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_3766_4810"
        x="0.2"
        y="0.2"
        width="449.1"
        height="347.6"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="2.9" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix type="matrix" values="0 0 0 0 0.415296 0 0 0 0 0.912516 0 0 0 0 0.912516 0 0 0 1 0" />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_3766_4810" />
        <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_3766_4810" result="shape" />
      </filter>
      <radialGradient
        id="paint0_angular_3766_4810"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(58 206.5) rotate(2.85415) scale(67.6588 160.555)"
      >
        <stop stop-color="white" />
        <stop offset="0.2" stop-color="#497FF3" />
        <stop offset="0.505" stop-color="white" />
        <stop offset="0.65" stop-color="#3EB4E3" />
        <stop offset="0.835" stop-color="#38D0DA" />
        <stop offset="1" stop-color="#38D0DA" />
      </radialGradient>
    </defs>
  </Svg2>
);

const PngLastLine = styled.img`
  position: absolute;
  top: 1406px;
  width: 400px;
  left: 50%;
  transform: translateX(-99%);

  @media (max-width: 1240px) {
    display: none;
  }
`;

const JourneySectionV2 = ({
  title = "Your Gen-AI blueprint starts with a question",
  subTitle = "Everything you need, straight from your chatbox.",
  placeholder = "What can we do for you?",
  bottomButtons = [
    { text: "Check financial model" },
    { text: "Highlight risks" },
    { text: "Write draft email" },
    { text: "Build a database" },
  ],
  imagesSections = [
    {
      imageSrc: "",
      title: "Aggregate",
      text: "Pull your data from <b>emails, websites, external feeds, PDFs, excels</b> and images.",
    },
  ],
}) => {
  const isMobile = window?.screen?.width < 1240;
  return (
    <Container>
      <BacklitPanel>
        <Backlight />
        <PanelRect />
      </BacklitPanel>
      <TopContent>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <SubTitle
          dangerouslySetInnerHTML={{
            __html: subTitle,
          }}
        />
        <Gap height={isMobile ? "40px" : "70px"} />

        <InputContainer>
          <Input disabled value={placeholder} />
          <PaperClipIcon
            style={{
              fill: "white",
              position: "absolute",
              right: "48px",
              top: "50%",
              transform: "translateY(-50%) rotate(-225deg)",
              height: "24px",
            }}
          />
          <SendIcon
            style={{
              fill: "white",
              position: "absolute",
              right: "20px",
              top: "50%",
              transform: "translateY(-50%)",
              height: "18px",
            }}
          />
        </InputContainer>
        <BottomButtons>
          {bottomButtons.map((button, i) => (
            <BottomButton key={i}>{button.text}</BottomButton>
          ))}
        </BottomButtons>
      </TopContent>
      <Gap height="10px" />
      <ImageSections>
        <SvgLeftToRight />
        <SvgRightToLeft />
        <PngLastLine src="/images/last-line.png" />
        {imagesSections.map((section, i) => (
          <>
            {/* <SvgDivider style={{ transform: i % 2 === 0 ? "scaleX(-1)" : "" }} /> */}
            {i === 0 ? <Gap height="100px" /> : <Gap height="140px" />}
            <ImageSection
              key={i}
              // style={{ gridTemplateColumns: i % 2 === 0 ? "auto 1fr" : "1fr auto" }}
            >
              <ImageContent
                style={{ gridColumn: i % 2 === 0 ? 1 : 2, justifySelf: i % 2 === 0 ? "end" : "start" }}
                src={section?.imageSrc}
              />
              {section?.stickerSrc && <ImageSticker src={section?.stickerSrc} />}
              <TextContent style={{ gridColumn: i % 2 === 0 ? 2 : 1, justifySelf: i % 2 === 0 ? "start" : "end" }}>
                <SectionTitle>{section?.title}</SectionTitle>
                <SectionDescription
                  dangerouslySetInnerHTML={{
                    __html: section?.text,
                  }}
                />
              </TextContent>
            </ImageSection>
          </>
        ))}
        <Gap height="120px" />
      </ImageSections>
      {/* <svg
        style={{ marginLeft: "300px", marginTop: "-120px" }}
        width="33"
        height="293"
        viewBox="0 0 33 293"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="293" x2="0.5" stroke="#B9B9B9" stroke-opacity="0.33" />
        <line x1="32.144" y1="293" x2="32.144" stroke="#B9B9B9" stroke-opacity="0.33" />
      </svg> */}
    </Container>
  );
};

export default JourneySectionV2;
