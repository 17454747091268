import * as backend from "api/backend/marketingServiceEndpoints";
import { sleep } from "utils/common";

export const postContactUsDetails = async body => {
  const { data, error } = await backend.postContactUsDetails(body);
  return { data, error };
};

export const postNewsletterEmail = async body => {
  const { data, error } = await backend.postNewsletterEmail(body);
  return { data, error };
};

export const getWebsiteContents = async params => {
  const { data, error } = await backend.getWebsiteContents(params);
  return { data, error };
};

export const getWebsiteContentsVersion = async (version, params) => {
  const { data, error } = await backend.getWebsiteContentsVersion(version, params);
  return { data, error };
};

export const postWebsiteContents = async (params, body) => {
  const { data, error } = await backend.postWebsiteContents(params, body);
  return { data, error };
};

export const postUploadFiles = async (params, body, onUploadProgress = () => {}) => {
  const { data, error } = await backend.postUploadFiles(params, body, onUploadProgress);
  return { data, error };
};

export const postVerify = async (params, body) => {
  const { data, error } = await backend.postUrlTokensVerify(params, body);
  return { data, error };
};

export const postSlideTracking = async (params, body) => {
  const { data, error } = await backend.postSlideTracking(params, body);
  return { data, error };
};

export const getConfirmEmail = async (accessToken, params) => {
  const { data, error } = await backend.getConfirmEmail(accessToken, params);
  return { data, error };
};

export const getUnsubscribe = async params => {
  const { data, error } = await backend.getUnsubscribe(params);
  return { data, error };
};

export const postSurvey = async (params, body) => {
  const { data, error } = await backend.postSurvey(params, body);
  return { data, error };
};

const TEMPLATES = [
  {
    id: "id0",
    prompt: "financial deals summary",
    dashboardConfig: {},
    wordDoc: {},
  },
  {
    id: "id1",
    prompt: "property deals dashboard",
    dashboardConfig: {},
    wordDoc: {},
  },
];

export const getFlowDatabaseTemplates = async params => {
  const { data, error } = await backend.getFlowDatabaseTemplates(params);
  return { data, error };
};
