import ButtonDark from "components/ButtonDark";
import ContactUsModal from "components/ContactUsModal";
import styled from "styled-components";

const Container = styled.div`
  background-image: linear-gradient(
    0deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
  color: white;
  font-size: 48px;
  text-align: center;
  padding: 40px;
  font-weight: 200;
`;

const BannerSectionMobile = ({ text, heading }) => {
  return (
    <Container>
      <ContactUsModal
        triggerComponent={
          <ButtonDark
            style={{
              width: "180px",
              height: "42px",
              fontSize: "18px",
            }}
          >
            {text}
          </ButtonDark>
        }
      />
    </Container>
  );
};

export default BannerSectionMobile;
