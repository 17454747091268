import ButtonDark from "components/ButtonDark";
import { ChevronDown } from "components/ui/Icons";
import { useNavigate, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  height: 100vh;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 50px;
  align-content: center;
  color: white;

  background-image: linear-gradient(
    45deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-size: 52px;
  line-height: 85px;
  display: flex;
  align-items: center;
  text-align: center;
  color: #ffffff;
  width: max-content;
`;

const CardsContainer = styled.div`
  display: flex;
  gap: 40px;
  padding: 0 50px;
`;

const Card = styled.div`
  border: 0.2px solid #8b8b8b;
  padding: 40px;
  padding-top: 60px;
  padding-bottom: 50px;
  display: grid;
  justify-content: center;
  justify-items: center;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 4px #ffffff16;
  max-width: 424px;
`;

const CardTitle = styled.div`
  font-size: 40px;
  padding-bottom: 30px;
`;

const CardText = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 200;
  font-size: 20px;
  line-height: 30px;
  padding-bottom: 40px;
  width: 300px;
`;

const IconContainer = styled.div`
  padding-bottom: 60px;
  svg {
    fill: #4e5155;
  }
`;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  bottom: 40px;
  justify-self: center;
  margin-top: 100px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`;

const CardListSection = ({ title, cards = [{ iconComponent: null, title: "", text: "" }] }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();

  return (
    <Container>
      <Title>{title}</Title>
      <CardsContainer>
        {cards.map((card, i) => {
          return (
            <Card key={i}>
              {/* <IconContainer>{card.iconComponent}</IconContainer> */}
              <CardTitle>{card.title}</CardTitle>
              <CardText>{card.text}</CardText>
              <ButtonDark
                onClick={() => {
                  searchParams.set("promptIndex", i);
                  navigate(`?${searchParams.toString()}`);
                }}
              >
                Try it out
              </ButtonDark>
            </Card>
          );
        })}
      </CardsContainer>

      <StyledChevronDown
        onClick={() => window.scrollTo({ top: window.innerHeight * 2, behavior: "smooth" })}
        style={{ height: "40px", stroke: "white", strokeWidth: "2px" }}
      />
    </Container>
  );
};

export default CardListSection;
