import { BoltzflowLogo, BoltzhubLogo } from "components/ui/Icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

const LOGOS = {
  flow: <BoltzflowLogo style={{ fill: "black", height: "37px" }} />,
  hub: <BoltzhubLogo style={{ fill: "black", height: "37px" }} />,
};

const TopContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr repeat(6, auto);
  gap: 22px;
  padding: 20px 20px;
  align-items: center;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 0.2s ease-in-out;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
  color: ${props => props.theme.color.closest};
`;

const FlexLink = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: ${props => props.theme.color.furthest};
`;

const NavLink = styled.a`
  font-size: 16px;
  text-decoration: none;
  color: ${props => props.theme.color.closest};
  :hover {
    opacity: 0.8;
  }
`;

const TopNav = ({ logo = "flow" }) => {
  return (
    <TopContainer>
      <FlexLink>
        {LOGOS[logo]}
        <Title>{logo === "flow" ? "Boltzflow" : "Boltzhub"}</Title>
      </FlexLink>
      <NavLink href="https://boltzbit.com/" target="_blank">
        Boltzbit
      </NavLink>
      {logo === "flow" ? (
        <NavLink href="/hub?token=e8f44f7a-bb23-4360-b19b-56739f5c1b9e" target="_blank">
          Boltzhub
        </NavLink>
      ) : (
        <NavLink href="/flow?token=e8f44f7a-bb23-4360-b19b-56739f5c1b9e" target="_blank">
          Boltzflow
        </NavLink>
      )}
      <NavLink href="https://boltzbit.com/about/team/" target="_blank">
        About
      </NavLink>
      <NavLink href="https://boltzbit.com/news" target="_blank">
        Blog
      </NavLink>
      <NavLink href={logo === "flow" ? "https://boltzflow.com/" : "https://boltzhub.com/"} target="_blank">
        Login
      </NavLink>
    </TopContainer>
  );
};

export default TopNav;
