import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import SectionsViewerMobileMinimal from "components/SectionsViewerMobileMinimal";
import { CommentTickIcon, GearIcon, ProcessorIcon } from "components/ui/Icons";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "hero",
    title: "The Gen-AI Engine for Enterprise.",
    subTitle: "Fuel insights from your business data on private models.",
    buttons: [{ text: "Talk to us" }, { text: "Try it yourself" }],
  },
  {
    type: "card-list",
    title: "What can a Gen-AI Engine do?",
    cards: [
      {
        iconComponent: <ProcessorIcon />,
        title: "Extract",
        text: "Pull your data from emails, websites, external feeds, PDFs, excels and images.",
      },
      {
        iconComponent: <GearIcon />,
        title: "Analyse",
        text: "Your private model understands your requests and retrieves your data.",
      },
      {
        iconComponent: <CommentTickIcon />,
        title: "Action",
        text: "Use your predictions, forecasts and insights to grow revenue faster.",
      },
    ],
  },
  {
    type: "prompts",
    title: "Find your solution...",
    prompts: [{ text: "database schema for 10 employees" }, { text: "financial report for a car company" }],
  },
  {
    type: "scroller-minimal",
    textAlignment: "right",
    colorScheme: {
      background: "linear-gradient(45deg, #000000 0%, #0D1D34 90%)",
      heading: "#ffffff",
      progress: "#abc9ff",
      inactiveOptionLink: "#b8b8b8",
    },
    options: [
      {
        imageSources: [],
        optionName: "Why our customers choose us",
        headingLines: ["Keep your data", "in your organisation"],
        subHeading: "",
        text: "Our tailored Gen-AI models are deployed and managed by you for security.<br />Host your solution on public, private cloud or on-prem for maximum control.",
      },
      {
        imageSources: [],
        optionName: "Why our customers choose us",
        headingLines: ["Customise your AI", "for the best results"],
        subHeading: "",
        text: "Every business is unique. By using your own data, you can boost<br /> the accuracy of your models with our user friendly fine-tuning.",
      },
      {
        imageSources: [],
        optionName: "Why our customers choose us",
        headingLines: ["Lay foundations", "you can build on"],
        subHeading: "",
        text: "This is just the beginning. Build AI Engines to solve<br/>your problems, and automate your solutions.",
      },
    ],
  },
  {
    type: "banner",
    heading: "Take the next step",
    text: "Talk to us",
  },
  {
    type: "footer-minimal",
  },
];

const DesktopContainer = styled.div`
  display: none;
`;

const MobileContainer = styled.div``;

const Container = styled.div`
  @media (min-width: 1240px) {
    ${DesktopContainer} {
      display: block;
    }
    ${MobileContainer} {
      display: none;
    }
  }
`;

const MainPage = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "0ced6987-57da-4c35-938d-418acc5d328a") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
      <MobileContainer>
        <SectionsViewerMobileMinimal sections={SECTIONS} templates={templates} isFooterHidden />
      </MobileContainer>
    </Container>
  );
};

export default MainPage;
