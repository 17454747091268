import Main from "components/Main";
import Scroller from "components/Scroller";
import Columns from "components/Columns";
import Footer from "components/Footer";
import StartNow from "components/StartNow";
import Testimonials from "components/Testimonials";
import Customers from "components/Customers";
import VideoSection from "components/VideoSection";
import Message from "components/Message";
import TableSection from "components/TableSection";
import AnimatedContent from "components/AnimatedContent";
import TestimonialsSimple from "components/TestimonialsSimple";
import ColumnsAndTimeline from "components/ColumnsAndTimeline";
import HeroSection from "components/HeroSection";
import PromptsSection from "components/PromptsSection";
import ScrollerMinimal from "components/ScrollerMinimal";
import FooterMinimalSection from "components/FooterMinimalSection";
import BannerSection from "components/BannerSection";
import CardListSection from "components/CardListSection";
import HeroSectionV1 from "components/HeroSectionV1";
import JourneySection from "components/JourneySection";
import WordCarouselSection from "components/WordCarouselSection";
import ColumnsSectionV1 from "components/ColumnsSectionV1";
import FaqSection from "components/FaqSection";
import BannerSectionV1 from "components/BannerSectionV1";
import FooterSectionV1 from "components/FooterSectionV1";
import MessageSectionV1 from "components/MessageSectionV1";
import UnderConstructionSection from "components/UnderConstructionSection";
import AboutUsSection from "components/AboutUsSection";
import ResearchTitleSection from "components/ResearchTitleSection";
import ResearchIllustrationSection from "components/ResearchIllustrationSection";
import StatsSection from "components/StatsSection";
import IllustrationTwoColumnSection from "components/IllustrationTwoColumnSection";
import ResearchPapersSection from "components/ResearchPapersSection";
import WordCloudSection from "components/WordCloudSection";
import ThreeCardsSection from "components/ThreeCardsSection";
import PeopleSection from "components/PeopleSection";
import TeamsSection from "components/TeamsSection";
import RolesSection from "components/RolesSection";
import ColoredBlocksSection from "components/ColoredBlocksSection";
import HeroSectionV2 from "components/HeroSectionV2";
import MessageSectionV2 from "components/MessageSectionV2";
import JourneySectionV2 from "components/JourneySectionV2";
import WordCloudSectionV2 from "components/WordCloudSectionv2";
import ThreeCardsSectionV2 from "components/ThreeCardsSectionV2";
import BannerSectionV2 from "components/BannerSectionV2";
import FooterSectionV2 from "components/FooterSectionV2";
import ResearchTitleSectionV2 from "components/ResearchTitleSectionV2";
import MessageGraphicSection from "components/MessageGraphicSection";
import MessageListSection from "components/MessageListSection";
import PeopleSectionV2 from "components/PeopleSectionV2";
import TeamsSectionV2 from "components/TeamsSectionV2";
import OneIllustrationSection from "components/OneIllustrationSection";
import ResearchIllustrationSectionV2 from "components/ResearchIllustrationSectionV2";
import ResearchPapersSectionV2 from "components/ResearchPapersSectionV2";
import MessageWithBackground from "components/MessageWithBackground";
import MessageWithBackgroundLight from "components/MessageWithBackgroundLight";
import RequestGroup from "components/RequestGroup";
import ContactUsSection from "components/ContactUsSection";
import LogosSection from "components/LogosSection";

const SectionsViewer = ({ sections, templates = [], isFooterHidden }) => {
  return (
    <>
      {sections.map((section, i) => {
        if (section?.type === "contact-us") {
          return <ContactUsSection key={i} />;
        }
        if (section?.type === "request-group") {
          return <RequestGroup key={i} {...section} />;
        }
        if (section?.type === "message-with-background-light") {
          return <MessageWithBackgroundLight key={i} {...section} />;
        }
        if (section?.type === "message-with-background") {
          return <MessageWithBackground key={i} {...section} />;
        }
        if (section?.type === "research-papers-v2") {
          return <ResearchPapersSectionV2 key={i} {...section} />;
        }
        if (section?.type === "research-illustration-v2") {
          return <ResearchIllustrationSectionV2 key={i} {...section} />;
        }
        if (section?.type === "one-illustration") {
          return <OneIllustrationSection key={i} {...section} />;
        }
        if (section?.type === "teams-v2") {
          return <TeamsSectionV2 key={i} {...section} />;
        }
        if (section?.type === "people-v2") {
          return <PeopleSectionV2 key={i} {...section} />;
        }
        if (section?.type === "message-list") {
          return <MessageListSection key={i} {...section} />;
        }
        if (section?.type === "research-title-v2") {
          return <ResearchTitleSectionV2 key={i} {...section} />;
        }
        if (section?.type === "message-graphic") {
          return <MessageGraphicSection key={i} {...section} />;
        }
        if (section?.type === "footer-v2") {
          return <FooterSectionV2 key={i} />;
        }
        if (section?.type === "banner-v2") {
          return <BannerSectionV2 key={i} {...section} />;
        }
        if (section?.type === "three-cards-v2") {
          return <ThreeCardsSectionV2 key={i} {...section} />;
        }
        if (section?.type === "word-cloud-v2") {
          return <WordCloudSectionV2 key={i} {...section} />;
        }
        if (section?.type === "journey-v2") {
          return <JourneySectionV2 key={i} {...section} />;
        }
        if (section?.type === "message-v2") {
          return <MessageSectionV2 key={i} {...section} />;
        }
        if (section?.type === "hero-v2") {
          return <HeroSectionV2 key={i} {...section} />;
        }
        if (section?.type === "colored-blocks") {
          return <ColoredBlocksSection key={i} {...section} />;
        }
        if (section?.type === "roles") {
          return <RolesSection key={i} {...section} />;
        }
        if (section?.type === "teams") {
          return <TeamsSection key={i} {...section} />;
        }
        if (section?.type === "people") {
          return <PeopleSection key={i} {...section} />;
        }
        if (section?.type === "three-cards") {
          return <ThreeCardsSection key={i} {...section} />;
        }
        if (section?.type === "word-cloud") {
          return <WordCloudSection key={i} {...section} />;
        }
        if (section?.type === "research-title") {
          return <ResearchTitleSection key={i} {...section} />;
        }
        if (section?.type === "research-illustration") {
          return <ResearchIllustrationSection key={i} {...section} />;
        }
        if (section?.type === "stats") {
          return <StatsSection key={i} {...section} />;
        }
        if (section?.type === "illustration-two-column") {
          return <IllustrationTwoColumnSection key={i} {...section} />;
        }
        if (section?.type === "research-papers") {
          return <ResearchPapersSection key={i} {...section} />;
        }
        if (section.type === "under-construction") {
          return <UnderConstructionSection key={i} />;
        }
        if (section.type === "hero-v1") {
          return <HeroSectionV1 key={i} {...section} />;
        }
        if (section.type === "about-us") {
          return <AboutUsSection key={i} {...section} />;
        }
        if (section.type === "message-v1") {
          return <MessageSectionV1 key={i} {...section} />;
        }
        if (section?.type === "journey") {
          return <JourneySection key={i} {...section} />;
        }
        if (section?.type === "word-carousel") {
          return <WordCarouselSection key={i} {...section} />;
        }
        if (section.type === "columns-v1") {
          return <ColumnsSectionV1 key={i} {...section} />;
        }
        if (section?.type === "faq") {
          return <FaqSection key={i} {...section} />;
        }
        if (section.type === "banner-v1") {
          return <BannerSectionV1 key={i} {...section} />;
        }
        if (section.type === "footer-v1") {
          return <FooterSectionV1 key={i} />;
        }
        if (section.type === "hero") {
          return <HeroSection key={i} {...section} />;
        }
        if (section.type === "logos") {
          return <LogosSection key={i} {...section} />;
        }
        if (section.type === "card-list") {
          return <CardListSection key={i} {...section} />;
        }

        if (section.type === "prompts") {
          return <PromptsSection key={i} {...section} templates={templates} />;
        }

        if (section.type === "animated-content") {
          return (
            <AnimatedContent
              key={i}
              messageLine1={section?.messageLine1}
              messageLine2={section?.messageLine2}
              description={section?.description}
            />
          );
        }
        if (section.type === "table") {
          return <TableSection key={i} rows={section.rows} headerBg={section?.headerBg} />;
        }
        if (section.type === "video") {
          return (
            <VideoSection
              key={i}
              messageLine1={section?.messageLine1}
              messageLine2={section?.messageLine2}
              messageLine2Blue={section?.messageLine2Blue}
              typewriterMessages={section?.typewriterMessages}
              logo={section?.logo}
              videoSrc={section?.videoSrc}
              templates={templates}
            />
          );
        }
        if (section.type === "main") {
          return <Main key={i} heading={section.heading} text={section.text} />;
        }
        if (section.type === "message") {
          return (
            <Message
              key={i}
              messageLine1={section?.messageLine1}
              messageLine2={section?.messageLine2}
              description={section?.description}
            />
          );
        }

        if (section.type === "scroller-minimal") {
          return (
            <ScrollerMinimal
              textAlignment={section.textAlignment}
              key={i}
              colorScheme={section?.colorScheme}
              options={section.options}
            />
          );
        }

        if (section.type === "scroller") {
          return (
            <Scroller
              textAlignment={section.textAlignment}
              key={i}
              colorScheme={section?.colorScheme}
              options={section.options}
            />
          );
        }
        if (section.type === "columns-and-timeline") {
          return (
            <ColumnsAndTimeline
              key={i}
              heading={section.heading}
              columns={section.columns}
              timeline={section.timeline}
            />
          );
        }
        if (section.type === "columns") {
          return (
            <Columns key={i} heading={section.heading} subHeading={section.subHeading} columns={section.columns} />
          );
        }
        if (section.type === "startnow") {
          return <StartNow key={i} bg={section?.bg} />;
        }
        if (section.type === "testimonials") {
          return <Testimonials heading={section?.heading} slides={section?.slides} key={i} />;
        }
        if (section.type === "testimonials-simple") {
          return <TestimonialsSimple heading={section?.heading} slides={section?.slides} key={i} />;
        }
        if (section.type === "customers") {
          return <Customers logos={section.logos} key={i} />;
        }

        if (section.type === "banner") {
          return <BannerSection key={i} {...section} />;
        }

        if (section.type === "footer-minimal") {
          return <FooterMinimalSection key={i} {...section} />;
        }

        return <div key={i}>{JSON.stringify(section)}</div>;
      })}
      {!isFooterHidden && <Footer />}
    </>
  );
};

export default SectionsViewer;
