import { useEffect, useState } from "react";
import styled from "styled-components";
import { last } from "lodash";
import { Link, useSearchParams } from "react-router-dom";

import SectionsViewer from "components/SectionsViewer";
import { getWebsiteContents, postWebsiteContents } from "api/services/marketingService";

const SELECT_KEYS = ["type", "textAlignment"];
const SELECT_VALUES = {
  type: ["main", "scroller", "testimonials", "customers", "columns", "startnow"],
  textAlignment: ["left", "right"],
};

const ARRAY_KEYS = ["options", "slides", "columns"];
const ARRAY_VALUES = {
  options: ["optionName", "heading", "subHeading", "text"],
  slides: ["text", "name", "company"],
  columns: ["heading", "text"],
};

const OBJECT_KEYS = ["colorScheme"];
const OBJECT_VALUES = {
  colorScheme: ["background", "heading", "progress", "inactiveOptionLink"],
};

const TYPE_TO_INITIAL_SECTION = {
  main: {
    type: "main",
    heading: "Heading",
    text: "Text is here",
  },
  scroller: {
    type: "scroller",
    colorScheme: {
      background: "",
      heading: "",
      progress: "",
      inactiveOptionLink: "",
    },
    options: [
      {
        optionName: "Lorem ipsum dolor",
        heading: "Lorem ipsum suspense",
        subHeading: "Dolore magna aliqua",
        text: "Lorem ipsum dolor sit amet, conseceur altrices adipiscing elia sed do eiusmod incididunt  tempoe a labore a dolore magna aliqua.",
      },
    ],
  },
  columns: {
    type: "columns",
    heading: "Lorem",
    subHeading: "mm",
    columns: [
      {
        icon: "iconName",
        heading: "Lorem ipsum dolor",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      },
      {
        icon: "iconName",
        heading: "Consectetur adipiscing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      },
      {
        icon: "iconName",
        heading: "Consectetur adipiscing elit",
        text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua",
      },
    ],
  },
  testimonials: {
    type: "testimonials",
    heading: "What clients say",
    slides: [
      {
        text: "Lorem ipsum dolor conresa adipiscing elit, eiusmod teor labore dolore magna aliqua.",
        name: "Michael Macnell",
        company: "ID Studio UK",
      },
      {
        text: "Blabus porem ipsum dolor sit amet, conseceur altrices.",
        name: "Steve Jobs",
        company: "Apple",
      },
    ],
  },
  customers: {
    type: "customers",
  },
  startnow: {
    type: "startnow",
  },
};

const EditTopNav = styled.div`
  border-bottom: 1px solid grey;
  position: sticky;
  top: 0;
  font-size: 14px;
  background-color: white;
  padding: 8px;
  z-index: 1;

  display: grid;
  gap: 10px;
  justify-content: start;
  grid-template-columns: auto auto 1fr auto;

  * {
    font-size: 14px;
  }
`;

const GoToSiteLink = styled(Link)`
  justify-self: end;
`;

const PublishButton = styled.button`
  justify-self: end;
`;

const SectionsContainer = styled.div`
  padding: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  * {
    font-size: 14px;
    font-family: "Montserrat";
  }
  padding-bottom: 100px;
`;

const SectionContainer = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  justify-content: start;
  align-items: start;
  row-gap: 20px;
  column-gap: 10px;

  background-color: #eaeaea;
  width: 100%;
`;

const JsonContainer = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 5px;
  background-color: #00000012;
  padding: 8px;
  border-radius: 4px;
`;

const Select = styled.select`
  display: block;
`;

const JsonListContainer = styled.div`
  display: grid;
  gap: 5px;
  justify-items: start;
`;

const ItemContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: start;
  gap: 5px;
`;

const VersionsSelect = styled.select`
  width: 100px;
`;

const EditableJson = ({ keys = [], value = {}, onChangeValue = () => {} }) => {
  return (
    <JsonContainer>
      {keys.map(key => {
        return (
          <>
            {key}
            <textarea
              value={value[key]}
              onChange={e => {
                onChangeValue({ ...value, [key]: e.target.value });
              }}
            />
          </>
        );
      })}
    </JsonContainer>
  );
};

const EditableJsonList = ({ itemKeys = [], items = [], onChangeItems = () => {} }) => {
  return (
    <JsonListContainer>
      {items.map((item, index) => {
        return (
          <ItemContainer>
            <EditableJson
              keys={itemKeys}
              value={item}
              onChangeValue={newItem => {
                const newItems = [...items];
                newItems[index] = newItem;
                onChangeItems(newItems);
              }}
            />
            <button
              onClick={() => {
                const newItems = [...items];
                newItems.splice(index, 1);
                onChangeItems(newItems);
              }}
            >
              Remove
            </button>
          </ItemContainer>
        );
      })}
      <button
        onClick={() => {
          const newItem = {};
          itemKeys.forEach(key => {
            newItem[key] = "";
          });
          onChangeItems([...items, newItem]);
        }}
      >
        Add
      </button>
    </JsonListContainer>
  );
};

const Section = ({ section = {}, onChangeSection = () => {} }) => {
  return (
    <SectionContainer>
      {Object.entries(section).map(([key, value]) => {
        if (SELECT_KEYS.includes(key)) {
          return (
            <>
              {key}
              <Select
                value={value}
                onChange={e => {
                  onChangeSection(TYPE_TO_INITIAL_SECTION[e.target.value]);
                }}
              >
                {SELECT_VALUES[key].map(value => (
                  <option value={value}>{value}</option>
                ))}
              </Select>
            </>
          );
        }

        if (ARRAY_KEYS.includes(key)) {
          return (
            <>
              {key}
              <EditableJsonList
                itemKeys={ARRAY_VALUES[key]}
                items={value}
                onChangeItems={newItems => {
                  onChangeSection({ ...section, [key]: newItems });
                }}
              />
            </>
          );
        }

        if (OBJECT_KEYS.includes(key)) {
          return (
            <>
              {key}
              <EditableJson
                keys={OBJECT_VALUES[key]}
                value={value}
                onChangeValue={newValue => {
                  onChangeSection({ ...section, [key]: newValue });
                }}
              />
            </>
          );
        }

        if (typeof value === "string") {
          return (
            <>
              {key}
              <textarea
                value={value}
                onChange={e => {
                  onChangeSection({ ...section, [key]: e.target.value });
                }}
              />
            </>
          );
        }

        return null;
      })}
    </SectionContainer>
  );
};

const AddButton = styled.button`
  width: max-content;
`;

const SectionAndButtons = styled.div`
  display: flex;
  align-items: start;
  background-color: #eaeaea;

  padding: 8px;
  border-radius: 4px;
`;

const SectionButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
`;

const EditPage = () => {
  const [searchParams] = useSearchParams();

  const [allContents, setAllContents] = useState([]);
  const [sections, setSections] = useState([]);
  const [isPreviewing, setIsPreviewing] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedVersion, setSelectedVersion] = useState(1);
  const [isPostingNewVersion, setIsPostingNewVersion] = useState(false);

  useEffect(() => {
    doPopulateAllContents();
  }, []);

  const doPopulateAllContents = async () => {
    setIsLoading(true);

    const { data: websiteContents } = await getWebsiteContents({ token: searchParams?.get("token") });
    setAllContents(websiteContents || []);
    setSelectedVersion(last(websiteContents)?.version || 1);
    setSections(last(websiteContents)?.jsonContent || []);

    setIsLoading(false);
  };

  const doPostWebsiteContents = async () => {
    setIsPostingNewVersion(true);
    const { error } = await postWebsiteContents(
      { token: searchParams?.get("token") },
      {
        jsonContent: sections,
      }
    );
    setIsPostingNewVersion(false);

    if (error) {
      alert(JSON.stringify(error));
      return;
    }
    window.location.reload();
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <EditTopNav isDisabled={isPostingNewVersion}>
        <button
          onClick={() => {
            setIsPreviewing(!isPreviewing);
            window.scrollTo(0, 0);
          }}
        >
          {isPreviewing ? "Edit" : "Preview"}
        </button>
        <div>
          version
          <VersionsSelect
            value={selectedVersion}
            onChange={e => {
              const newVersion = parseInt(e.target.value);
              setSelectedVersion(newVersion);
              const newSections = allContents.find(content => content.version === newVersion)?.jsonContent || [];
              setSections(newSections);
            }}
          >
            {allContents.map((content, i) => (
              <option key={i}>{content?.version}</option>
            ))}
          </VersionsSelect>
        </div>
        <GoToSiteLink to={`/?token=${searchParams?.get("token")}`}>Go to site</GoToSiteLink>
        <PublishButton onClick={doPostWebsiteContents}>Create new version & publish</PublishButton>
      </EditTopNav>
      {!isPreviewing && (
        <SectionsContainer>
          {sections.map((section, index) => (
            <SectionAndButtons>
              <Section
                key={index}
                section={section}
                onChangeSection={newSection => {
                  const newSections = [...sections];
                  newSections[index] = newSection;
                  setSections(newSections);
                }}
              />
              <SectionButtonsContainer>
                <button
                  onClick={() => {
                    const newSections = [...sections];
                    newSections.splice(index, 1);
                    setSections(newSections);
                  }}
                >
                  Remove
                </button>
                <button
                  onClick={() => {
                    const newSections = [...sections];
                    const temp = newSections[index];
                    newSections[index] = newSections[index - 1];
                    newSections[index - 1] = temp;
                    setSections(newSections);
                  }}
                >
                  Move Up
                </button>
                <button
                  onClick={() => {
                    const newSections = [...sections];
                    const temp = newSections[index];
                    newSections[index] = newSections[index + 1];
                    newSections[index + 1] = temp;
                    setSections(newSections);
                  }}
                >
                  Move Down
                </button>
              </SectionButtonsContainer>
            </SectionAndButtons>
          ))}
          <AddButton
            onClick={() => {
              setSections([...sections, TYPE_TO_INITIAL_SECTION.main]);
            }}
          >
            Add new
          </AddButton>
        </SectionsContainer>
      )}
      {isPreviewing && <SectionsViewer sections={sections} />}
    </>
  );
};

export default EditPage;
