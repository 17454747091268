import styled from "styled-components";
import { useSearchParams } from "react-router-dom";

import Button from "components/Button";
import { useEffect, useState } from "react";
import { postUploadFiles } from "api/services/marketingService";
import FileInput from "components/FileInput";

const TopContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(5, auto);
  gap: 22px;
  padding: 20px;
  align-items: center;
  z-index: 1;
`;

const Container = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background-image: url("/images/color1.jpeg");
  background-size: cover;
  background-position: center;
`;

const Title = styled.div`
  font-size: 28px;
  font-weight: 500;
  padding-bottom: 20px;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.furthest};
  width: max-content;
`;

const TopLogoImg = styled.img`
  height: 35px;
`;

const WhiteButton = styled(Button)`
  padding: 10px 32px;
  color: white;
  :hover {
    opacity: 0.8;
  }
`;

const InputContainer = styled.div`
  margin-top: 200px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  margin-left: 100px;
  background-color: #f5f5f5;
  width: max-content;

  border: 4px solid;
  border-image: linear-gradient(
    ${props => props.theme.color.highlightGrey},
    ${props => props.theme.color.highlightGrey}
  );
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);
`;

const Msg = styled.div`
  font-size: 16px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  min-height: 18px;
  max-width: 400px;
  overflow: auto;
`;

const Progress = styled.progress`
  width: 100%;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  height: 8px;
`;

const UploadPage = () => {
  const [searchParams] = useSearchParams();

  const [file, setFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [msg, setMsg] = useState("");
  const [progress, setProgress] = useState(0);
  const [token, setToken] = useState("");

  useEffect(() => {
    document.addEventListener("progress", updateProgress);
    return () => document.removeEventListener("progress", updateProgress);
  }, []);

  useEffect(() => {
    if (progress === 100) {
      setMsg("Almost done...");
    }
  }, [progress]);

  useEffect(() => {
    setToken(searchParams?.get("token"));
  }, [searchParams?.get("token")]);

  const updateProgress = e => {
    setProgress((e?.loaded / e?.total) * 100);
  };

  const doUploadFile = async () => {
    setMsg("Upload in progress...");
    setIsLoading(true);

    const formData = new FormData();
    formData.append("file", file);

    const domain = searchParams?.get("domain") || "";

    const { error } = await postUploadFiles({ domain }, formData, updateProgress);

    setIsLoading(false);
    setMsg("File uploaded successfully");
    if (error) {
      setMsg(error?.message || "Error uploading file. Please try again.");
    }
  };

  if (token !== "0ced6987-57da-4c35-938d-418a885d338a") {
    return null;
  }

  return (
    <Container>
      <TopContainer>
        <StyledLink href="https://boltzbit.com/">
          <TopLogoImg src="/images/boltzbit_logo_h_white.png" />
        </StyledLink>
      </TopContainer>

      <InputContainer>
        <Title>Upload file</Title>
        <FileInput buttonText="Select a file" onFileSelect={newFile => setFile(newFile)} />
        <Msg isVisible={msg}>{msg}</Msg>
        <Progress isVisible={isLoading} value={progress} max="100" />
        <WhiteButton isDisabled={isLoading || !file} value="Upload" onClick={doUploadFile} />
      </InputContainer>
    </Container>
  );
};

export default UploadPage;
