import ButtonDark from "components/ButtonDark";
import ButtonV1 from "components/ButtonV1";
import styled from "styled-components";

const Container = styled.div`
  color: ${props => (props.colorScheme === "light" ? "black" : "white")};
  font-size: 48px;
  text-align: center;
  padding: 40px;
  font-weight: 200;
  margin-top: 80px;
  padding-bottom: 40px;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const Frame = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 40px;
  width: 1200px;
  margin: 0 auto;

  border: 0.2px solid #8b8b8b;
  padding: 60px 140px;
  border-radius: 10px;
  /* box-shadow: 0px 0px 25px 0px #ffffff44; */

  ${props => props.colorScheme === "light" && "width: 1200px;"}
`;

const Heading = styled.div`
  font-size: 42px;
`;

const BannerSectionV1 = ({ text, heading, colorScheme }) => {
  return (
    <Container colorScheme={colorScheme}>
      <Frame colorScheme={colorScheme}>
        <Heading>{heading}</Heading>
        {/* <ContactUsModal triggerComponent={<ButtonDark>{text}</ButtonDark>} /> */}
      </Frame>
    </Container>
  );
};

export default BannerSectionV1;
