import ButtonDark from "components/ButtonDark";
import ButtonV1 from "components/ButtonV1";
import ContactUsModal from "components/ContactUsModal";
import styled from "styled-components";

const Container = styled.div`
  color: ${props => (props.colorScheme === "light" ? "black" : "white")};
  font-size: 48px;
  text-align: center;
  margin-top: 80px;
  position: relative;

  display: none;
  @media (min-width: 1240px) {
    display: grid;
  }
`;

const Frame = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 40px;
  margin: 0 auto;

  position: absolute;
  top: 200px;
  left: 50%;
  transform: translateX(-50%);
`;

const Heading = styled.div`
  font-size: 42px;
  font-weight: 600;
  line-height: 1.4;
`;

const WaveBackground = styled.img`
  opacity: 1;
  width: 100%;
  margin-top: -200px;
`;

const BannerSectionV2 = ({ text, heading, colorScheme }) => {
  return (
    <Container colorScheme={colorScheme}>
      <WaveBackground src="/images/waves-illustration.png" />
      <Frame colorScheme={colorScheme}>
        <Heading
          dangerouslySetInnerHTML={{
            __html: heading,
          }}
        />

        {/* <ContactUsModal triggerComponent={<ButtonDark>{text}</ButtonDark>} /> */}
        <ContactUsModal
          fieldNames={["name", "role", "email", "message"]}
          isShowingCalendarLink
          triggerComponent={<ButtonV1 isPrimary>{text}</ButtonV1>}
        />
      </Frame>
    </Container>
  );
};

export default BannerSectionV2;
