import { useState, useEffect } from "react";
import styled from "styled-components";

import { Gap } from "components/Layout";
import { BoltzbitLogo, BoltzbitLogoWithText, ChevronDown } from "components/ui/Icons";
import ButtonDark from "components/ButtonDark";
import ContactUsModal from "components/ContactUsModal";

const BackgroundContainer = styled.div`
  background-image: linear-gradient(
    135deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
`;

const Content = styled.div`
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 48px;
  color: white;
  transition: opacity 1s;
`;

const TopBar = styled.div`
  position: fixed;
  top: 0;
  padding: 8px 20px;
  z-index: 2;
  transition: opacity 1s;

  background-color: transparent;
  border-bottom: 1px solid #ffffff22;
  width: 100vw;
  backdrop-filter: blur(10px);

  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopBarLink = styled.a`
  color: white;
  font-size: 16px;
  text-decoration: none;
  font-weight: 600;
  :hover {
    color: lightgrey;
  }
`;

const Title = styled.div`
  font-size: 90px;
  text-align: center;
  font-weight: 400;
  width: 900px;
  line-height: 1.2;

  background-image: linear-gradient(135deg, #ffffff 0%, #d9d9d9 40%, #787368 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  justify-self: center;
`;

const SubTitle = styled.div`
  font-size: 42px;
  font-weight: 300;
  text-align: center;
  line-height: 1.4;

  background-image: linear-gradient(0deg, #ffffff 0%, #d9d9d9 40%, #c4bdae 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
`;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  bottom: 40px;
  justify-self: center;
  margin-top: 100px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`;

const StyledBoltzflowLogo = styled(BoltzbitLogoWithText)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: translate(-50%, -50%);
  height: 300px;
  opacity: 0;

  animation: fade 1.8s;
  @keyframes fade {
    0% {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1;
    }
    70% {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      transform: scale(1) translate(-50%, -50%);
      opacity: 0;
    }
  }
`;

const TopBarLogo = styled(BoltzbitLogoWithText)`
  cursor: pointer;
`;

const BgLogo = styled(BoltzbitLogo)`
  position: absolute;
  right: 40px;
  bottom: 20px;
  height: 600px;
  z-index: 0;
  opacity: 0.05;
`;

const HeroSection = ({ title, subTitle, buttons = [] }) => {
  const [msSincePageLoad, setMsSincePageLoad] = useState(2000);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMsSincePageLoad(msSincePageLoad + 100);
    }, 100);
    return () => clearInterval(timeoutId);
  }, [msSincePageLoad]);

  // if (msSincePageLoad < 1500) {
  //   return (
  //     <BackgroundContainer>
  //       <StyledBoltzflowLogo style={{ fill: "white" }} />
  //     </BackgroundContainer>
  //   );
  // }

  return (
    <BackgroundContainer>
      <TopBar
        style={{
          opacity: msSincePageLoad >= 1400 ? 1 : 0,
        }}
      >
        <TopBarLogo
          isGlowDisabled
          style={{
            height: "42px",
            fill: "white",
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
        <TopBarLink href="https://flow.boltzbit.com/" target="_blank">
          Start for free
        </TopBarLink>
      </TopBar>

      <Content
        style={{
          opacity: msSincePageLoad >= 1400 ? 1 : 0,
          fill: "white",
        }}
      >
        {/* <BgLogo /> */}
        {/* <Gap /> */}
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <ButtonsContainer>
          {/* <ContactUsModal triggerComponent={<ButtonDark>{buttons?.[0].text}</ButtonDark>} /> */}

          <ButtonDark onClick={() => window.scrollTo({ top: window.innerHeight * 2, behavior: "smooth" })}>
            {buttons?.[1].text}
          </ButtonDark>
        </ButtonsContainer>
        <StyledChevronDown
          onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
          style={{ height: "40px", stroke: "white", strokeWidth: "2px" }}
        />
      </Content>
    </BackgroundContainer>
  );
};

export default HeroSection;
