import Footer from "components/Footer";
import VideoSectionMobile from "components/VideoSectionMobile";
import AnimatedContentMobile from "components/AnimatedContentMobile";

const SectionsViewerMobile = ({ sections }) => {
  return (
    <>
      {sections.map((section, i) => {
        if (section.type === "video") {
          return (
            <VideoSectionMobile
              key={i}
              messageLine1={section?.messageLine1}
              messageLine2={section?.messageLine2}
              messageLine2Blue={section?.messageLine2Blue}
              typewriterMessages={section?.typewriterMessages}
              logo={section?.logo}
              videoSrc={section?.videoSrc}
            />
          );
        }
        if (section.type === "animated-content") {
          return (
            <AnimatedContentMobile
              key={i}
              messageLine1={section?.messageLine1}
              messageLine2={section?.messageLine2}
              description={section?.description}
            />
          );
        }
        // return <div key={i}>{JSON.stringify(section)}</div>;
        return null;
      })}
      <Footer />
    </>
  );
};

export default SectionsViewerMobile;
