import ButtonV1 from "components/ButtonV1";
import { Gap } from "components/Layout";
import { COLOR1, COLOR2 } from "pages/main-v2";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  margin: 0 auto;
  padding: 100px 0;
  z-index: 1;
  position: relative;
  color: black;

  display: none;
  @media (min-width: 1240px) {
    display: grid;
  }
  width: 1200px;
`;

const Column = styled.div`
  display: grid;
  gap: 20px;
  border-radius: 10px;
  align-content: start;
  padding: 40px 100px;
  grid-template-columns: 1fr 1fr;
`;

const ColumnTitle = styled.div`
  font-family: Montserrat;

  font-family: Montserrat;
  font-size: 28px;
  font-weight: 600;
  text-align: left;
  line-height: 1.2;

  background: linear-gradient(88.57deg, ${COLOR2} 17.21%, ${COLOR1} 62.45%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const ColumnText = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
  line-height: 1.4;
`;

const StyledButtonV1 = styled(ButtonV1)`
  margin-top: 40px;
  padding: 10px 40px;
  height: auto;
  justify-self: center;
  font-size: 20px;
`;

const HorizontalLine = styled.div`
  height: 1px;
  background: linear-gradient(90deg, rgba(164, 164, 164, 0) 0%, #a4a4a4 50.5%, rgba(164, 164, 164, 0) 100%);
  width: 100%;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 51.41px;
  font-weight: 600;
  line-height: 56px;
  /* width: 500px; */
  color: #747474;
  padding-top: 40px;
  padding-bottom: 20px;
  text-align: center;

  background: linear-gradient(132.27deg, #424242 48.26%, #7a7979 64.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const Text = styled.div`
  text-align: center;
  width: 600px;
  justify-self: center;
  line-height: 1.2;
`;

const MessageListSection = ({ title, text, columns }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Text
        dangerouslySetInnerHTML={{
          __html: text,
        }}
      />
      <Gap height="40px" />
      {columns.map((column, index) => (
        <>
          <HorizontalLine />
          <Column key={index} style={index === 0 ? { gridRow: "span 2", alignContent: "center" } : {}}>
            <ColumnTitle dangerouslySetInnerHTML={{ __html: column.title }} />
            <ColumnText dangerouslySetInnerHTML={{ __html: column.text }} />
            {/* {index === 0 && !isButtonHidden && (
                <StyledButtonV1 onClick={() => navigate("/solutions")}>Learn more about security</StyledButtonV1>
              )} */}
          </Column>
        </>
      ))}
      <HorizontalLine />
    </Container>
  );
};

export default MessageListSection;
