import ButtonDark from "components/ButtonV1";
import ContactUsModal from "components/ContactUsModal";
import { LogoBig } from "components/ui/Icons";
import { CENTER_COLUMN_WIDTH } from "pages/main-v2";
import { Link, useSearchParams } from "react-router-dom";
import styled from "styled-components";

const TopBarAnchor = styled.a`
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
`;

const TopBarLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
`;

const Container = styled.div`
  display: grid;
  padding: 14px 0;
  transition: opacity 1s;

  background-color: rgba(0, 0, 0, 0.2);
  ${props => props.colorScheme === "light" && `background-color: rgba(255, 255, 255, 0.5);`}
  width: 100vw;
  backdrop-filter: blur(10px);

  ${TopBarLink},a {
    color: ${props => (props.colorScheme === "light" ? "black" : "white")};
    :hover {
      color: lightgrey;
    }
  }
`;

const Content = styled.div`
  margin: 0 auto;
  width: ${CENTER_COLUMN_WIDTH}px;

  display: grid;
  grid-template-columns: 1fr repeat(6, auto);
  gap: 20px;
  justify-content: space-between;
  align-items: center;
`;

const LogoImg = styled.img`
  height: 25px;
`;

const Mobile = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  display: block;
  @media (min-width: 1240px) {
    display: none;
  }
`;

const Desktop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const TopBarV2 = ({ colorScheme = "dark", backgroundColor = "" }) => {
  const [searchParams] = useSearchParams();

  const isShowingBlogsLink = searchParams?.get("showButton") === "true" || searchParams?.get("token") === "bzabc";

  return (
    <>
      <Mobile>
        <Container
          colorScheme={colorScheme}
          style={{
            gridTemplateColumns: "1fr auto auto auto auto",
            alignItems: "center",
            gap: "10px",
            padding: "20px",
            backgroundColor,
          }}
        >
          <a href="/" style={{ width: "max-content" }}>
            {colorScheme === "dark" ? (
              <LogoImg src="/images/logo-white.png" />
            ) : (
              <LogoImg src="/images/logo-black.png" />
            )}
          </a>
          <TopBarLink to="/about-us">About us</TopBarLink>
          <TopBarLink to="/research">Research</TopBarLink>
          <TopBarLink to="/api">API</TopBarLink>
          {/* <ContactUsModal
            fieldNames={["name", "role", "email", "message"]}
            isShowingCalendarLink
            triggerComponent={
              <ButtonDark
                variant="outlined"
                style={{ width: "130px", height: "32px", minWidth: "130px", fontSize: "14px" }}
              >
                Talk to us
              </ButtonDark>
            }
          /> */}
        </Container>
      </Mobile>

      <Desktop>
        <Container colorScheme={colorScheme} style={{ backgroundColor }}>
          <Content>
            <a href="/" style={{ width: "max-content" }}>
              {colorScheme === "dark" ? (
                <LogoImg src="/images/boltzbit_logo_h_white.png" />
              ) : (
                <LogoImg src="/images/boltzbit-logo-horizontal-black.png" />
              )}
            </a>
            <TopBarLink to="/about-us">About us</TopBarLink>
            <TopBarLink to="/research">Research</TopBarLink>
            {isShowingBlogsLink && <TopBarLink to="/blogs?token=bzabc">Blog</TopBarLink>}
            <TopBarLink to="/api">API</TopBarLink>
            <TopBarLink to="/careers">Careers</TopBarLink>
            {/* <TopBarLink to="/news">News</TopBarLink> */}
            {/* <TopBarLink to="/solutions">Solutions</TopBarLink> */}
            {/* <TopBarAnchor href="https://flow.boltzbit.com/login" target="_blank">
              Log in
            </TopBarAnchor> */}
            <ContactUsModal
              fieldNames={["name", "role", "email", "message"]}
              isShowingCalendarLink
              triggerComponent={
                <ButtonDark
                  variant="outlined"
                  style={{ width: "130px", height: "32px", minWidth: "130px", fontSize: "14px" }}
                >
                  Talk to us
                </ButtonDark>
              }
            />
          </Content>
        </Container>
      </Desktop>
    </>
  );
};

export default TopBarV2;
