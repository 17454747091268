import { Editor } from "@monaco-editor/react";
import { CENTER_COLUMN_WIDTH, COLOR2 } from "pages/main-v2";
import { useState } from "react";
import styled from "styled-components";

const BORDER_STYLE_WHITE = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%) padding-box,
    linear-gradient(to bottom, #d1d1d1, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const StyledEditor = styled(Editor)`
  height: 300px;
  * {
    font-size: 12px;
  }
`;

const StyledEditorHtml = styled.div`
  white-space: pre-wrap;
  overflow: auto;
  font-family: monospace;
  font-size: 12px;
  padding: 0 20px;
  color: #424242;
  line-height: 1.5;
`;

const Container = styled.div`
  width: ${CENTER_COLUMN_WIDTH}px;
  margin: 0 auto;
  margin-bottom: 50px;
`;

const Title = styled.div`
  font-size: 24px;
  font-weight: 600;
`;

const Description = styled.div`
  padding: 20px 0;
`;

const Request = styled.div``;

const Tabs = styled.div`
  display: flex;
  border-bottom: 1px solid #bebebe;
`;

const Tab = styled.div`
  cursor: pointer;
  padding: 10px 0;
  border-bottom: 1px solid ${props => (props.isSelected ? COLOR2 : "transparent")};
  margin-right: 40px;
  font-size: 14px;
  font-weight: 600;
`;

const Panels = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
`;

const Panel = styled.div`
  padding: 0px;
  padding-top: 20px;
  ${BORDER_STYLE_WHITE};
`;

const SmallTitle = styled.div`
  font-weight: 600;
  font-size: 14px;
  padding: 10px 0;
  padding-top: 20px;
`;

const EDITOR_OPTIONS = {
  readOnly: true,
  lineNumbers: "off",
  minimap: { enabled: false },
  scrollbar: { vertical: "hidden", horizontal: "hidden" },
  // disable scrolling
  scrollBeyondLastLine: false,
  // disable indent guides
  renderIndentGuides: false,
};

const RequestGroup = ({ title = "", description = "", requests = [], editorHeight = 300 }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const { input, output } = requests?.[selectedIndex] || {};

  return (
    <Container>
      <Title>{title}</Title>
      <Description>{description}</Description>
      <Tabs>
        {requests?.map((request, index) => (
          <Tab key={index} isSelected={selectedIndex === index} onClick={() => setSelectedIndex(index)}>
            {request.label}
          </Tab>
        ))}
      </Tabs>
      <Panels>
        <div>
          <SmallTitle>Input</SmallTitle>
          <Panel>
            {/* <StyledEditor options={EDITOR_OPTIONS} language="json" value={JSON.stringify(input, null, 2)} /> */}
            <StyledEditorHtml style={{ height: `${editorHeight}px` }}>
              {JSON.stringify(input, null, 2)}
            </StyledEditorHtml>
          </Panel>
        </div>
        <div>
          <SmallTitle>Output</SmallTitle>
          <Panel>
            {/* <StyledEditor options={EDITOR_OPTIONS} language="json" value={JSON.stringify(output, null, 2)} /> */}
            <StyledEditorHtml style={{ height: `${editorHeight}px` }}>
              {JSON.stringify(output, null, 2)}
            </StyledEditorHtml>
          </Panel>
        </div>
      </Panels>
    </Container>
  );
};

export default RequestGroup;
