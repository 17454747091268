import styled from "styled-components";

const Container = styled.div`
  background-color: #ffffff;
  padding: 20px 40px;
  border-radius: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;

  display: grid;
  grid-auto-flow: column;
  gap: 120px;
`;

const Stat = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  gap: 40px;
`;

const Title = styled.div`
  font-size: 100px;
  font-weight: 500;
  text-align: left;
`;

const SubTitle = styled.div`
  font-size: 50px;
  font-weight: 500;
`;

const Titles = styled.div`
  display: grid;
  gap: 10px;
  justify-content: start;
`;

const Text = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  line-height: 1.2;
  font-weight: 500;
  text-align: left;

  display: grid;
  align-content: center;
`;

const StatsSection = ({ stats = [] }) => {
  return (
    <Container>
      {stats?.map(stat => (
        <Stat>
          <Titles>
            <Title>{stat?.title}</Title>
            <SubTitle>{stat?.subTitle}</SubTitle>
          </Titles>
          <Text>{stat?.text}</Text>
        </Stat>
      ))}
    </Container>
  );
};

export default StatsSection;
