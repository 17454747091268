import { Gap } from "components/Layout";
import SpeedAnimation from "components/SpeedAnimation";
import TokensAnimation from "components/TokensAnimation";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  background: radial-gradient(80% 30% at 50% -20%, rgba(120, 119, 198, 0.3) 0%, rgba(255, 255, 255, 0) 100%);
  padding: 80px 0;

  display: none;
  @media (min-width: 1240px) {
    display: grid;
  }
`;

const InnerColumn = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 40px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 80px;
  line-height: 1.2;
  font-weight: 500;
`;

const SubTitle = styled.div`
  font-size: 32px;
  font-weight: 400;
  color: #747474;
  margin-top: 40px;
`;

const Text = styled.div`
  font-size: 20px;
  color: #747474;
  line-height: 1.2;
`;

const MessageContainer = styled.div`
  height: 100vh;
  display: grid;
  align-content: center;
`;

const ResearchIllustrationSection = ({ title, subTitle, text }) => {
  return (
    <Container>
      <InnerColumn>
        <MessageContainer>
          <Title
            dangerouslySetInnerHTML={{
              __html: title,
            }}
          />

          <SubTitle>{subTitle}</SubTitle>
        </MessageContainer>
        <SpeedAnimation />
        <Gap height="100px" />
        <TokensAnimation />
        {/* <Text
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        /> */}
      </InnerColumn>
    </Container>
  );
};

export default ResearchIllustrationSection;
