import { postContactUsDetails } from "api/services/marketingService";
import ButtonDark from "components/ButtonDark";
import ButtonV1 from "components/ButtonV1";
import { BoltzhubLogoInner } from "components/ui/Icons";
import { COLOR2 } from "pages/main-v2";
import { Fragment } from "react";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  align-items: center;
  height: 100vh;
  background-color: black;
  padding: 50px;

  @media (min-width: 1240px) {
    grid-template-columns: 1fr 2fr;
  }
`;

const FormContainer = styled.div`
  color: white;
  padding: 10px;
  font-size: 16px;
  display: grid;
  gap: 0px;
  padding: 28px;
  border-radius: 20px;
  background-color: #232627;
  height: 100%;

  display: grid;
  align-content: center;
  justify-content: center;
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
`;

const StyledBoltzbitLogo = styled(BoltzhubLogoInner)`
  fill: white;
  height: 40px;
  fill: url(#SvgGradientBlueToGreen);
  align-self: start;
  position: absolute;
  transform: translateX(-115%);
`;

const Input = styled.input`
  width: 100%;

  @media (min-width: 1240px) {
    width: 500px;
  }

  color: white;
  font-family: "Montserrat", sans-serif;
  padding: 4px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid transparent;
  background-color: #141718;
  margin-bottom: 16px;
  :focus {
    border: 1px solid ${COLOR2};
  }
  font-size: 14px;
  font-weight: 500;

  height: 52px;
  padding: 14px 16px 14px 16px;
  gap: 12px;
  border-radius: 12px;

  ::placeholder {
    color: #80878baa;
  }
`;

const InputTitle = styled.div`
  font-size: 16px;
`;

const TextArea = styled.textarea`
  font-family: "Montserrat", sans-serif;
  padding: 4px 8px;
  border-radius: 4px;
  outline: none;
  border: 1px solid #eaeaea;
  background-color: transparent;
  margin-bottom: 20px;
  :focus {
    border: 1px solid ${COLOR2};
  }
  font-size: 16px;

  height: 80px;
  resize: none;
`;

const ErrorMsg = styled.div`
  color: #ff000094;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: start;
`;

const SuccessMsg = styled.div`
  min-height: 48px;
  margin-top: 20px;
  font-size: 14px;
  line-height: 1.5;
  text-align: center;
`;

const SmallText = styled.div`
  font-size: 14px;
  margin-top: 20px;
  line-height: 1.5;
  * {
    color: white;
    font-size: 14px;
  }
`;

const LeftContainer = styled.div`
  display: none;
  padding-right: 50px;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const LeftTitle = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 42px;
  line-height: 56px;
  letter-spacing: -0.02em;
  margin-bottom: 10px;

  background: linear-gradient(88.86deg, #ffffff 60.62%, #6f6f6f 105.12%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const LeftSubTitle = styled.div`
  font-size: 24px;
  color: #e8ecef80;
  line-height: 1.4;
  margin-bottom: 40px;
`;

const Img = styled.img`
  width: 100%;
`;

const FIELDS = [
  {
    name: "name",
    label: "Your name",
    placeholder: "Ada Lovelace",
  },
  {
    name: "email",
    label: "Your email *",
    placeholder: "ada@mail.com",
  },
  {
    name: "role",
    label: "Job Role",
    placeholder: "Hedge Fund Manager",
  },
  {
    name: "company",
    label: "Your Company",
    placeholder: "Boltzbit",
  },
  {
    name: "message",
    label: "Your message",
    type: "textarea",
    placeholder: "Your message here",
  },
];

const FIELD_NAMES = ["name", "email", "role", "company"];

const SUCCESS_MSG = "Thank you. Please check your email for a confirmation link.";
const EXISTS_MSG = "You are already on the waitlist. Please check your email for a confirmation link.";

const SignupPage = () => {
  const [errorMsg, setErrorMsg] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [payload, setPayload] = useState({
    name: "",
    email: "",
    message: "",
    role: "",
  });
  const [hasBeenSent, setHasBeenSent] = useState(false);
  const [successMsg, setSuccessMsg] = useState(SUCCESS_MSG);

  const doSend = async () => {
    setSuccessMsg(SUCCESS_MSG);
    setIsSending(true);
    setErrorMsg("");
    const { name, email } = payload;
    const { error } = await postContactUsDetails({
      firstName: name?.split(" ")?.[0] || "",
      lastName: name?.split(" ")?.[1] || "",
      email,
      message: payload?.message,
      company: "",
      role: payload?.role,
      industry: "",
      phone: "",
      country: "",
      product: "signup",
    });
    if (error) {
      if (error?.message === "Email has already been used to sign up.") {
        setSuccessMsg(EXISTS_MSG);
        setIsSending(false);
        setHasBeenSent(true);
        return;
      }

      setErrorMsg(error?.message || "There was an error. Please try again.");
      setIsSending(false);
      return;
    }

    setIsSending(false);
    setHasBeenSent(true);
  };

  return (
    <Container>
      <LeftContainer>
        <LeftTitle>Unlock the power of Boltzbit AI</LeftTitle>
        <LeftSubTitle>Build your own GenAI to unify data, discover insights and transform your operation.</LeftSubTitle>
        <Img src="/images/signup-img.png" />
      </LeftContainer>
      <FormContainer>
        <Title style={{ justifySelf: "center", marginBottom: "20px" }}>
          <StyledBoltzbitLogo />
          Boltzbit
        </Title>
        {FIELDS?.filter(field => FIELD_NAMES?.includes(field?.name)).map(field => (
          <Fragment key={field?.name}>
            {field.type === "textarea" ? (
              <TextArea
                value={payload[field.name]}
                onChange={e => setPayload({ ...payload, [field.name]: e.target.value })}
                placeholder={field.label}
              />
            ) : (
              <Input
                value={payload[field.name]}
                onChange={e => setPayload({ ...payload, [field.name]: e.target.value })}
                placeholder={field.label}
              />
            )}
          </Fragment>
        ))}

        <ErrorMsg>{errorMsg}</ErrorMsg>
        {hasBeenSent ? (
          <SuccessMsg
            style={{
              justifySelf: "center",
            }}
          >
            {successMsg}
          </SuccessMsg>
        ) : (
          <ButtonV1
            isPrimary
            isDisabled={isSending || !payload?.email}
            onClick={doSend}
            style={{
              width: "100%",
              maxWidth: "none",
              height: "40px",
              fontSize: "16px",
              justifySelf: "center",
              marginTop: "0px",
            }}
          >
            Join Waitlist
          </ButtonV1>
        )}
        <SmallText>
          By joining our waitlist, you accept our{" "}
          <a href="/terms" target="_blank" rel="noreferrer">
            Terms and Conditions
          </a>
          .
        </SmallText>
      </FormContainer>
    </Container>
  );
};

export default SignupPage;
