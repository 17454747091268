import ContactUsModal from "components/ContactUsModal";
import TopBar from "components/TopBar";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";

import ButtonV1 from "components/ButtonV1";

const Container = styled.div`
  margin: 0 auto;
  padding: 0 20px;
  padding-top: calc(100px + 60px);
  padding-bottom: 60px;

  @media (min-width: 1240px) {
    width: 1200px;
    padding: 0;
    padding-top: calc(100px + 60px);
    padding-bottom: 60px;
  }
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 60px;
  line-height: 1.1;

  background: linear-gradient(92.77deg, #2e2d2d 17.72%, #70747b 66.83%, #787368 77.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  @media (min-width: 1240px) {
    font-size: 90px;
  }
`;

const SubTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 29px;
  /* or 82% */
  display: flex;
  align-items: center;
  padding-top: 40px;

  color: #747474;

  @media (min-width: 1240px) {
    font-size: 32px;
    line-height: 1.1;
  }
`;

const JoinBtn = styled(ButtonV1)`
  margin-top: 40px;
`;

const Blocks = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: 300px 300px;
  gap: 20px;
  margin-top: 40px;
`;

const Block = styled.a`
  color: white;
  padding: 40px;
  display: grid;
  align-content: center;
  border-radius: 10px;
  text-decoration: none;
  :hover {
    text-decoration: underline;
  }
`;

const BlockTitle = styled.div`
  font-family: Montserrat;
  font-size: 36px;
  font-weight: 300;
  text-align: left;
  line-height: 1.2;
`;

const BlockSubTitle = styled.div`
  margin-top: 8px;
`;

const ColoredBlocksSection = ({ title = "", subTitle = "", blocks = [] }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <Container>
      <TopBar colorScheme="light" />
      <Title>{title}</Title>
      <SubTitle>{subTitle}</SubTitle>
      <Blocks>
        {blocks.map((block, index) => (
          <Block
            href={block?.url}
            target="_blank"
            rel="noreferrer"
            key={index}
            style={{ backgroundColor: block?.color, gridRow: index === 0 ? "span 2" : "auto" }}
          >
            <BlockTitle>{block.title}</BlockTitle>
            <BlockSubTitle>{block.subTitle}</BlockSubTitle>
          </Block>
        ))}
      </Blocks>
      <SubTitle>More stories coming soon</SubTitle>
    </Container>
  );
};

export default ColoredBlocksSection;
