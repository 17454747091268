import styled from "styled-components";

import { ArrowLeftIcon } from "components/ui/Icons";
import { SubHeading } from "components/Layout";
import { useState } from "react";

const minmax = (min, max, x) => Math.min(Math.max(x, min), max);

const isSlideVisible = (slideIndex, progressPercentage, numSlides) => {
  const percentagePerSlide = Math.ceil(100 / numSlides);
  const visibleSlideIndex = Math.floor(progressPercentage / percentagePerSlide) - 1;

  return visibleSlideIndex === slideIndex;
};

const Container = styled.div`
  padding-top: 100px;
  padding-bottom: 120px;
  text-align: center;

  display: grid;
  grid-template-areas:
    "heading"
    "text"
    "name"
    "company-and-name"
    "arrows";
`;

const WhatClientsSayHeading = styled(SubHeading)`
  grid-area: heading;
  font-size: 48px;
  font-weight: bold;
  padding-bottom: 60px;
`;

const SlideText = styled.div`
  grid-area: text;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  font-weight: 600;
  font-size: 36px;
  line-height: 46px;
  padding-bottom: 20px;

  @media (min-width: 768px) {
    font-size: 46px;
    line-height: 56px;
  }
`;

const CompanyAndName = styled.div`
  padding-top: 40px;
  padding-bottom: 10px;

  text-align: left;
  display: grid;
  width: max-content;
  justify-self: center;
  align-content: start;
  grid-area: company-and-name;
  column-gap: 20px;
  grid-template-areas:
    "logo name"
    "logo company";
`;

const Name = styled.div`
  grid-area: name;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  font-weight: 700;
  font-size: 20px;
`;

const Company = styled.div`
  grid-area: company;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
`;

const Arrows = styled.div`
  grid-area: arrows;
  display: flex;
  justify-content: center;
  padding-top: 40px;
`;

const ArrowLeft = styled(ArrowLeftIcon)`
  cursor: pointer;
  padding: 0 10px;
  transition: fill 0.2s;

  :hover {
    fill: ${props => props.theme.color.primary};
  }
`;

const Logo = styled.img`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  grid-area: logo;
  height: 32px;
  align-self: center;
`;

const ArrowRight = styled(ArrowLeft)`
  transform: rotate(180deg);
`;

const TestimonialsSimple = ({ heading, slides }) => {
  const progressPerSlide = Math.ceil(100 / slides?.length);
  const [progressPercentage, setProgressPercentage] = useState(progressPerSlide);

  return (
    <Container>
      <WhatClientsSayHeading>{heading}</WhatClientsSayHeading>
      {slides.map((slide, i) => (
        <SlideText isVisible={isSlideVisible(i, progressPercentage, slides?.length)} key={i}>
          {slide.text}
        </SlideText>
      ))}
      <CompanyAndName>
        {slides.map((slide, i) => (
          <Logo isVisible={isSlideVisible(i, progressPercentage, slides?.length)} key={i} src={slide.logo} />
        ))}
        {slides.map((slide, i) => (
          <Name isVisible={isSlideVisible(i, progressPercentage, slides?.length)} key={i}>
            {slide.name}
          </Name>
        ))}
        {slides.map((slide, i) => (
          <Company isVisible={isSlideVisible(i, progressPercentage, slides?.length)} key={i}>
            {slide.company}
          </Company>
        ))}
      </CompanyAndName>
      <Arrows>
        <ArrowLeft
          onClick={() =>
            setProgressPercentage(prevProgress => minmax(progressPerSlide, 100, prevProgress - progressPerSlide))
          }
        />
        <ArrowRight
          onClick={() =>
            setProgressPercentage(prevProgress => minmax(progressPerSlide, 100, prevProgress + progressPerSlide))
          }
        />
      </Arrows>
    </Container>
  );
};

export default TestimonialsSimple;
