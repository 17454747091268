import styled from "styled-components";
import { useEffect } from "react";
import Typewriter from "typewriter-effect/dist/core";

import Button from "components/Button";
import { Link, useNavigate } from "react-router-dom";
import { BoltzflowLogo, BoltzhubLogo } from "components/ui/Icons";

const TopContainer = styled.div`
  position: absolute;
  top: 0;

  width: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(5, auto);
  gap: 22px;
  padding: 20px;
  align-items: center;
  z-index: 1;
`;

const Video = styled.video`
  height: 100vh;
  object-fit: cover;
  object-position: center;
`;

const Container = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;
`;

const VideoOverlay = styled.div`
  position: absolute;
  top: 0;
  background: linear-gradient(30deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.2));
  width: 100%;
  height: 100%;
`;

const Title = styled.div`
  font-size: 26px;
  font-weight: 600;
`;

const FlexLink = styled(Link)`
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: ${props => props.theme.color.furthest};
`;

const WhiteButton = styled(Button)`
  padding: 10px 32px;
  background: ${props => props.theme.color.furthest};
  :hover {
    opacity: 0.8;
  }
`;

const NavLink = styled(Link)`
  text-decoration: none;
  color: ${props => props.theme.color.furthest};
  :hover {
    opacity: 0.8;
  }
`;

const MessagesAndButtons = styled.div`
  position: absolute;
  padding: 20px;
  bottom: 40px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  align-items: start;
`;

const Message = styled.div`
  font-weight: 500;
  color: ${props => props.theme.color.furthest};
  font-size: 34px;
`;

const BlueMessage = styled(Message)`
  color: ${props => props.theme.color.primary};
`;

const AnimatedSpan = styled.span`
  display: inline-block;
  color: ${props => props.theme.color.furthest};
  width: 160px;
  * {
    font-size: 34px;
  }
`;

const LOGOS = {
  flow: <BoltzflowLogo style={{ fill: "white", height: "37px" }} />,
  hub: <BoltzhubLogo style={{ fill: "white", height: "37px" }} />,
};

const VideoSectionMobile = ({
  messageLine1,
  messageLine2,
  messageLine2Blue,
  typewriterMessages = [],
  logo = "flow",
  videoSrc,
}) => {
  const navigate = useNavigate();

  useEffect(() => {
    if (!typewriterMessages?.length) {
      return;
    }

    new Typewriter("#typewriter", {
      strings: typewriterMessages,
      autoStart: true,
      loop: true,
    });
  }, [typewriterMessages?.length]);

  return (
    <Container>
      <TopContainer>
        <FlexLink to="/">
          {LOGOS[logo]}
          <Title>{logo === "flow" ? "Boltzflow" : "Boltzhub"}</Title>
        </FlexLink>
      </TopContainer>

      <VideoOverlay />
      <Video loop autoPlay muted>
        {/* <source
          src="https://player.vimeo.com/progressive_redirect/playback/859473284/rendition/1080p/file.mp4?loc=external&log_user=0&signature=6f684fd3c96cdcf2ff568fc2e7490e54b18dbfa827135fd36c4d839d4789bef2"
          type="video/mp4"
        ></source> */}
        {/* <source
          src="https://player.vimeo.com/progressive_redirect/playback/643652799/rendition/1080p/file.mp4?loc=external&signature=69f782f53bbe4eaafeade5fe72cd501f363b72f7471f2a4eb0871d0271461a5a"
          type="video/mp4"
        ></source> */}
        <source src={videoSrc} type="video/mp4"></source>
      </Video>

      <MessagesAndButtons>
        <Message>{messageLine1}</Message>
        <Message>
          {messageLine2}
          <AnimatedSpan id="typewriter"></AnimatedSpan>
        </Message>
        <BlueMessage>{messageLine2Blue}</BlueMessage>
        <WhiteButton
          style={{ marginTop: "40px" }}
          value="Join Waitlist"
          onClick={() => {
            navigate("contact");
          }}
        />
      </MessagesAndButtons>
    </Container>
  );
};

export default VideoSectionMobile;
