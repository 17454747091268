import styled from "styled-components";

const Container = styled.div`
  display: none;
  height: 600px;
  align-content: center;
  @media (min-width: 1240px) {
    display: grid;
  }
`;

const Img = styled.img`
  width: 1140px;
  justify-self: center;
`;

const OneIllustrationSection = ({ style = {} }) => {
  return (
    <Container style={style}>
      <Img src="/images/expanding-wave.png" />
    </Container>
  );
};

export default OneIllustrationSection;
