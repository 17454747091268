import BenchmarksChart from "components/BenchmarksChart";
import styled from "styled-components";

const Container = styled.div`
  background: linear-gradient(180deg, #ffffff 0%, #f8f8f8 100%);
  padding: 40px;
  padding-top: 80px;
  border-radius: 20px;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 40px;
  margin-top: 120px;

  display: grid;
  gap: 20px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 50px;
  font-weight: 500;
  line-height: 56px;
  color: #70747b;
`;

const Text = styled.div`
  color: #747474;
  line-height: 1.2;
  width: 1000px;
`;

const Image = styled.img`
  height: 300px;
  width: 600px;
  object-fit: cover;
  object-position: -50px -140px;
`;

const Description = styled.div`
  font-size: 16px;
  line-height: 1.2;
  align-self: end;
  font-weight: 500;
  text-align: right;
  color: #747474;
`;

const TwoColumn = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
`;

const IllustrationTwoColumnSection = ({ title = "", text = "", imageSrc = "", description = "" }) => {
  return (
    <Container>
      <Title dangerouslySetInnerHTML={{ __html: title }} />
      <Text dangerouslySetInnerHTML={{ __html: text }} />
      <TwoColumn>
        <BenchmarksChart />
        <Description dangerouslySetInnerHTML={{ __html: description }} />
      </TwoColumn>
    </Container>
  );
};

export default IllustrationTwoColumnSection;
