import styled from "styled-components";

export const Gap = styled.div`
  height: ${props => props.height || "20px"};
`;

export const Heading = styled.div`
  font-size: 50px;
  text-align: ${props => props.textAlignment};
  font-weight: 700;

  @media (min-width: 768px) {
    font-size: 70px;
  }
`;

export const SubHeading = styled.div`
  text-align: ${props => props.textAlignment};
  font-size: 28px;
  font-weight: 600;
`;
