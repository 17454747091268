import ButtonDark from "components/ButtonV1";
import { LogoBig } from "components/ui/Icons";
import { Link } from "react-router-dom";
import styled from "styled-components";

const TopBarAnchor = styled.a`
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
`;

const TopBarLink = styled(Link)`
  font-size: 14px;
  text-decoration: none;
  font-weight: 600;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
`;

const Container = styled.div`
  padding: 14px 20px;
  transition: opacity 1s;

  background-color: rgba(0, 0, 0, 0.5);
  ${props => props.colorScheme === "light" && `background-color: rgba(255, 255, 255, 0.5);`}
  width: 100vw;
  backdrop-filter: blur(10px);

  display: grid;
  grid-template-columns: 1fr repeat(6, auto);
  gap: 20px;
  justify-content: space-between;
  align-items: center;

  ${TopBarLink},a {
    color: ${props => (props.colorScheme === "light" ? "black" : "white")};
    :hover {
      color: lightgrey;
    }
  }
`;

const LogoImg = styled.img`
  height: 25px;
`;

const Mobile = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  display: block;
  @media (min-width: 1240px) {
    display: none;
  }
`;

const Desktop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 2;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const TopBar = ({ colorScheme = "dark" }) => {
  const logo = <LogoBig />;

  return (
    <>
      <Mobile>
        <Container colorScheme={colorScheme} style={{ gridTemplateColumns: "1fr auto auto auto auto" }}>
          <a href="/" style={{ width: "max-content" }}>
            {colorScheme === "dark" ? (
              <LogoImg src="/images/logo-white.png" />
            ) : (
              <LogoImg src="/images/logo-black.png" />
            )}
          </a>
          <TopBarLink to="/about-us">About us</TopBarLink>
          <TopBarLink to="/research">Research</TopBarLink>
          <TopBarLink to="/api">API</TopBarLink>
        </Container>
      </Mobile>

      <Desktop>
        <Container colorScheme={colorScheme}>
          <a href="/" style={{ width: "max-content" }}>
            {colorScheme === "dark" ? (
              <LogoImg src="/images/boltzbit_logo_h_white.png" />
            ) : (
              <LogoImg src="/images/boltzbit-logo-horizontal-black.png" />
            )}
          </a>
          <TopBarLink to="/about-us">About us</TopBarLink>
          {/* <TopBarLink to="/news">News</TopBarLink> */}
          <TopBarLink to="/research">Research</TopBarLink>
          <TopBarLink to="/api">API</TopBarLink>
          <TopBarLink to="/careers">Careers</TopBarLink>
          {/* <TopBarLink to="/news">News</TopBarLink> */}
          {/* <TopBarLink to="/solutions">Solutions</TopBarLink> */}
          <TopBarAnchor
            href="https://flow.boltzbit.com/?isAnimating=true&sources=%5B%7B%22fileId%22%3A%226627f9c0f5fa74662b290da6%22%2C%22fileName%22%3A%22ICBC+2023+Q3+Report.pdf%22%2C%22type%22%3A%22FILE%22%2C%22numberOfPages%22%3A23%7D%5D"
            target="_blank"
          >
            Log in
          </TopBarAnchor>
        </Container>
      </Desktop>
    </>
  );
};

export default TopBar;
