import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "hero-v1",
    title: "Enterprise GenAI<br/>always a step ahead",
    subTitle: "transform business with secure customised GenAI",
    buttons: [{ text: "Talk to us" }, { text: "Try it yourself" }],
  },
  {
    type: "message-v1",
    leftTitle: "Groundbreaking research",
    text: "Our research in Boltzman machines creates the most powerful Large Language Models, <span style='color: #497FF3; font-size: inherit;'>surpassing all the GPTs to date.</span>",
    isLinkVisible: true,
  },
  {
    type: "journey",
    title: "Your <span style='color: #497FF3; font-size: inherit;'>Gen-AI solution</span> starts with a question",
    subTitle: "Everything you need, straight from your chatbox.",
    placeholder: "What can we do for you?",
    bottomButtons: [
      { text: "Check financial model" },
      { text: "Highlight risks" },
      { text: "Write draft email" },
      { text: "Build a database" },
    ],
    imagesSections: [
      {
        imageSrc: "/images/journey-images/aggregate.png",
        title: "Aggregate",
        text: "Pull your data from <span style='font-weight: 500; font-size: inherit;'>emails, websites, external feeds, PDFs, excels</span> and images.",
      },
      {
        imageSrc: "/images/journey-images/analyse.png",
        title: "Analyse",
        text: "Your private model understands your requests and retrieves your data.",
      },
      {
        imageSrc: "/images/journey-images/automate.png",
        title: "Automate",
        text: "Use your predictions, <span style='font-weight: 500; font-size: inherit;'>forecasts and insights</span> to grow revenue faster.",
      },
    ],
  },
  {
    type: "word-cloud",
    title: "<span style='color: #497FF3; font-size: inherit;'>Private models</span> fine-tuned<br/>for every business",
    cloudLines: [
      ["Legal Services", "Venture Capital", "Private Equity"],
      ["Insurance", "Equity Capital Market", "ESG"],
      ["Real Estate Asset Management", "Battery Infrastructure"],
      ["Equity Research", "Asset Management"],
      ["Marketing", "Debt Capital Management"],
    ],
    fontSizes: [
      [1, 0.7, 1.2],
      [2.7, 1, 2],
      [0.7, 1],
      [1.4, 2.6],
      [0.6, 1.1],
    ],
    alphas: [
      [0.5, 0.3, 0.7],
      [0.8, 0.5, 0.5],
      [0.5, 0.3, 0.5],
      [0.5, 0.5, 0.5],
      [0.5, 0.8, 0.8],
    ],
    subTitle: "",
  },
  {
    type: "three-cards",
    title: "We customise <br /><span style='color: #497FF3; font-size: inherit;'>GenAI for enterprise</span>",
    columns: [
      {
        title: "Keep your data in your organisation",
        text: "Our tailored Gen-AI models are deployed and managed by you for security. Host your solution on public, private cloud or on-prem for maximum control.",
      },
      {
        title: "Customise your<br/>AI for best results",
        text: "Every business is unique. By using your own data, you can boost the accuracy of your models with our user friendly fine-tuning.",
      },
      {
        title: "Lay a foundation<br/>you can build upon",
        text: "This is just the beginning. Build AI Engines to solve your problems, and automate your solutions.",
      },
    ],
  },
  {
    type: "banner-v1",
    heading: "Take the next step",
    text: "Talk to us",
  },
  {
    type: "footer-v1",
  },
];

const DesktopContainer = styled.div`
  background: linear-gradient(178.34deg, #161718 -2.94%, #14181f 90.88%, #000d78 621.32%);
`;

const Container = styled.div``;

const MainPage = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "0ced6987-57da-4c35-938d-418acc5d328a") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
    </Container>
  );
};

export default MainPage;
