import { getBlogs } from "api/backend/marketingServiceEndpoints";
import FooterSectionV2 from "components/FooterSectionV2";
import { Gap } from "components/Layout";
import TopBarV2 from "components/TopBarV2";
import { CENTER_COLUMN_WIDTH, COLOR1, COLOR2 } from "pages/main-v2";
import { useEffect } from "react";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { dateSafe, formatSafe } from "utils/common";

const Container = styled.div`
  padding-top: 100px;
  display: grid;
  justify-content: center;
  justify-items: center;
`;

const Cards = styled.div`
  display: flex;
  gap: 40px;
  width: ${CENTER_COLUMN_WIDTH}px;
  display: grid;
  grid-template-columns: 1fr 1fr;
`;

const Card = styled.a`
  display: grid;
  border: 1px solid #eaeaea;
  border-radius: 24px;
  text-decoration: none;
  align-content: start;
  grid-template-rows: auto auto auto 1fr;
  :hover {
    img {
      transform: scale(1.05);
    }
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 28px;
  text-align: left;
  padding: 8px 40px;
  padding-top: 40px;
  line-height: 1.2;

  background: linear-gradient(132.27deg, #424242 48.26%, #7a7979 64.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const SubTitle = styled.div`
  padding: 8px 40px;
  color: #424242;
`;

const DateText = styled.div`
  padding: 8px 40px;
  padding-bottom: 40px;
  color: #424242;
`;

const ImgContainer = styled.div`
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background-color: #eaeaea;
  height: 350px;
  width: 100%;
  overflow: hidden;
`;

const Img = styled.img`
  border-top-right-radius: 24px;
  border-top-left-radius: 24px;
  background-color: #eaeaea;
  height: 100%;
  width: 100%;
  object-fit: cover;
  transition: transform 0.2s;
`;

const PageTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 60px;
  line-height: 1.1;
  margin-top: 60px;

  background: linear-gradient(92.77deg, #2e2d2d 17.72%, #70747b 66.83%, #787368 77.13%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-align: center;

  @media (min-width: 1240px) {
    font-size: 60px;
  }

  .gradient-text {
    font-size: inherit;
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }
`;

const PageImg = styled.img`
  width: 1140px;
  justify-self: center;
`;

const BlogsPage = () => {
  const [searchParams] = useSearchParams();
  const [blogs, setBlogs] = useState([]);

  useEffect(() => {
    doPopulateBlogs();
  }, []);

  const doPopulateBlogs = async () => {
    const { data } = await getBlogs();

    const blogsMostRecentFirst = data?.sort((a, b) => {
      return dateSafe(b?.meta?.dateStr) - dateSafe(a?.meta?.dateStr);
    });

    setBlogs(blogsMostRecentFirst || []);
  };

  const token = searchParams.get("token");

  if (token !== "bzabc") {
    return null;
  }

  return (
    <Container>
      <TopBarV2 colorScheme="dark" backgroundColor="black" />
      <PageTitle>
        <span class="gradient-text">Latest News</span>
      </PageTitle>
      <PageImg style={{ marginLeft: "600px", marginTop: "-70px" }} src="/images/expanding-wave.png" />
      <Gap height="80px" />
      <Cards>
        {blogs?.map((blog, i) => {
          let thumbnailSrc = "/images/teams-research.jpeg";
          if (blog?.meta?.imgGoogleDriveId) {
            thumbnailSrc = `https://drive.google.com/thumbnail?id=${blog?.meta?.imgGoogleDriveId}&sz=w800`;
          }

          if (i === 0) {
            return (
              <Card
                style={{
                  gridColumn: "span 2",
                  gridTemplateColumns: "2fr 1fr",
                }}
                key={blog?.id}
                href={`/blogs/${blog?.id}?token=bzabc`}
              >
                <ImgContainer
                  style={{
                    borderTopRightRadius: 0,
                    borderBottomLeftRadius: 24,
                    height: 420,
                  }}
                >
                  <Img style={{ borderTopRightRadius: 0 }} src={thumbnailSrc} />
                </ImgContainer>
                <div
                  style={{
                    display: "grid",
                    gridTemplateRows: "auto auto 1fr",
                  }}
                >
                  <Title>{blog?.name}</Title>
                  <SubTitle>{blog?.subtitle}</SubTitle>
                  <DateText style={{ alignSelf: "end" }}>{formatSafe(blog?.meta?.dateStr, "d MMMM yyy")}</DateText>
                </div>
              </Card>
            );
          }

          return (
            <Card key={blog?.id} href={`/blogs/${blog?.id}?token=bzabc`}>
              <ImgContainer>
                <Img src={thumbnailSrc} />
              </ImgContainer>
              <Title>{blog?.name}</Title>
              <SubTitle>{blog?.subtitle}</SubTitle>
              <DateText style={{ alignSelf: "end" }}>{formatSafe(blog?.meta?.dateStr, "d MMMM yyy")}</DateText>
            </Card>
          );
        })}
      </Cards>
      <Gap height="40px" />
      <div style={{ width: "100vw" }}>
        <FooterSectionV2 />
      </div>
    </Container>
  );
};

export default BlogsPage;
