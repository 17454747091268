import { useState, useEffect } from "react";
import styled from "styled-components";

import { Gap } from "components/Layout";
import { BoltzbitLogo, BoltzbitLogoWithText, ChevronDown } from "components/ui/Icons";
import ButtonDark from "components/ButtonV1";
import TopBar from "components/TopBar";

const BackgroundContainer = styled.div``;

const Content = styled.div`
  padding: 0 20px;
  @media (min-width: 1240px) {
    padding: 0;
    display: grid;
    width: 1200px;
  }

  height: 100vh;
  align-content: center;
  gap: 0px;
  color: white;
  transition: opacity 1s;
  padding-top: 80px;
  margin: 0 auto;
`;

const Title = styled.div`
  font-size: 62px;
  line-height: 1.2;
  @media (min-width: 1240px) {
    font-size: 100px;
    line-height: 115px;
  }

  font-weight: 500;
  z-index: 1;

  background-image: linear-gradient(135deg, #ffffff 0%, #d9d9d9 40%, #787368 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;

  font-weight: 500;
`;

const SubTitle = styled.div`
  font-size: 32px;
  font-weight: 300;
  line-height: 1.4;
  color: #bdbdbd;
  z-index: 1;

  /* background-image: linear-gradient(0deg, #ffffff 0%, #d9d9d9 40%, #c4bdae 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent; */
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 40px;
  justify-content: center;
  z-index: 1;
`;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  bottom: 40px;
  justify-self: center;
  margin-top: 100px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`;

const StyledBoltzflowLogo = styled(BoltzbitLogoWithText)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform-origin: top left;
  transform: translate(-50%, -50%);
  height: 300px;
  opacity: 0;

  animation: fade 1.8s;
  @keyframes fade {
    0% {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1;
    }
    70% {
      transform: scale(1) translate(-50%, -50%);
      opacity: 1;
    }
    100% {
      transform: scale(1) translate(-50%, -50%);
      opacity: 0;
    }
  }
`;

const TopBarLogo = styled(BoltzbitLogoWithText)`
  cursor: pointer;
`;

const BgLogo = styled(BoltzbitLogo)`
  position: absolute;
  right: 40px;
  bottom: 20px;
  height: 600px;
  z-index: 0;
  opacity: 0.05;
`;

const Logos = styled.div`
  display: flex;
  gap: 50px;
  justify-content: center;
  align-items: center;
  padding-top: 40px;
`;

const LogoImg = styled.img`
  height: 40px;
  filter: grayscale(1);
`;

const StyledSvg = styled.svg`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const GridBackground = () => (
  <StyledSvg width="1016" height="495" viewBox="0 0 1016 495" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1014.39 47.7427L0 46.2947M1014.79 149.611L0.402723 148.163M1015.19 251.479L0.807999 250.031M1015.6 353.349L1.212 351.901M1016 455.218L1.61472 453.77M505.706 487.923L506.776 0M392.811 488.218L393.881 0.294455M279.918 488.513L280.988 0.590137M167.021 488.808L168.091 0.885207M54.126 489.103L55.196 1.17966M960.09 494.115L961.16 6.1916M847.197 494.41L848.267 6.48728M734.3 494.706L735.37 6.78235M621.405 495L622.475 7.07681"
      stroke="url(#paint0_linear_1829_107)"
    />
    <rect x="164" y="351" width="116" height="103" fill="#497FF3" fill-opacity="0.1" />
    <rect x="280" y="47" width="116" height="103" fill="#497FF3" fill-opacity="0.1" />
    <defs>
      <linearGradient
        id="paint0_linear_1829_107"
        x1="-57.5128"
        y1="102.021"
        x2="737.763"
        y2="874.234"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#171819" />
        <stop offset="0.548889" stop-color="#190B70" />
        <stop offset="1" stop-color="#497FF3" />
      </linearGradient>
    </defs>
  </StyledSvg>
);

const HeroSectionV1 = ({ title, subTitle, buttons = [], logoSources = [] }) => {
  const [msSincePageLoad, setMsSincePageLoad] = useState(2000);

  useEffect(() => {
    const timeoutId = setTimeout(() => {
      setMsSincePageLoad(msSincePageLoad + 100);
    }, 100);
    return () => clearInterval(timeoutId);
  }, [msSincePageLoad]);

  // if (msSincePageLoad < 1500) {
  //   return (
  //     <BackgroundContainer>
  //       <StyledBoltzflowLogo style={{ fill: "white" }} />
  //     </BackgroundContainer>
  //   );
  // }

  return (
    <BackgroundContainer>
      <TopBar />
      <GridBackground />

      <Content
        style={{
          opacity: msSincePageLoad >= 1400 ? 1 : 0,
          fill: "white",
        }}
      >
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Gap height="10px" />
        <SubTitle>{subTitle}</SubTitle>
        <Gap height="50px" />

        {/* <Logos>
          {logoSources?.map(src => {
            if (src?.includes("tpicap")) {
              return (
                <LogoImg
                  src={src}
                  style={{
                    height: "50px",
                  }}
                />
              );
            }

            if (src?.includes("accenture")) {
              return (
                <LogoImg
                  src={src}
                  style={{
                    opacity: 0.2,
                  }}
                />
              );
            }

            return <LogoImg src={src} />;
          })}
        </Logos> */}
        {/* <StyledChevronDown
          onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
          style={{ height: "40px", stroke: "white", strokeWidth: "2px" }}
        /> */}
      </Content>
    </BackgroundContainer>
  );
};

export default HeroSectionV1;
