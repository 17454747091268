import { get } from "lodash";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import styled from "styled-components";
import { deleteAllCookies, getCookieByName } from "utils/common";

const Container = styled.div`
  position: fixed;
  bottom: 20px;
  right: 20px;
  max-width: 420px;
  line-height: 1.5;
  border-radius: 4px;

  background-color: black;
  border: 1px solid #656565;
  padding: 12px;
  color: white;
  font-size: 12px;
  a {
    font-size: 12px;
    color: #497ff3;
  }
  z-index: 10000;
`;

const Button = styled.button`
  color-scheme: dark;
  font-family: "Montserrat", sans-serif;
`;

// cookies_policy = ACCEPTED, REJECTED

const CookieBanner = ({ onClickAcceptAll = () => {} }) => {
  const [isBannerVisible, setIsBannerVisible] = useState(false);
  const location = useLocation();

  useEffect(() => {
    if (location?.pathname === "/cookies") {
      return;
    }

    const cookiesPolicy = getCookieByName("cookies_policy");
    if (cookiesPolicy === "REJECTED" || cookiesPolicy === "ACCEPTED") {
      return;
    }

    setIsBannerVisible(true);
  }, [location?.pathname]);

  const doAcceptAll = () => {
    document.cookie = "cookies_policy=ACCEPTED; path=/; max-age=31536000;";
    setIsBannerVisible(false);
  };

  const doReject = () => {
    deleteAllCookies();
    document.cookie = "cookies_policy=REJECTED; path=/; max-age=31536000;";
    setIsBannerVisible(false);
  };

  if (!isBannerVisible) {
    return;
  }

  return (
    <Container>
      We use cookies to give you the best experience on our website.
      <br />
      <a href="/cookies" target="_blank">
        Cookie Policy
      </a>
      <div style={{ display: "flex", gap: "10px", marginTop: "10px" }}>
        <Button
          onClick={() => {
            doAcceptAll();
            onClickAcceptAll();
          }}
        >
          Accept all
        </Button>
        <Button onClick={doReject}>Reject</Button>
      </div>
    </Container>
  );
};

export default CookieBanner;
