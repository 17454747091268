import { COLOR1, COLOR2 } from "pages/main-v2";
import styled from "styled-components";

const ButtonV1 = styled.button`
  z-index: 1;
  min-width: 160px;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 38px;
  color: #fff;
  /* border: 1px solid #373737; */
  outline: none;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  font-family: "Montserrat", sans-serif;
  border: none;
  background-color: #000000;
  /* box-shadow: 0px 0px 20px 0px #1e1e1e; */
  border-radius: 10px;

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  transition: opacity 0.2s;

  ${props => props.isActive && "font-weight: 400;"}

  ${props => props.isPrimary && "background-color: #497FF3;"}
  background-color: #497FF3;

  :hover {
    font-weight: 500;
  }

  ${props =>
    props.variant === "outlined" &&
    `
    background: linear-gradient(black, black) padding-box,
      linear-gradient(to right, ${COLOR1}, ${COLOR2}) border-box;
    border-radius: 10px;
    border: 1px solid transparent;
  `}

  ${props =>
    props.isPrimary &&
    `
    background: linear-gradient(176.39deg, ${COLOR2} 39.89%, ${COLOR1} 105.35%);,
    border-radius: 10px;
  `}
`;

export default ButtonV1;
