import ButtonV1 from "components/ButtonV1";
import { Gap } from "components/Layout";
import { Navigate, useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  color: black;
  margin: 0 auto;
  padding: 0px 0;
  padding-bottom: 100px;
  background-color: white;
  z-index: 1;
  position: relative;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const Content = styled.div`
  width: 1200px;
  margin: 0 auto;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px;
`;

const Column = styled.div`
  display: grid;
  gap: 20px;
  border: 1px solid #b9b9b9;
  border-radius: 10px;
  padding: 20px;
  align-content: start;

  transition: background-color 0.2s, color 0.2s;
  :hover {
    background-color: #497ff3;
    color: white;
  }
`;

const ColumnTitle = styled.div`
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;

  /* background: linear-gradient(92deg, #000 -44.28%, #497ff3 32.47%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; */
`;

const ColumnText = styled.div`
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 100;
  line-height: 1.2;
`;

const StyledButtonV1 = styled(ButtonV1)`
  margin-top: 40px;
  padding: 10px 40px;
  height: auto;
  justify-self: center;
  font-size: 20px;
`;

const HorizontalLine = styled.div`
  height: 1px;
  background: linear-gradient(90deg, #70747b 0%, #999 100%);
  width: 100%;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 51.41px;
  font-weight: 500;
  line-height: 56px;
  /* width: 500px; */
  color: #747474;
  padding: 40px 0;
`;

const ThreeCardsSection = ({ isButtonHidden = false, title, columns }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Content>
        {title && <Title dangerouslySetInnerHTML={{ __html: title }} />}
        <Columns>
          {columns.map((column, index) => (
            <Column key={index} style={index === 0 ? { gridRow: "span 2", alignContent: "center" } : {}}>
              <ColumnTitle dangerouslySetInnerHTML={{ __html: column.title }} />
              <ColumnText dangerouslySetInnerHTML={{ __html: column.text }} />
              {/* {index === 0 && !isButtonHidden && (
                <StyledButtonV1 onClick={() => navigate("/solutions")}>Learn more about security</StyledButtonV1>
              )} */}
            </Column>
          ))}
        </Columns>
      </Content>
    </Container>
  );
};

export default ThreeCardsSection;
