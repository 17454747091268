import { Gap } from "components/Layout";
import { set } from "lodash";
import { GRADIENT_TEXT, GRADIENT_TEXT_FLIPPED } from "pages/main-v2";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect/dist/core";

const BORDER_STYLE_WHITE = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0.2) 0%, #ffffff 100%) padding-box,
    linear-gradient(to bottom, #d1d1d1, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const BORDER_STYLE_BLACK = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(21, 22, 23, 0) 0%, rgba(15, 18, 25, 0.9) 26.5%, #1B1F26 100%) padding-box,
    linear-gradient(to bottom, #d1d1d1, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const STAT = {
  finalValue: 30,
  unit: "x",
  subTitle: "Faster",
  description: "Creating concise answers at unprecedented speed without high end GPUs.",
};

const TEXT =
  "Generative AI is a cutting-edge technology that enables machines to produce content, such as images, text, music, and even videos, that mimic human creativity. By leveraging sophisticated algorithms, generative AI systems learn patterns from vast amounts of data and then generate new content based on those patterns.";

const TEXT_BZ =
  "Generative AI is a cutting-edge technology that enables machines to produce content, such as images, text, music, and even videos, that mimic human creativity. By leveraging sophisticated algorithms, generative AI systems learn patterns from vast amounts of data and then generate new content based on those patterns. Boltzbit LLM is a generative AI model that is 21x faster than other GPT models. It is designed to create concise answers at a speed for better business.";

const Container = styled.div`
  display: grid;
  gap: 80px;
  grid-template-columns: 1fr 1fr;
  height: 550px;
`;

const TextScroller = styled.div`
  height: 190px;
  overflow: auto;
  -ms-overflow-style: none;
  // hide scrollbar for IE and Edge
  scrollbar-width: none;
  // hide scrollbar for Firefox
  &::-webkit-scrollbar {
    display: none;
  }
`;

const Panel = styled.div`
  padding: 20px 40px;
  border-radius: 10px;
  line-height: 1.5;
  white-space: pre-wrap;
  ${props => (props?.isBlack ? BORDER_STYLE_BLACK : BORDER_STYLE_WHITE)};
  padding-top: 0;
  height: 275px;
  pointer-events: none;
`;

const PanelTitle = styled.div`
  padding-top: 20px;
  font-weight: 600;
  padding-bottom: 10px;
  white-space: nowrap;
  width: max-content;
  ${props => (props.isGpt ? GRADIENT_TEXT : GRADIENT_TEXT_FLIPPED)};
`;

const Stat = styled.div`
  padding: 32px;
  border-radius: 10px;
  display: grid;
  align-content: center;
`;

const StatTitle = styled.div`
  font-size: 200px;
  font-weight: 600;
  ${GRADIENT_TEXT_FLIPPED}
  div {
    font-size: 200px;
    font-weight: 600;
    ${GRADIENT_TEXT_FLIPPED}
  }

  display: grid;
  grid-template-columns: 250px auto;
`;

const StatSubTitle = styled.div`
  font-size: 40px;
  font-weight: 600;
`;

const StatFourCols = styled.div`
  display: grid;
  /* grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-items: end; */
`;

export const StatDescription = styled.div`
  font-size: 20px;
  font-weight: 300;
  margin-top: 10px;
  line-height: 1.2;
`;

const TwoRows = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  gap: 0px;
`;

const PromptText = styled.div`
  font-size: 18px;
  color: #b7b7b7;
  text-align: left;
  line-height: 1.5;
  /* height: 2em; */
  * {
    font-size: 18px;
    background-image: linear-gradient(135deg, #497ff3 0%, #497ff388 20%, #497ff3 40%, #497ff388 90%);
    background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  background-image: linear-gradient(135deg, #497ff3 0%, #497ff388 20%, #497ff3 40%, #497ff388 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const PromptTextGpt = styled(PromptText)`
  color: #959595;
  * {
    font-size: 18px;
    background-image: none;
    background-clip: none;
    -webkit-text-fill-color: #b7b7b7;
  }

  background-image: none;
  background-clip: none;
  -webkit-text-fill-color: #b7b7b7;
`;

const MS_PER_LETTER = 1;
const MS_PER_LETTER_GPT = 80;

const MS_PAUSE = 1000;

const SpeedAnimationV2 = () => {
  const [n, setN] = useState(0);
  const [statValue, setStatValue] = useState(0);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
  const statRef = useRef(null);

  const bzTextContainer = useRef(null);

  useEffect(() => {
    if (!bzTextContainer?.current) {
      return;
    }

    const intervalId = setInterval(() => {
      bzTextContainer.current.scrollTop = bzTextContainer.current.scrollHeight;
    }, 200);

    return () => clearInterval(intervalId);
  }, [bzTextContainer?.current]);

  useEffect(() => {
    const handleScroll = () => {
      if (statRef.current) {
        const rect = statRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setHasScrolledIntoView(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!hasScrolledIntoView) {
      setStatValue(0);
      return;
    }

    const intervalId = setInterval(() => {
      setStatValue(prev => {
        if (prev >= STAT.finalValue) {
          clearInterval(intervalId);
          return STAT.finalValue;
        }
        return prev + 1;
      });
    }, 40);

    return () => clearInterval(intervalId);
  }, [hasScrolledIntoView]);

  useEffect(() => {
    const newTypewriter = new Typewriter("#text-bz");
    newTypewriter
      .changeDelay(MS_PER_LETTER)
      .typeString(`${TEXT_BZ} ${TEXT_BZ} ${TEXT_BZ} ${TEXT_BZ} ${TEXT_BZ} ${TEXT_BZ}`)
      .pauseFor(MS_PAUSE)
      .start();

    const newTypewriterGpt = new Typewriter("#text-gpt");
    newTypewriterGpt
      .changeDelay(MS_PER_LETTER_GPT)
      .typeString(TEXT)
      .pauseFor(MS_PAUSE)
      .callFunction(() => setN(prev => prev + 1))
      .start();

    return () => {
      newTypewriter.stop();
      newTypewriterGpt.stop();
    };
  }, [n]);

  return (
    <Container>
      <Stat ref={statRef}>
        <StatFourCols>
          <StatTitle>
            <div>{statValue}</div>
            <div>{STAT.unit}</div>
          </StatTitle>
          <StatSubTitle>{STAT.subTitle}</StatSubTitle>
        </StatFourCols>

        <StatDescription>{STAT.description}</StatDescription>
      </Stat>

      <TwoRows>
        <Panel>
          <PanelTitle>Boltzbit LLM</PanelTitle>
          <TextScroller ref={bzTextContainer}>
            <PromptText id="text-bz">{/* {TEXT_BZ} {TEXT_BZ} {TEXT_BZ} */}</PromptText>
          </TextScroller>
          <Gap />
        </Panel>
        <Panel isBlack>
          <PanelTitle isGpt>GPTs</PanelTitle>
          <PromptTextGpt id="text-gpt"></PromptTextGpt>
        </Panel>
      </TwoRows>
    </Container>
  );
};

export default SpeedAnimationV2;
