import ButtonDark from "components/ButtonDark";
import ContactUsModal from "components/ContactUsModal";
import styled from "styled-components";

const Container = styled.div`
  background-image: linear-gradient(
    135deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
  color: white;
  font-size: 48px;
  text-align: center;
  padding: 40px;
  font-weight: 200;
`;

const Frame = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  gap: 40px;

  border: 0.2px solid #8b8b8b;
  padding: 60px 140px;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 4px #ffffff44;
`;

const Heading = styled.div`
  font-size: 42px;
`;

const BannerSection = ({ text, heading }) => {
  return (
    <Container>
      <Frame>
        <Heading>{heading}</Heading>
        {/* <ContactUsModal triggerComponent={<ButtonDark>{text}</ButtonDark>} /> */}
      </Frame>
    </Container>
  );
};

export default BannerSection;
