import { COLOR1, COLOR2 } from "pages/main-v2";
import styled from "styled-components";

const Container = styled.div`
  display: none;
  @media (min-width: 1240px) {
    display: grid;
  }

  width: 1200px;
  margin: 0 auto;
  align-items: center;
  grid-template-columns: 1fr 1fr;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  align-content: start;
  gap: 40px;
`;

const Title = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  font-size: 48px;
  line-height: 46px;
  letter-spacing: -0.016em;
  background: linear-gradient(88.86deg, ${COLOR2} 17.72%, ${COLOR1} 47%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const Text = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 1.4;
  padding-right: 100px;
  color: #424242;
`;

const Graphic = styled.img`
  height: 560px;
  justify-self: end;
`;

const MessageGraphicSection = ({ title, leftTitle = "", text, isLinkVisible = false }) => {
  return (
    <Container>
      <Content>
        <Title style={{ textAlign: "left" }}>{leftTitle}</Title>
        <Text
          style={leftTitle ? { textAlign: "left" } : {}}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
      </Content>
      <Graphic src="/images/logo-mountain.png" />
    </Container>
  );
};

export default MessageGraphicSection;
