import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import SectionsViewerMobileMinimal from "components/SectionsViewerMobileMinimal";
import { CommentTickIcon, GearIcon, ProcessorIcon } from "components/ui/Icons";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "message-with-background-light",
    title: "<span class='gradient-text'>Boltzbit</span> LLM API",
    subTitle: "Launching our LLM for Open Source",
    isShowingContactUs: true,
  },
  {
    type: "footer-v2",
  },
];

const DesktopContainer = styled.div`
  /* display: none; */
  /* background: linear-gradient(178.34deg, #161718 -2.94%, #14181f 90.88%, #000d78 621.32%); */
  background: linear-gradient(180deg, #e4e4e4 8.5%, #ffffff 40.5%, #f4f4f4 84.5%);
`;

const MobileContainer = styled.div``;

const Container = styled.div`
  /* @media (min-width: 1240px) {
    ${DesktopContainer} {
      display: block;
    }
    ${MobileContainer} {
      display: none;
    }
  } */
`;

const ApiPage = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "0ced6987-57da-4c35-938d-418acc5d328a") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
      {/* <MobileContainer>
        <SectionsViewerMobileMinimal sections={SECTIONS} templates={templates} isFooterHidden />
      </MobileContainer> */}
    </Container>
  );
};

export default ApiPage;
