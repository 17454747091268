import { postSlideTracking, postVerify } from "api/services/marketingService";
import Button from "components/Button";
import TextInput from "components/TextInput";
import { clamp } from "lodash";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { getCookieByName } from "utils/common";

const Container = styled.div`
  display: flex;
  justify-content: center;
  background-color: black;
  align-items: center;
  height: 100vh;
`;

const ContainerEmailPage = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  /* background-image: url("/images/color1.jpeg"); */
  background-size: cover;
  background-position: center;
`;

const Img = styled.img`
  height: 100%;
  width: 100%;
  object-fit: scale-down;
`;

const TopContainer = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: 1fr repeat(5, auto);
  gap: 22px;
  padding: 20px;
  align-items: center;
  z-index: 1;
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 500;
  padding-bottom: 20px;
  color: white;
`;

const StyledLink = styled.a`
  text-decoration: none;
  color: ${props => props.theme.color.furthest};
  width: max-content;
`;

const TopLogoImg = styled.img`
  height: 35px;
`;

const WhiteButton = styled(Button)`
  padding: 4px 16px;
  font-size: 14px;
  border: 2px solid;
  border-image: linear-gradient(to right, #0191ff, #6f24ba) 1;

  bottom: 0;
  right: 0;

  :hover {
    background: linear-gradient(to right, #0191ff, #6f24ba);
  }
`;

const InputContainer = styled.div`
  margin-top: 180px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: start;
  margin-left: auto;
  margin-right: auto;
  background-color: rgba(12, 14, 18, 0.867);
  width: 400px;

  border: 2px solid rgb(73, 86, 111);
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);

  ${props => props.isDisabled && "opacity: 0.8; pointer-events: none;"}
`;

const StyledTextInput = styled.input`
  border: 2px solid rgb(73, 86, 111);
  padding: 10px 15px;
  width: 100%;
  outline: none;
  background-color: transparent;
  color: white;
  font-family: "Montserrat";
`;

const Msg = styled.div`
  font-size: 16px;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  min-height: 18px;
  max-width: 400px;
  overflow: auto;
  color: white;
`;

const TunnelContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
`;

const AnimatedTunnel = () => {
  useEffect(() => {
    const threeJsScript = document.createElement("script");
    threeJsScript.src = "/static/scripts/three.min.js";
    threeJsScript.async = true;
    document.body.appendChild(threeJsScript);

    threeJsScript.onload = () => {
      const tunnelScript = document.createElement("script");
      tunnelScript.src = "/static/scripts/tunnel.js";
      tunnelScript.async = true;
      document.body.appendChild(tunnelScript);

      tunnelScript.onload = () => {
        // eslint-disable-next-line no-undef
        tunnel.setup({
          element: document.getElementById("tunnel"),
        });
      };
    };
  }, []);

  return <TunnelContainer id="tunnel" />;
};

const InvestorPage = () => {
  const [slideNumber, setSlideNumber] = useState(1);
  const [emailValue, setEmailValue] = useState("");
  const [accessToken, setAccessToken] = useState(getCookieByName("deckAccessToken"));
  const [isChecking, setIsChecking] = useState(false);
  const [msg, setMsg] = useState("");
  const imgRef = useRef(null);

  const [searchParams] = useSearchParams();
  const urlToken = searchParams.get("token");

  useEffect(() => {
    if (!imgRef.current) {
      return;
    }

    imgRef.current.src = `/images/deck/Slide${slideNumber}.jpeg`;
    imgRef.current.onload = async () => {
      const nowDate = new Date();
      const { data } = await postSlideTracking({}, { slideNumber, accessToken, timeViewed: nowDate.toString() });
      // to prevent user from accessing without a valid token
      if (!data?.id) {
        window.location = `/investor`;
      }
    };
  }, [slideNumber, accessToken]);

  useEffect(() => {
    const onKeyDown = e => {
      if (e.key === "ArrowRight") {
        setSlideNumber(s => clamp(s + 1, 1, 19));
      } else if (e.key === "ArrowLeft") {
        setSlideNumber(s => clamp(s - 1, 1, 19));
      }
    };

    document.addEventListener("keydown", onKeyDown);
    return () => document.removeEventListener("keydown", onKeyDown);
  }, []);

  const doVerifyEmail = async () => {
    setIsChecking(true);
    setMsg("Checking...");
    const { data, error } = await postVerify({}, { email: emailValue, urlToken });
    setIsChecking(false);

    const newAccessToken = data?.objectJSON;
    if (error || !newAccessToken || newAccessToken === "false") {
      setMsg("No access for this email.");
      return;
    }

    setAccessToken(newAccessToken);
    setMsg("");
    document.cookie = `deckAccessToken=${newAccessToken};max-age=3600;path=/investor`;
  };

  if (!urlToken) {
    return null;
  }

  if (!accessToken) {
    return (
      <ContainerEmailPage>
        <TopContainer>
          <StyledLink href="https://boltzbit.com/">
            <TopLogoImg src="/images/boltzbit_logo_h_white.png" />
          </StyledLink>
        </TopContainer>

        <AnimatedTunnel />

        <InputContainer isDisabled={isChecking}>
          <Title>Enter work email</Title>
          <StyledTextInput
            placeholder="Work Email *"
            value={emailValue}
            onChange={e => {
              setMsg("");
              setEmailValue(e.target.value);
            }}
            // onNewInput={newValue => {
            //   setMsg("");
            //   setEmailValue(newValue);
            // }}
          />
          <Msg isVisible>{msg}</Msg>
          <WhiteButton variant="highlighted" isDisabled={!emailValue} onClick={doVerifyEmail} value="View Deck" />
        </InputContainer>
      </ContainerEmailPage>
    );
  }

  return (
    <Container onKeyDown={e => console.log(e)}>
      <Img ref={imgRef} alt="slide" />
    </Container>
  );
};

export default InvestorPage;
