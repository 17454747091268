import styled from "styled-components";

const OuterContainer = styled.div`
  width: 100%;
  background-color: ${props => props.theme.color.secondary};
`;

const Bar = styled.div`
  background-color: ${props => props.color};
  width: ${props => props.progressPercentage}%;
  height: 100%;
  transition: width 0.2s;
`;

const ProgressBar = ({ color, progressPercentage, className }) => (
  <OuterContainer className={className}>
    <Bar color={color} progressPercentage={progressPercentage} />
  </OuterContainer>
);

export default ProgressBar;
