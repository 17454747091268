import { Gap } from "components/Layout";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  color: white;
  background: linear-gradient(0deg, #16181a 31.69%, #151f2c 85.46%);
  width: 95vw;
  margin: 0 auto;
  margin-top: 0px;
  padding-top: 20px;
  border-radius: 40px;

  display: grid;
  justify-content: center;
  justify-items: center;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 51.41px;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: -1.1200000047683716px;
  text-align: center;
`;

const WordsLine = styled.div`
  display: flex;
  gap: 20px;
  margin-bottom: 20px;
  width: 100vw;
  left: 0;
`;

const Word = styled.div`
  gap: 10px;
  box-sizing: border-box;

  background-color: #161819;
  border: 0.5px solid #3a3b4b;
  box-shadow: 0px 0px 20px #1e1e1e;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  display: flex;
  align-items: center;
  text-align: center;

  /* background: linear-gradient(90deg, #747474 0%, #dadada 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */
  color: #747474;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);

  white-space: nowrap;
  padding: 20px 80px;
`;

const LearnMoreLink = styled.a`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  text-align: center;
  width: max-content;
  margin-bottom: 20px;
  color: #497ff3;
`;

const WordCarouselSection = ({ title = "", wordsLine1 = [], wordsLine2 = [], linkText = "" }) => {
  const [offsetX, setOffsetX] = useState(0);

  useEffect(() => {
    const onScroll = e => {
      setOffsetX(window.scrollY % 50);
    };

    window.addEventListener("scroll", onScroll);
  }, []);

  return (
    <Container>
      <Title
        dangerouslySetInnerHTML={{
          __html: title,
        }}
      />
      <Gap height="20px" />
      <WordsLine style={{ transform: "translateX(20px)" }}>
        {wordsLine1.map((word, i) => (
          <Word key={i}>{word.text}</Word>
        ))}
      </WordsLine>

      <WordsLine style={{ transform: "translateX(-60px)" }}>
        {wordsLine2.map((word, i) => (
          <Word key={i}>{word.text}</Word>
        ))}
      </WordsLine>
      <LearnMoreLink href="/solutions">{linkText}</LearnMoreLink>
    </Container>
  );
};

export default WordCarouselSection;
