import styled from "styled-components";
import { FacebookIcon, InstagramIcon, LinkedinIcon, XIcon } from "components/ui/Icons";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  padding-top: 40px;
  color: white;
  background-color: black;

  margin-top: 60px;
  @media (min-width: ${props => props.theme.breakpoint.lg}) {
    margin-top: 0;
  }

  background-image: linear-gradient(
    45deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 60px;
  gap: 15px;

  @media (min-width: 768px) {
    gap: 0;
    flex-wrap: nowrap;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  svg {
    fill: white;
    stroke: white;
  }

  @media (min-width: 768px) {
    justify-content: end;
  }
`;

const SeparatorContainer = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 5px 1fr 5px;
  gap: 8px;
  align-items: center;
  padding-bottom: 30px;
`;

const Box = styled.div`
  width: 5px;
  height: 5px;
  background-color: white;
  /* background-color: ${props => props.theme.color.primary}; */
`;

const Line = styled.div`
  height: 1px;
  background-color: ${props => props.theme.color.closer0};
`;

const BottomSeparator = () => (
  <SeparatorContainer>
    <Box />
    <Line />
    <Box />
  </SeparatorContainer>
);

const BottomLinks = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  padding-bottom: 30px;
  grid-auto-flow: column;
  justify-content: start;
`;

const GreyText = styled.div`
  font-size: 10px;
  color: #b4b4b4;
`;

const GreyLink = styled.a`
  font-size: 10px;

  text-decoration: none;
  color: #b4b4b4;

  :hover {
    color: white;
  }
`;

const FooterSectionMinimal = () => {
  return (
    <Container>
      <LinksContainer>
        <SocialIcons>
          <XIcon />
          <LinkedinIcon height="18px" />
        </SocialIcons>
      </LinksContainer>
      <BottomSeparator />
      <BottomLinks>
        <GreyText>© 2022 Copyright All Right Reserved.</GreyText>
        <GreyText>Boltzbit Ltd</GreyText>
        <GreyLink href="/flow/contact">Contact</GreyLink>
        <GreyLink target="_blank" href="https://boltzbit.com/terms/">
          Terms & Conditions
        </GreyLink>
        <GreyLink target="_blank" href="https://boltzbit.com/privacy-policy/">
          Privacy Policy
        </GreyLink>
      </BottomLinks>
    </Container>
  );
};

export default FooterSectionMinimal;
