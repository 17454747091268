import styled from "styled-components";

const BackgroundContainer = styled.div`
  background-image: linear-gradient(
    135deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
`;

const Title = styled.div`
  font-size: 48px;
  text-align: center;
  font-weight: 400;
  line-height: 1.2;

  background-image: linear-gradient(135deg, #ffffff 0%, #d9d9d9 40%, #787368 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Text = styled.div`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  line-height: 1.4;

  background-image: linear-gradient(0deg, #ffffff 0%, #d9d9d9 80%, #c4bdae 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const Slide = styled.div`
  padding: 12px;
  height: 100vh;
  display: grid;
  gap: 20px;
  align-content: center;
  align-items: center;
`;

const ScrollerSectionMinimalMobile = ({ options = [] }) => {
  return (
    <BackgroundContainer>
      {options?.map((option, i) => (
        <Slide key={i}>
          <Title>{option.headingLines.join(" ")}</Title>
          <Text
            dangerouslySetInnerHTML={{
              __html: option?.text,
            }}
          />
        </Slide>
      ))}
    </BackgroundContainer>
  );
};

export default ScrollerSectionMinimalMobile;
