import { Gap } from "components/Layout";
import { StatDescription } from "components/SpeedAnimationV2";
import { GRADIENT_TEXT, GRADIENT_TEXT_FLIPPED } from "pages/main-v2";
import { useEffect } from "react";
import { useRef } from "react";
import { useState } from "react";
import styled from "styled-components";
import Typewriter from "typewriter-effect/dist/core";

const PURPLE = "#7f49f3";

const BORDER_STYLE_WHITE = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%) padding-box,
    linear-gradient(to bottom, #d1d1d1, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const BORDER_STYLE_BLACK = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(21, 22, 23, 0) 0%, rgba(15, 18, 25, 0.9) 26.5%, #1B1F26 100%) padding-box,
    linear-gradient(to bottom, #d1d1d1, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const STAT = {
  finalValue: 98,
  unit: "%",
  subTitle: "Accuracy",
  description: "Generating sequences of tokens provides the best answers for your needs.",
};

const PROMPT = "The revenue of you company has been growing";
const TOKENS_BZ = [
  "while maintaing a",
  "as outlined in",
  "due to to multiple",
  "thanks to many",
  "according to projections",
  "aligned with the",
  "in line with the",
  "keeping accordance",
];
const TOKENS_GPT = ["a", "beg", "by", "in", "the", "of", "to", "with"];

const FRAMES = [
  { prompt: PROMPT, tokens_bz: TOKENS_BZ, tokens_gpt: TOKENS_GPT, n: 16 },
  {
    prompt: "The main sources of revenue are",
    tokens_bz: [
      "aligned with",
      "according to",
      "as outlined in",
      "due to",
      "thanks to",
      "have been",
      "in line with",
      "beginning to",
      "exempt from",
    ],
    tokens_gpt: ["we", "al", "by", "and", "in", "the", "of", "to", "a"],
    n: 30,
  },
];

const Container = styled.div`
  display: grid;
  gap: 80px;
  grid-template-columns: 1fr 1fr;
  height: 550px;
`;

const Panel = styled.div`
  position: relative;
  border-radius: 10px;
  line-height: 1.5;
  white-space: pre-wrap;
  display: grid;
  align-content: start;

  padding: 20px 40px;

  border-radius: 10px;
  line-height: 1.5;
  ${props => (props?.isBlack ? BORDER_STYLE_BLACK : BORDER_STYLE_WHITE)}
`;

const PanelTitle = styled.div`
  font-weight: 600;
  padding-bottom: 0px;
  white-space: nowrap;
  width: max-content;
  ${props => (props.isGpt ? GRADIENT_TEXT : GRADIENT_TEXT_FLIPPED)};
`;

const PanelSubTitle = styled.div`
  font-weight: 400;
  padding-bottom: 10px;
  white-space: nowrap;
  color: #497ff3;
  ${props => (props.isGpt ? GRADIENT_TEXT : GRADIENT_TEXT_FLIPPED)};
`;

const Token = styled.div`
  padding: 2px;
  justify-self: center;
  width: 200px;
  white-space: nowrap;
  color: #b7b7b7;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const Rect = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 80px;
`;

const Stat = styled.div`
  padding: 32px;
  border-radius: 10px;
  display: grid;
  align-content: center;
`;

const StatTitle = styled.div`
  font-size: 200px;
  font-weight: 600;
  ${GRADIENT_TEXT_FLIPPED}
  * {
    font-size: 200px;
    font-weight: 600;
    ${GRADIENT_TEXT_FLIPPED}
  }

  display: grid;
  grid-template-columns: 250px auto;
`;

const StatSubTitle = styled.div`
  font-size: 40px;
  font-weight: 600;
`;

const StatFourCols = styled.div`
  display: grid;
  /* grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  justify-items: end; */
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: auto 1fr;
  align-content: start;
`;

const PromptSpan = styled.span`
  /* font-size: 32px; */
  color: #424242;
  transition: opacity 0.5s;
  font-weight: 600;
`;

const TokensContainer = styled.div`
  transition: opacity 0.5s;
`;

const TokensAnimationV2 = () => {
  const [index, setIndex] = useState(0);

  const [statValue, setStatValue] = useState(50);
  const [hasScrolledIntoView, setHasScrolledIntoView] = useState(false);
  const statRef = useRef(null);

  useEffect(() => {
    const handleScroll = () => {
      if (statRef.current) {
        const rect = statRef.current.getBoundingClientRect();
        const isVisible = rect.top < window.innerHeight && rect.bottom >= 0;
        setHasScrolledIntoView(isVisible);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  useEffect(() => {
    if (!hasScrolledIntoView) {
      setStatValue(50);
      return;
    }

    const intervalId = setInterval(() => {
      setStatValue(prev => {
        if (prev >= STAT.finalValue) {
          clearInterval(intervalId);
          return STAT.finalValue;
        }
        return prev + 1;
      });
    }, 20);

    return () => clearInterval(intervalId);
  }, [hasScrolledIntoView]);

  useEffect(() => {
    const intervalId = setInterval(() => setIndex(i => (i + 1) % FRAMES.length), 3000);

    return () => clearInterval(intervalId);
  }, []);

  const { prompt, tokens_bz, tokens_gpt, n } = FRAMES?.[index] || {};

  return (
    <Container>
      <TwoColumns>
        <div style={{ gridColumn: "span 2", alignContent: "center", marginBottom: "12px" }}>
          <PromptSpan style={{ position: "absolute", opacity: index === 0 ? 1 : 0 }}>
            {FRAMES?.[0]?.prompt} . . .
          </PromptSpan>
          <PromptSpan style={{ opacity: index === 0 ? 0 : 1 }}>{FRAMES?.[1]?.prompt} . . .</PromptSpan>
        </div>
        <Panel>
          <PanelTitle>Boltzbit LLM</PanelTitle>
          <PanelSubTitle>Top next sequence</PanelSubTitle>
          <div>
            <TokensContainer style={{ position: "absolute", opacity: index === 0 ? 1 : 0 }}>
              {FRAMES?.[0]?.tokens_bz.map(token => (
                <Token style={{ color: "#424242" }}>{token}</Token>
              ))}
            </TokensContainer>
            <TokensContainer
              style={{
                opacity: index === 0 ? 0 : 1,
              }}
            >
              {FRAMES?.[1]?.tokens_bz.map(token => (
                <Token style={{ color: "#424242" }}>{token}</Token>
              ))}
            </TokensContainer>
          </div>
        </Panel>
        <Panel isBlack>
          <PanelTitle isGpt>GPTs</PanelTitle>
          <PanelSubTitle isGpt>Top next token</PanelSubTitle>
          <div>
            <TokensContainer style={{ position: "absolute", opacity: index === 0 ? 1 : 0 }}>
              {FRAMES?.[0]?.tokens_gpt.map(token => (
                <Token>{token}</Token>
              ))}
            </TokensContainer>
            <TokensContainer
              style={{
                opacity: index === 0 ? 0 : 1,
              }}
            >
              {FRAMES?.[1]?.tokens_gpt.map(token => (
                <Token>{token}</Token>
              ))}
            </TokensContainer>
          </div>
        </Panel>
      </TwoColumns>

      <Stat ref={statRef}>
        <StatFourCols>
          <StatTitle>
            <div>{statValue}</div>
            <div>{STAT.unit}</div>
          </StatTitle>
          <StatSubTitle>{STAT.subTitle}</StatSubTitle>
        </StatFourCols>
        <StatDescription>{STAT.description}</StatDescription>
      </Stat>
    </Container>
  );
};

export default TokensAnimationV2;
