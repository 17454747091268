import { Gap } from "components/Layout";
import styled from "styled-components";

const Container = styled.div`
  color: black;

  display: none;
  justify-content: center;
  align-items: start;
  align-content: start;
  min-height: 100vh;
  width: 1200px;
  margin: 0 auto;

  grid-template-columns: 1fr 3fr;
  align-items: start;
  gap: 200px;

  margin-bottom: 100px;

  @media (min-width: 1240px) {
    display: grid;
  }
`;

const Title = styled.div`
  text-align: justify;
  font-family: Montserrat;
  font-size: 40px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: auto auto;
  gap: 40px;
  align-content: start;
  align-items: start;
  /* justify-content: center; */
`;

const PersonName = styled.div`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
`;

const Role = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #497ff3;
`;

const ShortDescription = styled.div`
  line-height: 1.5;
`;

const PersonImage = styled.img`
  width: 100%;
  height: 280px;
  object-fit: cover;
  border-radius: 10px;
`;

const PersonImageRect = styled.div`
  width: 100%;
  height: 200px;
  object-fit: cover;
  border-radius: 10px;
  background-color: #eaeaea;
`;

const Person = styled.div`
  display: grid;
  gap: 12px;
`;

const Description = styled.div`
  line-height: 1.5;
`;

const Column = styled.div``;

const TeamsSection = ({ title = "", description = "", teams = [] }) => {
  return (
    <Container>
      <Column>
        <Title>{title}</Title>
      </Column>
      <Column>
        <Description>{description}</Description>
        <Gap height="20px" />
        <Columns>
          {teams?.map(team => (
            <Person>
              {/* <PersonImage src={team?.imageSrc} /> */}
              {/* <PersonImageRect /> */}
              <PersonName>{team?.title}</PersonName>
              <ShortDescription>{team.description}</ShortDescription>
            </Person>
          ))}
        </Columns>
      </Column>
    </Container>
  );
};

export default TeamsSection;
