import styled from "styled-components";

import Button from "components/Button";
import { BoltzflowLogo } from "components/ui/Icons";

const Container = styled.div`
  padding: 0 50px;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 10px;

  display: grid;
  grid-template-areas:
    "logo"
    "graphic"
    "heading"
    "text"
    "button";
  gap: 20px;
  justify-items: center;
  text-align: center;

  @media (min-width: 768px) {
    justify-items: left;
    text-align: left;
    gap: 40px;
    grid-template-areas:
      "logo ."
      "heading graphic"
      "text graphic"
      "button graphic";
  }
`;

const LogoAndProduct = styled.div`
  display: grid;
  grid-area: logo;
  grid-template-columns: auto auto;
  grid-template-areas:
    "logoIcon smallText"
    "logoIcon bigText";
  grid-template-rows: auto auto;
  row-gap: 2px;
  column-gap: 10px;
  width: max-content;
`;

const BoltzflowLogoStyled = styled(BoltzflowLogo)`
  grid-area: logoIcon;
`;

const SmallLogoText = styled.div`
  grid-area: smallText;
  font-weight: 600;
  font-size: 16px;
  align-self: end;
`;

const BigLogoText = styled.div`
  grid-area: bigText;
  font-weight: 600;
  font-size: 32px;
  line-height: 30px;
`;

const Text = styled.div`
  grid-area: text;
  font-size: 20px;
  line-height: 30px;
`;

const Graphic = styled.img`
  grid-area: graphic;
  width: 100%;

  @media (min-width: 768px) {
    min-width: 600px;
  }
`;

const MainHeading = styled.div`
  grid-area: heading;
  font-style: normal;
  font-weight: 700;
  font-size: 60px;
  line-height: 60px;

  @media (min-width: 768px) {
    font-size: 60px;
    line-height: 60px;
  }
`;

const TryNowButton = styled(Button)`
  width: max-content;
  grid-area: button;
`;

const Main = ({ heading, text }) => {
  return (
    <Container>
      <LogoAndProduct>
        <BoltzflowLogoStyled />
        <SmallLogoText>Boltzhub</SmallLogoText>
        <BigLogoText>Boltzflow</BigLogoText>
      </LogoAndProduct>
      <MainHeading>{heading}</MainHeading>
      <Text>{text}</Text>
      <TryNowButton value="Try it now" />
      <Graphic src="/images/boltzflow.gif" alt="graphic" />
    </Container>
  );
};

export default Main;
