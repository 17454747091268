import styled from "styled-components";

const ButtonDark = styled.button`
  z-index: 1;
  min-width: 220px;
  max-width: 400px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 48px;
  color: #fff;
  border: 1px solid #373737;
  cursor: pointer;
  font-size: 20px;
  font-weight: 200;
  font-family: "Montserrat", sans-serif;
  background-color: #000000;
  box-shadow: 0px 0px 20px 4px #ffffff44;
  border-radius: 10px;

  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
  transition: opacity 0.2s;

  ${props => props.isActive && "font-weight: 400;"}

  :hover {
    font-weight: 400;
  }
`;

export default ButtonDark;
