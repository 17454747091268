import ButtonDark from "components/ButtonDark";
import { useState, useEffect } from "react";
import styled from "styled-components";
// import Typewriter from "typewriter-effect";
import Typewriter from "typewriter-effect/dist/core";

const Container = styled.div`
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: start;
  color: white;
  padding-top: 240px;

  background-image: linear-gradient(
    45deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
`;

const Frame = styled.div`
  display: grid;
  gap: 20px;
  padding: 20px;
  width: 80vw;

  border: 0.2px solid #8b8b8b;
  border-radius: 10px;
  box-shadow: 0px 0px 20px 4px #ffffff44;
`;

const StaticText = styled.div`
  font-size: 18px;
  font-weight: 200;
  text-align: left;
  color: #9a9a9a;
  line-height: 1.2;
`;

const PromptText = styled.div`
  font-weight: 200;
  text-align: left;
  line-height: 1.5;
  height: 2em;
  * {
    font-size: 18px;
  }
`;

const MS_PER_LETTER = 40;
const MS_PAUSE = 3000;
const MS_DELETE = 4;

// const FLOW_BASE_URL = "http://localhost:5000";
// const FLOW_BASE_URL = "https://ocr.boltztest.com";
const FLOW_BASE_URL = "https://flow.boltzbit.com";

const PromptsSectionMobile = ({ title, prompts = [], templates = [] }) => {
  const [isTyping, setIsTyping] = useState(true);
  const [indexBeingTyped, setIndexBeingTyped] = useState(0);
  const [typewriter, setTypewriter] = useState(null);

  useEffect(() => {
    const typewriter = new Typewriter("#prompt-text-mobile");

    setTypewriter(typewriter);

    return () => typewriter.stop();
  }, []);

  useEffect(() => {
    if (!typewriter || !templates?.length) {
      return;
    }

    typewriter
      .changeDelay(MS_PER_LETTER)
      .typeString(templates?.[indexBeingTyped]?.prompt)
      .callFunction(() => setIsTyping(false))
      .pauseFor(MS_PAUSE)
      .callFunction(() => setIsTyping(true))
      .deleteAll(MS_DELETE)
      .callFunction(() => {
        setIndexBeingTyped(prev => (prev + 1) % templates?.length);
      })
      .start();
  }, [JSON.stringify(templates), typewriter, indexBeingTyped]);

  return (
    <Container>
      <Frame>
        <StaticText>{title}</StaticText>
        <PromptText id="prompt-text-mobile"></PromptText>
        <ButtonDark
          style={{
            marginTop: "100px",
            justifySelf: "center",
            width: "120px",
            height: "42px",
            fontSize: "14px",
          }}
          isDisabled={isTyping}
          onClick={() => {
            const templateId = templates?.[indexBeingTyped]?._id;
            window.open(`${FLOW_BASE_URL}/flow-dashboard-templates/${templateId}/resolve`, "_blank");
          }}
        >
          Start
          {/* <Tip>{templates?.[indexBeingTyped]?.prompt}</Tip> */}
        </ButtonDark>
      </Frame>
    </Container>
  );
};

export default PromptsSectionMobile;
