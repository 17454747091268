import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const Video = styled.video`
  max-width: 70vw;
  max-height: 80vh;
  border: 1px solid #ccc;
  border-radius: 10px;

  @media (max-width: ${props => props.theme.breakpoint.lg}) {
    width: 90%;
    max-width: 90vw;
  }
`;

const Container = styled.div`
  position: relative;
  width: 100vw;
  overflow: hidden;

  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
`;

const VideoSection = ({ videoSrc }) => {
  // const [searchParams] = useSearchParams();

  // if (searchParams?.get("video") !== "true") {
  //   return null;
  // }

  return (
    <Container>
      <Video controls poster="/images/poster.png">
        <source src={videoSrc} type="video/mp4"></source>
      </Video>
    </Container>
  );
};

export default VideoSection;
