import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import SectionsViewerMobileMinimal from "components/SectionsViewerMobileMinimal";
import { CommentTickIcon, GearIcon, ProcessorIcon } from "components/ui/Icons";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "research-title",
    title: "True innovation makes the impossible possible",
    subTitle: "Groundbreaking research to make the next generation LLMs",
  },
  {
    type: "research-illustration",
    title:
      "Boltzbit LLMs <span style='color: #497FF3; font-size: inherit;'>learn to search</span> for the best sequence of tokens",
    subTitle: "Our models surpass the best GPTs boosted by tree search",
    text: "Our groundbreaking research shows that we can generate multiple tokens, faster. We believe our research creates real-world solutions for everyone. We differentiate ourselves by building systems that provide unlimited scope, factual information and fast customisation.<br/></br><b>Boltzbit LLMs provide optimal results.</b>",
  },
  // {
  //   type: "benchmarks-table",
  //   title: "Unparalleled benchmarks",
  //   benchmarks: [
  //     {
  //       name: "Training Efficiency",
  //       description: "Based on 50% accuracy on test data, Boltzbit LLMs can be trained in 1/4 of the time",
  //     },
  //     {
  //       name: "Accuracy",
  //       description: "Accuracy of investigation prompts after 8 hours of training, from 34%+ more accurate",
  //     },
  //     { name: "Cost", description: "" },
  //     { name: "Inference Efficiency", description: "" },
  //     { name: "Inference Cost", description: "" },
  //   ],
  //   columns: ["Model", "Training Efficiency", "Accuracy", "Cost", "Inference Efficiency", "Inference Cost"],
  //   records: [
  //     {
  //       Model: "Boltzbit",
  //       "Training Efficiency": "1/4",
  //       Accuracy: "34%+",
  //       Cost: "£1.6M",
  //       "Inference Efficiency": "1/4",
  //       "Inference Cost": "£1.6M",
  //     },
  //     {
  //       Model: "GPT-4",
  //       "Training Efficiency": "1",
  //       Accuracy: "34%",
  //       Cost: "£6.4M",
  //       "Inference Efficiency": "1",
  //       "Inference Cost": "£6.4M",
  //     },
  //     {
  //       Model: "GPT-3",
  //       "Training Efficiency": "1",
  //       Accuracy: "34%",
  //       Cost: "£6.4M",
  //       "Inference Efficiency": "1",
  //       "Inference Cost": "£6.4M",
  //     },
  //   ],
  //   description:
  //     "Data set:  500 emails with answers to 10 questions against each email<br/>Training data set = 400 emails<br/>Testing data set = 100 emails",
  // },
  // {
  //   type: "stats",
  //   stats: [
  //     {
  //       title: "20x",
  //       subTitle: "Faster",
  //       text: "Our groundbreaking research shows that we can generate multiple tokens, faster.",
  //     },
  //     {
  //       title: "+30%",
  //       subTitle: "Accuracy",
  //       text: "Our groundbreaking research shows that we can generate multiple tokens, faster.",
  //     },
  //   ],
  // },
  // {
  //   type: "illustration-two-column",
  //   title: "<span style='color: #497FF3; font-size: inherit;'>Unparalleled</span> benchmarks",
  //   text: "Our groundbreaking research shows that we can generate multiple tokens, faster. We believe our research creates real-world solutions for everyone. We differentiate ourselves by building systems that provide unlimited scope, factual information and fast customisation.",
  //   imageSrc: "/images/illustration-2.png",
  //   description:
  //     "We believe our research creates real-world solutions for everyone. We differentiate ourselves by building systems that provide unlimited scope, factual information and fast customisation.",
  // },
  {
    type: "research-papers",
    title: "Research papers",
    papers: [
      {
        date: "2012 NeurIPS",
        title: "Continuous Relaxations for Discrete Hamiltonian Monte Carlo",
        url: "https://mlg.eng.cam.ac.uk/pub/pdf/ZhaSutSto12a.pdf",
      },
      {
        date: "2021 NeurIPS",
        title: "A Gradient Based Strategy for Hamiltonian Monte Carlo Hyperparameter Optimization",
        url: "https://proceedings.mlr.press/v139/campbell21a/campbell21a.pdf",
      },
    ],
  },
  // {
  //   type: "banner-v1",
  //   heading: "Take the next step",
  //   colorScheme: "light",
  //   text: "Talk to us",
  // },
  {
    type: "footer-v1",
  },
];

const DesktopContainer = styled.div`
  /* display: none; */
  background-color: #f8f8f8;
  /* background: linear-gradient(178.34deg, #161718 -2.94%, #14181f 90.88%, #000d78 621.32%); */
`;

const MobileContainer = styled.div``;

const Container = styled.div`
  /* @media (min-width: 1240px) {
    ${DesktopContainer} {
      display: block;
    }
    ${MobileContainer} {
      display: none;
    }
  } */
`;

const ResearchPage = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "0ced6987-57da-4c35-938d-418acc5d328a") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
      {/* <MobileContainer>
        <SectionsViewerMobileMinimal sections={SECTIONS} templates={templates} isFooterHidden />
      </MobileContainer> */}
    </Container>
  );
};

export default ResearchPage;
