import TopBar from "components/TopBar";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 40px;
  padding: 80px;
  height: 100vh;
  align-content: center;
`;

const Title = styled.div`
  font-size: 60px;
  font-weight: 500;
`;

const Text = styled.div`
  font-size: 42px;
  line-height: 1.5;
  font-style: normal;
  font-weight: 500;
`;

const StyledLink = styled.a`
  color: white;
  text-decoration: underline;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
`;

const UnderConstructionSection = () => {
  return (
    <Container>
      <TopBar />
      <Title>Coming soon</Title>
      {/* <div />
      <div /> */}
      {/* <Text
        dangerouslySetInnerHTML={{
          __html: "This page is under construction",
        }}
      /> */}
      <div />
      <StyledLink href="/">Back</StyledLink>
    </Container>
  );
};

export default UnderConstructionSection;
