import BannerSectionMobile from "components/BannerSectionMobile";
import FooterSectionMinimalMobile from "components/FooterSectionMinimalMobile";
import HeroSectionMobile from "components/HeroSectionMobile";
import PromptsSectionMobile from "components/PromptsSectionMobile";
import ScrollerSectionMinimalMobile from "components/ScrollerSectionMinimalMobile";

const SectionsViewerMobileMinimal = ({ sections, templates = [] }) => {
  return (
    <>
      {sections.map((section, i) => {
        if (section.type === "hero") {
          return <HeroSectionMobile key={i} {...section} />;
        }

        if (section.type === "prompts") {
          return <PromptsSectionMobile key={i} {...section} templates={templates} />;
        }

        if (section.type === "scroller-minimal") {
          return <ScrollerSectionMinimalMobile key={i} {...section} />;
        }

        if (section.type === "banner") {
          return <BannerSectionMobile key={i} {...section} />;
        }

        if (section.type === "footer-minimal") {
          return <FooterSectionMinimalMobile key={i} />;
        }

        return null;

        // return (
        //   <div style={{ overflow: "hidden" }} key={i}>
        //     {JSON.stringify(section)}
        //   </div>
        // );
      })}
    </>
  );
};

export default SectionsViewerMobileMinimal;
