import { Gap } from "components/Layout";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  color: white;
  display: grid;
  justify-content: center;
  padding-top: 80px;
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 600;
  line-height: 53.6px;
  letter-spacing: -1.399999976158142px;
  text-align: center;
`;

const SubTitle = styled.div`
  font-size: 22px;
  font-weight: 500;
  line-height: 33px;
  letter-spacing: -0.5px;
  text-align: center;
  opacity: 0.5;
`;

const Questions = styled.div`
  width: 1000px;
  border-bottom: 1px solid #e2e3e5;
`;

const QuestionContainer = styled.div`
  transition: height 0.1s;
  border-top: 1px solid #e2e3e5;
  height: 70px;
  overflow: hidden;

  ${props => props.isExpanded && `height: 115px;`}
`;

const Question = styled.div`
  cursor: pointer;
  height: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const Answer = styled.div`
  font-size: 14px;
  padding-bottom: 30px;
`;

const StyledSvg = styled.svg`
  transition: transform 0.1s;
`;

const StyledChevronDown = ({ style = {} }) => (
  <StyledSvg style={style} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M16.5 10.5L12 15.3462L7.5 10.5"
      stroke="white"
      stroke-width="1.5"
      stroke-linecap="square"
      stroke-linejoin="round"
    />
  </StyledSvg>
);

const FaqSection = ({ title, subTitle, questions = [] }) => {
  const [expandedIndices, setExpandedIndices] = useState([]);

  return (
    <Container>
      <Title>{title}</Title>
      <Gap height="20px" />
      <SubTitle>{subTitle}</SubTitle>
      <Gap height="80px" />
      <Questions>
        {questions.map((q, i) => (
          <QuestionContainer key={i} isExpanded={expandedIndices?.includes(i)}>
            <Question
              onClick={() => {
                if (expandedIndices.includes(i)) {
                  setExpandedIndices(expandedIndices.filter(x => x !== i));
                } else {
                  setExpandedIndices([...expandedIndices, i]);
                }
              }}
            >
              {q.question}
              <StyledChevronDown style={{ transform: expandedIndices?.includes(i) ? "" : "rotate(90deg)" }} />
            </Question>
            <Answer>{q.answer}</Answer>
          </QuestionContainer>
        ))}
      </Questions>
    </Container>
  );
};

export default FaqSection;
