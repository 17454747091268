import { Gap } from "components/Layout";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  padding: 20px;
  @media (min-width: 1240px) {
    padding: 0;
    padding-top: 80px;
  }

  color: white;
  justify-content: center;
  padding-top: 20px;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 51.41px;
  font-weight: 500;
  line-height: 56px;
  letter-spacing: -1.1200000047683716px;
  text-align: center;

  @media (min-width: 1240px) {
    width: 730px;
  }
`;

const SubTitle = styled.div`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  line-height: 28.6px;
  text-align: center;
  color: #b4bcd0;
`;

const Input = styled.input`
  background: #2e2d2d;
  border: 0.5px solid rgba(167, 167, 167, 0.56);
  box-shadow: 0px 0px 10px #acc7ec;
  border-radius: 10px;
  height: 60px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 40px;
  color: #ffffff;
  outline: none;
  padding: 0 20px;
`;

const BottomButtons = styled.div`
  display: grid;
  grid-auto-flow: column;
  gap: 20px;
`;

const BottomButton = styled.div`
  background: #161819;
  border: 0.5px solid #3a3b4b;
  box-shadow: 0px 0px 20px #1e1e1e;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;

  padding: 8px;
  font-weight: 400;
  font-size: 15px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background: linear-gradient(90deg, #747474 0%, #dadada 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;

  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
`;

const TopContent = styled.div`
  display: grid;
  justify-content: center;
  padding-top: 40px;
  gap: 20px;
  border-radius: 20px;
  background: radial-gradient(60% 80% at 50% -18%, #30323c 0%, #30323c 60%, rgba(0, 0, 0, 0) 100%);

  @media (min-width: 1240px) {
    width: 1200px;
  }
`;

const ImageSection = styled.div`
  position: relative;

  @media (min-width: 1240px) {
    display: grid;
    grid-template-columns: auto auto;
    gap: 60px;
    justify-content: center;
    grid-auto-flow: dense;
  }
`;

const ImageSections = styled.div`
  display: grid;
  justify-content: center;

  /* background: linear-gradient(142.05deg, #16181a 31.69%, #151f2c 85.46%); */
  border-radius: 40px;
  margin: 0 auto;

  @media (min-width: 1240px) {
    width: 1200px;
  }
`;

const ImageContent = styled.img`
  width: 100%;
  z-index: 1;

  @media (min-width: 1240px) {
    width: 650px;
  }
`;

const ImageSticker = styled.img`
  height: 135px;
  position: absolute;
  left: -80px;
  z-index: 0;
  top: 68px;
`;

const TextContent = styled.div`
  width: 263px;
  border-radius: 48px;
  align-self: center;

  display: grid;
  align-content: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
`;

const SectionTitle = styled.div`
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 700;
  font-size: 42px;
  line-height: 48px;
  align-items: center;
  letter-spacing: -0.26px;
  color: #497ff3;

  /* background: linear-gradient(264.26deg, #753098 18%, #497ff3 42.88%, #497ff3 116.94%); */
  /* -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent; */
`;

const SectionDescription = styled.div`
  font-family: Montserrat;
  font-size: 24px;
  font-weight: 200;
  line-height: 30px;
  color: #d9d9d9;
`;

const Svg = styled.svg`
  height: 90px;
  width: 100%;
  line {
    stroke: #e2e3e522;
  }
`;

const SvgDivider = ({ style = {} }) => (
  <Svg viewBox="0 0 802 90" style={style}>
    <line x1="1" y1="59.5" x2="784" y2="59.5001" />
    <line x1="19" y1="44.5" x2="802" y2="44.5001" />
    <line x1="0.5" y1="60" x2="0.499998" y2="1.63918e-08" />
    <line x1="18.5" y1="45" x2="18.5" y2="2.18557e-08" />
    <line x1="801.5" y1="90" x2="801.5" y2="45" />
    <line x1="783.5" y1="90" x2="783.5" y2="60" />
  </Svg>
);

const JourneySection = ({
  title = "Your Gen-AI blueprint starts with a question",
  subTitle = "Everything you need, straight from your chatbox.",
  placeholder = "What can we do for you?",
  bottomButtons = [
    { text: "Check financial model" },
    { text: "Highlight risks" },
    { text: "Write draft email" },
    { text: "Build a database" },
  ],
  imagesSections = [
    {
      imageSrc: "",
      title: "Aggregate",
      text: "Pull your data from <b>emails, websites, external feeds, PDFs, excels</b> and images.",
    },
  ],
}) => {
  return (
    <Container>
      <TopContent>
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <SubTitle
          dangerouslySetInnerHTML={{
            __html: subTitle,
          }}
        />
        <Gap />
        <Input disabled value={placeholder} />
        <BottomButtons>
          {bottomButtons.map((button, i) => (
            <BottomButton key={i}>{button.text}</BottomButton>
          ))}
        </BottomButtons>
      </TopContent>

      <ImageSections>
        {imagesSections.map((section, i) => (
          <>
            <SvgDivider style={{ transform: i % 2 === 0 ? "scaleX(-1)" : "" }} />
            <ImageSection key={i}>
              <ImageContent style={{ gridColumn: i % 2 === 0 ? 1 : 2 }} src={section?.imageSrc} />
              {section?.stickerSrc && <ImageSticker src={section?.stickerSrc} />}
              <TextContent style={{ gridColumn: i % 2 === 0 ? 2 : 1 }}>
                <SectionTitle>{section?.title}</SectionTitle>
                <SectionDescription
                  dangerouslySetInnerHTML={{
                    __html: section?.text,
                  }}
                />
              </TextContent>
            </ImageSection>
          </>
        ))}
        <Gap height="120px" />
      </ImageSections>
      <svg
        style={{ marginLeft: "300px", marginTop: "-120px" }}
        width="33"
        height="293"
        viewBox="0 0 33 293"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line x1="0.5" y1="293" x2="0.5" stroke="#B9B9B9" stroke-opacity="0.33" />
        <line x1="32.144" y1="293" x2="32.144" stroke="#B9B9B9" stroke-opacity="0.33" />
      </svg>
    </Container>
  );
};

export default JourneySection;
