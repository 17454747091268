import { Gap } from "components/Layout";
import { COLOR1, COLOR2 } from "pages/main-v2";
import styled from "styled-components";

const LogosContainer = styled.div`
  display: none;
  justify-content: center;
  text-align: center;

  @media (min-width: 1240px) {
    display: grid;
  }
`;

const Logos = styled.div`
  display: flex;
  gap: 80px;
  flex-wrap: wrap;
  align-items: center;
`;

const Title = styled.div`
  color: #bdbdbd;
  z-index: 1;
  text-align: center;

  justify-self: center;

  font-family: Montserrat;
  font-size: 24px;
  font-weight: 300;
  line-height: 1.4;
  text-align: center;
`;

const Logo = styled.img``;

const HorizontalLine = styled.div`
  height: 1px;
  background: linear-gradient(
    90deg,
    rgba(73, 127, 243, 0) 0%,
    ${COLOR2} 25.5%,
    #ffffff 50.5%,
    ${COLOR1} 75%,
    rgba(56, 208, 218, 0) 100%
  );
`;

const LogosSection = ({ logos, title = "" }) => (
  <LogosContainer>
    <Title>{title}</Title>
    <Gap height="20px" />
    <Logos>
      {logos.map((logo, i) => (
        <Logo src={logo?.src} key={i} style={{ height: logo?.height || 30 }} />
      ))}
    </Logos>
    <Gap height="20px" />
    <HorizontalLine />
    <Gap height="100px" />
  </LogosContainer>
);

export default LogosSection;
