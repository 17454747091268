import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "colored-blocks",
    title: "Newsfeed",
    subTitle: "Find out more about our latest news.",
    blocks: [
      {
        title: "Boltzbit Awarded Innovate UK Funding",
        color: "#497FF3",
        subTitle: "27 March 2024",
        url: "https://www.linkedin.com/posts/boltzbit_happy-easter-we-are-celebrating-the-start-activity-7180489065606045696-zmD5",
      },
      {
        title: "The Future of AI and Finance",
        color: "#FFA96A",
        subTitle: "27 March 2024",
        url: "https://www.linkedin.com/pulse/future-finance-ai-debt-capital-markets-boltzbit-qomwf?trk=public_post",
      },
      {
        title: "£1.6m Seed Round",
        color: "#6663F6",
        subTitle: "16 March 2022",
        url: "https://www.uktech.news/ai/boltzbit-seed-funding-20220316",
      },
    ],
  },
  {
    type: "footer-v1",
  },
];

const DesktopContainer = styled.div`
  /* display: none;
  background: linear-gradient(178.34deg, #161718 -2.94%, #14181f 90.88%, #000d78 621.32%); */
`;

const MobileContainer = styled.div``;

const Container = styled.div`
  /* @media (min-width: 1240px) {
    ${DesktopContainer} {
      display: block;
    }
    ${MobileContainer} {
      display: none;
    }
  } */
`;

const NewsPage = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "0ced6987-57da-4c35-938d-418acc5d328a") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
      {/* <MobileContainer>
        <SectionsViewerMobileMinimal sections={SECTIONS} templates={templates} isFooterHidden />
      </MobileContainer> */}
    </Container>
  );
};

export default NewsPage;
