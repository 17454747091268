import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { clamp, max, min } from "lodash";

const MessageLine = styled.div`
  font-size: 42px;
  font-weight: 700;
  padding: 4px 0;
  transition: opacity 0.4s ease-in-out;
`;

const Description = styled.div`
  z-index: 1;

  font-weight: 600;
  line-height: 1.5;
  font-size: 25px;
`;

const DescriptionUnderlay = styled(Description)`
  position: absolute;
  z-index: 0;
`;

const DesciptionContainer = styled.div`
  position: relative;
`;

const MessageSectionContainer = styled.div`
  display: grid;
  justify-items: center;
  align-content: center;
  transition: opacity 0.4s ease-in-out;
  padding: 80px 20px;
`;

const mapValue = (domain = [], range = [], value) => {
  const [domainStart, domainEnd] = domain;
  const [rangeStart, rangeEnd] = range;

  const domainLength = domainEnd - domainStart;
  const rangeLength = rangeEnd - rangeStart;

  const domainValue = value - domainStart;
  const ratio = domainValue / domainLength;
  const rangeValue = rangeLength * ratio;
  const valueInRange = rangeStart + rangeValue;

  return clamp(valueInRange, min(range), max(range));
};

const AnimatedContentMobile = ({ messageLine1 = "", messageLine2 = "", description = "" }) => {
  return (
    <MessageSectionContainer>
      <MessageLine>{messageLine1}</MessageLine>
      <MessageLine>{messageLine2}</MessageLine>
      <DesciptionContainer>
        <Description style={{ marginTop: "60px" }} dangerouslySetInnerHTML={{ __html: description }} />
        {/* <DescriptionUnderlay
          style={{ marginTop: "60px", color: "#dfdfdf" }}
          dangerouslySetInnerHTML={{ __html: description }}
        /> */}
      </DesciptionContainer>
    </MessageSectionContainer>
  );
};

export default AnimatedContentMobile;
