import TopBar from "components/TopBar";
import { useState } from "react";
import styled from "styled-components";

const Container = styled.div`
  color: white;
  background: linear-gradient(178deg, #161718 -2.94%, #14181f 68.63%, #000d78 121.32%);

  display: grid;
  justify-content: center;
  align-items: start;
  align-content: start;
  min-height: 100vh;
`;

const Title = styled.div`
  padding-top: 120px;
  color: #fafafa;
  text-align: justify;
  font-family: Montserrat;
  font-size: 40px;
  font-style: normal;
  font-weight: 700;
`;

const Tabs = styled.div`
  display: flex;
  padding: 40px 0;
  gap: 20px;
`;

const Tab = styled.div`
  cursor: pointer;
  ${props => props.isActive && `text-decoration: underline;`}
  ${props => props.isDisabled && `opacity: 0.5; pointer-events: none;`}
`;

const TwoColumns = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 1200px;
  gap: 50px;
  align-content: start;
  align-items: start;
`;

const Partners = styled.div`
  display: grid;
  width: 1200px;
  grid-auto-flow: column;
  gap: 50px;
  align-content: start;
  align-items: start;
`;

const Text = styled.div`
  color: #fafafa;
  text-align: justify;
  font-family: Montserrat;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 20px; /* 142.857% */
`;

const Image = styled.img`
  height: 400px;
`;

const PersonName = styled.div`
  font-family: Montserrat;
  font-size: 32px;
  font-weight: 600;
  line-height: 39.01px;
  text-align: left;
`;

const Role = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  line-height: 19.5px;
  text-align: left;
  color: #497ff3;
`;

const Description = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
`;

const PersonImage = styled.img`
  height: 390px;
  width: 390px;
  object-fit: cover;
`;

const PersonText = styled.div`
  display: grid;
  gap: 20px;
`;

const LogoImage = styled.img`
  height: 100px;
`;

const LogoText = styled.div``;

const AboutUsSection = ({ title, tabs = [] }) => {
  const [activeTabTitle, setActiveTabTitle] = useState(tabs[0]?.title);

  const activeTab = tabs.find(tab => tab.title === activeTabTitle);

  return (
    <Container>
      <TopBar />
      <Title>{title}</Title>
      <Tabs>
        {tabs.map(tab => (
          <Tab
            isDisabled={tab?.isDisabled}
            key={tab.title}
            isActive={activeTabTitle === tab.title}
            onClick={() => setActiveTabTitle(tab.title)}
          >
            {tab.title}
          </Tab>
        ))}
      </Tabs>
      {activeTab?.type === "text-and-image" && (
        <TwoColumns>
          <Text dangerouslySetInnerHTML={{ __html: activeTab?.text }} />
          <Image src={activeTab?.imageSrc} />
        </TwoColumns>
      )}
      {activeTab?.type === "people" &&
        activeTab?.people?.map(person => (
          <TwoColumns
            style={{
              gridTemplateColumns: "1fr auto",
              marginBottom: "60px",
            }}
          >
            <PersonText>
              <PersonName>{person?.name}</PersonName>
              <Role>{person?.role}</Role>
              <Description dangerouslySetInnerHTML={{ __html: person?.description }} />
            </PersonText>
            <PersonImage src={person?.imageSrc} />
          </TwoColumns>
        ))}
      {activeTab?.type === "partners" && (
        <Partners>
          {activeTab?.partners?.map(partner => (
            <div>
              <LogoImage src={partner?.logoSrc} />
              <LogoText>{partner?.description}</LogoText>
            </div>
          ))}
        </Partners>
      )}
    </Container>
  );
};

export default AboutUsSection;
