import styled from "styled-components";

const Container = styled.div`
  padding: 100px 20px;
  display: grid;
  justify-items: center;
  align-items: center;
  align-content: center;
  width: 100%;
  min-height: 100vh;
`;

const MessageLine = styled.div`
  font-size: 42px;
  font-weight: 700;
  padding: 4px 0;
`;

const Description = styled.div`
  max-width: 70%;
  text-align: center;
  line-height: 1.5;
`;

const Message = ({ messageLine1, messageLine2, description }) => {
  return (
    <Container>
      <MessageLine>{messageLine1}</MessageLine>
      <MessageLine>{messageLine2}</MessageLine>
      <Description style={{ marginTop: "60px" }} dangerouslySetInnerHTML={{ __html: description }} />
    </Container>
  );
};

export default Message;
