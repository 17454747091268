import { createGlobalStyle } from "styled-components";

import MontserratVariable from "fonts/Montserrat-VariableFont_wght.ttf";

const Fonts = createGlobalStyle`
  @font-face {
    font-family: "Montserrat";
    src: url(${MontserratVariable}) format("woff2-variations");
    font-weight: 100 900;
  }

  body {
    font-family: "Montserrat", sans-serif;
    font-weight: 400;
    color: ${props => props.textColor};
  }
`;

export default Fonts;
