import { Gap } from "components/Layout";
import styled from "styled-components";

const Container = styled.div`
  color: white;
  width: 1200px;
  margin: 0 auto;
  padding: 100px 0;
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 51.41px;
  font-weight: 500;
  letter-spacing: -1.1200000047683716px;
  text-align: center;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 100px;
`;

const Column = styled.div`
  display: grid;
  gap: 20px;
`;

const ColumnTitle = styled.div`
  font-family: Montserrat;
  font-size: 40px;
  font-weight: 300;
  line-height: 48px;
  text-align: center;

  background: linear-gradient(92deg, #000 -44.28%, #497ff3 32.47%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ColumnText = styled.div`
  color: #fff;
  text-align: center;
  font-family: Montserrat;
  font-size: 20px;
  font-style: normal;
  font-weight: 100;
  line-height: 30px;
`;

const HorizontalLine = styled.div`
  height: 1px;
  background: linear-gradient(90deg, #70747b 0%, #999 100%);
  width: 100%;
`;

const ColumnsSectionV1 = ({ title, columns }) => {
  return (
    <Container>
      <HorizontalLine style={{ marginBottom: "100px" }} />
      <Title>{title}</Title>
      <Gap height="40px" />
      <Columns>
        {columns.map((column, index) => (
          <Column key={index}>
            <ColumnTitle dangerouslySetInnerHTML={{ __html: column.title }} />
            <ColumnText dangerouslySetInnerHTML={{ __html: column.text }} />
          </Column>
        ))}
      </Columns>
      <HorizontalLine style={{ marginTop: "100px" }} />
    </Container>
  );
};

export default ColumnsSectionV1;
