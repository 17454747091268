import styled from "styled-components";

import { theme } from "theme";
import { Heading, SubHeading } from "components/Layout";
import VerticalProgressBar from "components/VerticalProgressBar";

const isOptionVisible = (optionIndex, progressPercentage, numOptions) => {
  const percentagePerOption = Math.ceil(100 / numOptions);
  const visibleOptionIndex = Math.floor(progressPercentage / percentagePerOption);
  if (progressPercentage === 100 && optionIndex === numOptions - 1) {
    return true;
  }

  return visibleOptionIndex === optionIndex;
};

const isSourceImageVisible = (optionIndex, progressPercentage, numOptions, sourceIndex, numSources) => {
  const percentagePerOption = Math.ceil(100 / numOptions);
  const percentagePerSource = Math.ceil(percentagePerOption / numSources);

  const visibleSourceIndex = Math.floor((progressPercentage - optionIndex * percentagePerOption) / percentagePerSource);
  return visibleSourceIndex === sourceIndex;
};

const Container = styled.div`
  margin-top: 52px;
  width: 100%;
  padding: 180px 20px 75px 20px;
  display: grid;

  grid-template-columns: 1fr 20px;
  grid-template-rows: auto auto auto auto;
  grid-template-areas:
    "optionsNav progressBar"
    "optionSubHeading progressBar"
    "optionHeading progressBar"
    "optionText progressBar";

  ${props =>
    props.textAlignment === "left" &&
    `
    grid-template-areas:
      "progressBar optionsNav"
      "progressBar optionSubHeading"
      "progressBar optionHeading"
      "progressBar optionText"; 
    grid-template-columns: 20px 1fr;
  `}

  @media (min-width: 768px) {
    padding: 0px 40px 0px 0px;
    grid-template-areas:
      "graphic optionsNav progressBar"
      "graphic optionSubHeading progressBar"
      "graphic optionHeading progressBar"
      "graphic optionText progressBar";
    grid-template-columns: 1fr 1fr 10px;

    column-gap: 80px;
    grid-template-rows: auto auto auto 1fr;

    ${props =>
      props.textAlignment === "left" &&
      `
    padding: 0px 0px 0px 40px;
    grid-template-areas:
      "progressBar optionsNav graphic"
      "progressBar optionSubHeading graphic"
      "progressBar optionHeading graphic"
      "progressBar optionText graphic"; 
    grid-template-columns: 10px 1fr 640px;
  `}
  }

  @media (min-width: ${props => props.theme.breakpoint.wide}) {
    column-gap: 120px;
  }
`;

const Graphic = styled.img`
  grid-area: graphic;
  ${props => props.textAlignment === "left" && "margin-left: 40px"};
  ${props => props.textAlignment === "right" && "margin-right: 40px"};
  height: 550px;
  width: 660px;
  justify-self: ${props => (props.textAlignment === "right" ? "start" : "end")};
  display: none;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  transition: opacity 0.2s;
  object-fit: cover;
  object-position: ${props => (props.textAlignment === "right" ? "top right" : "top left")};
  mask: linear-gradient(to top, transparent 0%, black 40%);

  @media (max-width: ${props => props.theme.breakpoint.wide}) {
    ${props =>
      props.textAlignment === "right" &&
      `
      object-fit: contain;
      object-position: center right;
      mask: linear-gradient(to top, transparent 10%, black 50%);
      height: 100%;
  `};
  }

  @media (min-width: 768px) {
    display: block;
    min-width: 520px;
  }

  @media (min-width: ${props => props.theme.breakpoint.wide}) {
    display: none;
    min-width: 50vw;
  }
`;

const GraphicWide = styled(Graphic)`
  display: none;
  @media (min-width: ${props => props.theme.breakpoint.wide}) {
    display: block;
  }
`;

const OptionsNav = styled.div`
  grid-area: optionsNav;
  display: flex;
  gap: 20px;
`;

const NavItem = styled.div`
  pointer-events: all;
  cursor: pointer;

  transition: color 0.2s;
  font-weight: 610;
  color: ${props => (props.isHighlighted ? props.theme.color.furthest : props.inactiveColor)};

  white-space: nowrap;
  font-size: 19px;
`;

const OptionSubHeading = styled(SubHeading)`
  grid-area: optionSubHeading;
  padding-top: 80px;
  padding-bottom: 20px;
  transition: opacity 0.2s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  align-self: end;
  color: ${props => props.theme.color.furthest};
`;

const OptionHeading = styled(Heading)`
  grid-area: optionHeading;
  transition: opacity 0.2s;
  line-height: 1.1;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  padding-bottom: 20px;
  align-self: start;
  color: ${props => props.color};
  font-size: 48px;
  max-width: 600px;

  @media (min-width: 768px) {
    font-size: 46px;
  }
`;

const OptionText = styled.div`
  grid-area: optionText;
  line-height: 30px;
  transition: opacity 0.2s;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  align-self: start;
  font-weight: 420;
  color: ${props => props.theme.color.furthest};
  max-width: 600px;
`;

const defaultColorScheme = {
  background: `linear-gradient(89.88deg, ${theme.color.secondary} 0.1%, ${theme.color.primary} 99.9%);;`,
  heading: theme.color.closest,
  progress: theme.color.furthest,
  inactiveOptionLink: "#B1DBE9",
};

const OptionsSlidesWithProgressBar = ({
  textAlignment = "right",
  options = [],
  colorScheme,
  progressPercentage = 0,
  onOptionNavClick = optionIndex => {},
}) => {
  return (
    <Container background={colorScheme?.background || defaultColorScheme?.background} textAlignment={textAlignment}>
      {options?.map((option, i) => (
        <>
          {option?.imageSources?.map((sourceUrl, j) => (
            <>
              <Graphic
                isVisible={
                  isOptionVisible(i, progressPercentage, options?.length) &&
                  isSourceImageVisible(i, progressPercentage, options?.length, j, option?.imageSources?.length)
                }
                src={sourceUrl}
                alt="graphic"
                textAlignment={textAlignment}
              />
              <GraphicWide
                isVisible={
                  isOptionVisible(i, progressPercentage, options?.length) &&
                  isSourceImageVisible(i, progressPercentage, options?.length, j, option?.imageSources?.length)
                }
                src={sourceUrl?.replace("images/", "images/wide/")}
                alt="graphic"
                textAlignment={textAlignment}
              />
            </>
          ))}
        </>
      ))}
      <OptionsNav textAlignment={textAlignment}>
        {options.map((option, i) => (
          <NavItem
            inactiveColor={colorScheme?.inactiveOptionLink || defaultColorScheme?.inactiveOptionLink}
            onClick={() => onOptionNavClick(i)}
            isHighlighted={isOptionVisible(i, progressPercentage, options?.length)}
            key={i}
          >
            {option?.optionName}
          </NavItem>
        ))}
      </OptionsNav>
      {options.map((option, i) => (
        <OptionSubHeading isVisible={isOptionVisible(i, progressPercentage, options?.length)} key={i}>
          {option?.subHeading}
        </OptionSubHeading>
      ))}
      {options.map((option, i) => (
        <OptionHeading
          // style={{ fontSize: option?.heading === "Asset Management" ? "62px" : "70px" }}
          color={colorScheme?.heading || defaultColorScheme?.heading}
          isVisible={isOptionVisible(i, progressPercentage, options?.length)}
          key={i}
          dangerouslySetInnerHTML={{ __html: option?.heading }}
        />
      ))}
      {options.map((option, i) => (
        <OptionText
          textAlignment={textAlignment}
          isVisible={isOptionVisible(i, progressPercentage, options?.length)}
          key={i}
          dangerouslySetInnerHTML={{ __html: option?.text }}
        />
      ))}
      <VerticalProgressBar
        textAlignment={textAlignment}
        color={colorScheme?.progress || defaultColorScheme?.progress}
        progressPercentage={progressPercentage}
      />
    </Container>
  );
};

export default OptionsSlidesWithProgressBar;
