import { Gap } from "components/Layout";
import SpeedAnimationV2 from "components/SpeedAnimationV2";
import TokensAnimationV2 from "components/TokensAnimationV2";
import styled from "styled-components";

const Container = styled.div`
  display: grid;
  padding: 80px 0;

  display: none;
  @media (min-width: 1240px) {
    display: grid;
  }
`;

const InnerColumn = styled.div`
  width: 1200px;
  margin: 0 auto;
  display: grid;
  gap: 40px;
`;

const ResearchIllustrationSectionV2 = ({ title, subTitle, text }) => {
  return (
    <Container>
      <InnerColumn>
        <SpeedAnimationV2 />
        <Gap height="100px" />
        <TokensAnimationV2 />
      </InnerColumn>
    </Container>
  );
};

export default ResearchIllustrationSectionV2;
