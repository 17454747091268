import { useState } from "react";
import styled from "styled-components";

import { postSurvey } from "api/services/marketingService";
import Button from "components/Button";

const GRID_FIELDS = [
  {
    type: "input",
    placeholder: "Name",
    fieldName: "name",
  },
  {
    type: "input",
    placeholder: "Email *",
    fieldName: "email",
  },
  {
    type: "input",
    placeholder: "Job Role",
    fieldName: "jobRole",
  },
  {
    type: "input",
    placeholder: "Company",
    fieldName: "company",
  },
];

const FIELDS = [
  {
    description: "How do you want to use Gen-Ai in your Every Day Work?",
    type: "radio",
    options: [
      { label: "Data Extraction from Email and Standard Reports" },
      { label: "Using Gen-AI in Word and Excel" },
      { label: "Both" },
      { label: "Other Cases (i.e Batch or Interactive Automation)", hasAdditionalInput: true },
    ],
    fieldName: "useCase",
  },
  {
    description: "If you had a ChatGPT Assistant at work, how would you intend to utilise it?",
    type: "checkbox",
    options: [
      { label: "Write documents and reports" },
      { label: "Summarise documents" },
      { label: "Extract information into Excel Spreadsheets" },
      { label: "Generating Excel Formulas for you" },
      { label: "Other" },
    ],
    subQuestion: "Why would this help you?",
    fieldName: "gptAssistantUseCase",
  },
  {
    description: "What are the job roles of members in your company who will utilise this product?",
    type: "checkbox",
    options: [
      { label: "Data Scientists & Researchers" },
      { label: "Business Analysts" },
      { label: "HR" },
      {
        label: "Other",
      },
    ],
    subQuestion: "Other",
    fieldName: "jobRoles",
  },
  {
    description: "What would be the main type of document you would expect to upload/integrate?",
    type: "checkbox",
    options: [
      { label: "Annual Reports" },
      { label: "Sustainability Reports " },
      { label: "Legal Documents" },
      { label: "Marketing Emails" },
      { label: "Internal Emails" },
      { label: "Other" },
    ],
    subQuestion: "Other",
    fieldName: "mainTypeOfDocument",
  },
];

const Container = styled.div`
  position: relative;
`;

const BlurryBackground = styled.div`
  position: fixed;
  top: -100px;
  z-index: -1;
  display: block;
  background-image: url("/images/color1.jpeg");
  background-size: cover;
  background-position: center;
  width: 100%;
  height: calc(100% + 100px);

  filter: blur(2px);
`;

const Input = styled.input`
  padding: 8px 10px;
  font-size: 16px;
  font-family: "Montserrat";
  outline: none;
  border: 4px solid;
  border-image: linear-gradient(${props => props.theme.color.primary}, ${props => props.theme.color.primary});

  width: 100%;
`;

const TextArea = styled.textarea`
  padding: 8px 10px;
  margin-top: 0px;
  font-size: 14px;
  font-family: "Montserrat";
  outline: none;
  border: 4px solid;
  border-image: linear-gradient(${props => props.theme.color.primary}, ${props => props.theme.color.primary});
  resize: none;
  height: 42px;
  width: 100%;
`;

const InputsContainer = styled.div`
  margin-left: auto;
  margin-right: auto;
  max-width: 800px;
  backdrop-filter: blur(4px);

  padding: 20px;
  background-color: ${props => props.theme.color.closer0}DD;
  display: grid;
  gap: 20px;

  border: 4px solid;
  border-image: linear-gradient(${props => props.theme.color.primary}, ${props => props.theme.color.primary});
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.4);

  @media (min-width: 768px) {
    margin-top: 100px;
    margin-bottom: 100px;
  }
`;

const Title = styled.div`
  font-weight: 500;
  font-size: 32px;
  justify-self: center;
  text-align: center;
  line-height: 1.2;
`;

const TopLogoImg = styled.img`
  height: 80px;
  justify-self: center;
`;

const QuestionText = styled.div`
  margin-top: 40px;
  line-height: 1.2;
`;

const StyledButton = styled(Button)`
  align-self: start;
  padding: 8px 14px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
  justify-self: center;
  font-size: 16px;
`;

const ThankYouText = styled.div`
  text-align: center;
  height: 40px;
`;

const RadioButtons = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 12px;
`;

const OptionLabel = styled.label`
  display: flex;
  gap: 2px;
  font-size: 16px;
  align-items: center;
  cursor: pointer;
  line-height: 1.2;
  width: max-content;
  width: 100%;
  input {
    margin: 0;
  }

  @media (min-width: 768px) {
    width: auto;
  }
`;

const SubQuestion = styled.div`
  font-size: 16px;
  margin-top: -4px;
`;

const InputsGrid = styled.div`
  margin-top: 20px;
  display: grid;
  gap: 10px;
  grid-template-columns: auto auto;
`;

const PrivacyTickContainer = styled.label`
  margin-top: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  input {
    margin: 0;
  }
`;

const ROOT_FIELDS = ["name", "email", "jobRole", "company"];

const SurveyPage = () => {
  const [formFields, setFormFields] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [hasBeenSubmitted, setHasBeenSubmitted] = useState(false);
  const [error, setError] = useState(null);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);

  const doSubmitForm = async () => {
    setIsLoading(true);

    const body = {
      otherFields: {},
    };
    Object.keys(formFields).forEach(fieldName => {
      if (ROOT_FIELDS.includes(fieldName)) {
        body[fieldName] = formFields[fieldName];
        return;
      }
      body.otherFields[fieldName] = formFields[fieldName];
    });

    const { error } = await postSurvey({}, body);

    setError(error);
    setIsLoading(false);
    setHasBeenSubmitted(true);

    if (!error) {
      window.location.href = "https://boltzbit.com/";
    }
  };

  return (
    <Container>
      <BlurryBackground />
      <InputsContainer>
        <TopLogoImg src="/images/logo-black.png" />
        <Title>Let us know what you think</Title>
        <InputsGrid>
          {GRID_FIELDS?.map(({ placeholder, fieldName, type }, i) => (
            <Input
              placeholder={placeholder}
              value={formFields?.[fieldName] || ""}
              onChange={e => {
                setFormFields({ ...formFields, [fieldName]: e.target.value });
              }}
            />
          ))}
        </InputsGrid>
        {FIELDS?.map(({ description, fieldName, type, placeholder, options, subQuestion }, i) => (
          <>
            {description && <QuestionText>{description}</QuestionText>}
            {type === "input" && (
              <Input
                placeholder={placeholder}
                value={formFields?.[fieldName] || ""}
                onChange={e => {
                  setFormFields({ ...formFields, [fieldName]: e.target.value });
                }}
              />
            )}
            {type === "radio" && (
              <RadioButtons>
                {options?.map(({ label, hasAdditionalInput }) => {
                  const isChecked = formFields?.[fieldName]?.label === label;

                  return (
                    <>
                      <OptionLabel>
                        <input
                          checked={isChecked}
                          onChange={() => {
                            setFormFields({ ...formFields, [fieldName]: { label } });
                          }}
                          type="radio"
                          name={`radio-group-${i}`}
                        />
                        {label}
                      </OptionLabel>
                      {isChecked && hasAdditionalInput && (
                        <TextArea
                          value={formFields?.[fieldName]?.additionalInput || ""}
                          onChange={e => {
                            setFormFields({
                              ...formFields,
                              [fieldName]: { ...formFields?.[fieldName], additionalInput: e.target.value },
                            });
                          }}
                          placeholder="Please give more details"
                        />
                      )}
                    </>
                  );
                })}
              </RadioButtons>
            )}
            {type === "checkbox" && (
              <>
                <RadioButtons>
                  {options?.map(({ label }) => {
                    const isChecked = formFields?.[fieldName]?.labels?.includes(label);

                    return (
                      <OptionLabel>
                        <input
                          checked={isChecked}
                          onChange={() => {
                            setFormFields({
                              ...formFields,
                              [fieldName]: {
                                ...formFields?.[fieldName],
                                labels: isChecked
                                  ? formFields?.[fieldName]?.labels?.filter(l => l !== label)
                                  : [...(formFields?.[fieldName]?.labels || []), label],
                              },
                            });
                          }}
                          type="checkbox"
                          name={`radio-group-${i}`}
                        />
                        {label}
                      </OptionLabel>
                    );
                  })}
                </RadioButtons>
                {subQuestion && (
                  <>
                    {/* <SubQuestion>{subQuestion}</SubQuestion> */}
                    <TextArea
                      value={formFields?.[fieldName]?.subQuestionAnswer || ""}
                      onChange={e => {
                        setFormFields({
                          ...formFields,
                          [fieldName]: { ...formFields?.[fieldName], subQuestionAnswer: e.target.value },
                        });
                      }}
                      placeholder="Please give more details"
                    />
                  </>
                )}
              </>
            )}
          </>
        ))}
        <PrivacyTickContainer>
          <input
            checked={isPrivacyPolicyAccepted}
            onChange={() => setIsPrivacyPolicyAccepted(!isPrivacyPolicyAccepted)}
            type="checkbox"
          />
          <div style={{ fontSize: "14px" }}>
            * I accept the{" "}
            <a style={{ fontSize: "14px" }} href="https://boltzbit.com/privacy-policy/" target="_blank">
              Privacy Policy
            </a>
          </div>
        </PrivacyTickContainer>

        {hasBeenSubmitted && !error ? (
          <ThankYouText>Thank you!</ThankYouText>
        ) : (
          <StyledButton
            isDisabled={isLoading || !formFields?.email || !isPrivacyPolicyAccepted}
            variant="highlighted"
            value="Submit"
            onClick={doSubmitForm}
          />
        )}
        {error && <div style={{ color: "salmon" }}>{error?.message}</div>}
      </InputsContainer>
    </Container>
  );
};

export default SurveyPage;
