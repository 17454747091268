import Button from "components/Button";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  padding-top: 80px;
  padding-bottom: 200px;
`;

const StartButton = styled(Button)`
  margin-left: auto;
  margin-right: auto;
  padding: 12px 80px;
  color: white;
  background: ${props =>
    `linear-gradient(to right, ${props.theme.color.primary} 0%, ${props.theme.color.secondary} 100%)`};

  :hover {
    opacity: 0.8;
  }
`;

const StartNow = ({ bg }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <StartButton
        bg={bg}
        variant="highlighted"
        value="Contact sales"
        onClick={() => {
          navigate("contact-business");
        }}
      />
    </Container>
  );
};

export default StartNow;
