import { getBlog, postPageVisit } from "api/backend/marketingServiceEndpoints";
import TopBarV2 from "components/TopBarV2";
import { useEffect } from "react";
import { useState } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import styled from "styled-components";
import { marked } from "marked";
import { Gap } from "components/Layout";
import { formatSafe, getCookieByName, urlParamsToObject, uuidv4 } from "utils/common";
import FooterSectionV2 from "components/FooterSectionV2";

const Container = styled.div`
  padding-top: 60px;
  display: grid;
  justify-content: center;
  justify-items: center;
  gap: 10px;
`;

const Content = styled.div`
  width: 800px;
  min-height: 100px;
  line-height: 1.5;
  p,
  li,
  strong,
  table,
  td,
  th {
    font-size: 16px;
  }
  strong {
    font-weight: 600;
  }
  td,
  th {
    text-align: left;
    padding: 8px 12px;
    border: 1px solid #eaeaea;
  }
  th {
    font-weight: 600;
  }
  h2 {
    font-size: 32px;
    font-weight: 600;
  }
  h3 {
    font-size: 24px;
    font-weight: 600;
  }
`;

const Title = styled.div`
  width: 800px;
  text-align: center;
  font-size: 40px;
  font-weight: 700;
  line-height: 1.2;

  background: linear-gradient(132.27deg, #424242 48.26%, #7a7979 64.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
`;

const SubTitle = styled.div`
  color: #424242;
`;

const DateText = styled.div`
  color: #424242;
`;

const usePostPageVisit = () => {
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (window?.location?.hostname === "localhost") {
      return;
    }

    let tempUserId = getCookieByName("tempUserId") || uuidv4();
    document.cookie = `tempUserId=${tempUserId}; path=/; max-age=31536000`;
    const meta = urlParamsToObject(searchParams.entries());
    postPageVisit({}, { tempUserId, url: window.location.pathname, meta });
  }, []);
};

const BlogPage = () => {
  const [searchParams] = useSearchParams();
  const { blogId } = useParams();
  const [blog, setBlog] = useState([]);
  const [htmlStr, setHtmlStr] = useState("");

  usePostPageVisit();

  useEffect(() => {
    doPopulateBlogs();
  }, []);

  const doPopulateBlogs = async () => {
    const { data } = await getBlog(blogId);
    setBlog(data || []);
    setHtmlStr(marked(data?.content || ""));
  };

  const token = searchParams.get("token");

  if (token !== "bzabc") {
    return null;
  }

  return (
    <Container>
      <TopBarV2 colorScheme="dark" backgroundColor="black" />
      <Gap height="40px" />
      <Title>{blog?.name}</Title>
      <Gap height="20px" />
      <SubTitle>{blog?.subtitle}</SubTitle>
      <DateText>{formatSafe(blog?.meta?.dateStr, "d MMMM yyy")}</DateText>
      <Gap height="40px" />
      <Content
        dangerouslySetInnerHTML={{
          __html: htmlStr,
        }}
      />
      <Gap height="40px" />
      <div style={{ width: "100vw" }}>
        <FooterSectionV2 />
      </div>
    </Container>
  );
};

export default BlogPage;
