import styled from "styled-components";
import { useEffect, useState } from "react";

import Footer from "components/Footer";
import TopNav from "components/TopNav";
import Button from "components/Button";
import { postContactUsDetails } from "api/services/marketingService";

const SpeakToUsText = styled.div`
  font-size: 16px;
  font-weight: 700;
`;

const Container = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1fr;
  max-width: 1200px;
  column-gap: 80px;
  row-gap: 40px;
  margin: 60px auto;
  margin-top: 40px;
  margin-bottom: 120px;
`;

const Title = styled.div`
  font-size: 40px;
  font-weight: 600;
  text-align: center;
`;

const Img = styled.img`
  width: 100%;
  justify-self: center;
  object-fit: scale-down;
`;

const ContactFieldsCard = styled.div`
  grid-row: span 2;
  width: 100%;

  background-color: ${props => props.theme.color.closer0};
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin: 0 auto;
  height: max-content;
`;

const StatusMsg = styled.div`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  height: 16px;
  transition: opacity 0.2s;
  font-size: 14px;
`;

const Input = styled.input`
  width: 100%;
  padding: 10px 15px;
  font-size: 16px;
  font-family: "Montserrat";
  outline: none;
  border: 2px solid;
  border-image: linear-gradient(${props => props.theme.color.primary}, ${props => props.theme.color.primary});
`;

const TextArea = styled.textarea`
  width: 100%;
  resize: none;
  outline: none;
  font-size: 16px;
  padding: 10px 15px;
  height: 150px;
  font-family: "Montserrat";
  border: 2px solid;
  border-image: linear-gradient(${props => props.theme.color.primary}, ${props => props.theme.color.primary});
`;

const StyledButton = styled(Button)`
  align-self: start;
  padding: 8px 14px;
  background-color: transparent;
  color: ${props => props.theme.color.closest};
`;

const BtnAndMsg = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: space-between;
  padding-top: 20px;
`;

const SmallText = styled.div`
  font-size: 12px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 16px;
`;

const ContactBusinessPage = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [role, setRole] = useState("");
  const [industry, setIndustry] = useState("");
  const [phone, setPhone] = useState("");
  const [country, setCountry] = useState("");

  const [isAgreed, setIsAgreed] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <TopNav />
      <Container>
        <Title>Contact sales</Title>
        <ContactFieldsCard>
          <SpeakToUsText>Start your beta test now</SpeakToUsText>
          <div style={{ display: "flex", gap: "8px" }}>
            <Input value={firstName} onChange={e => setFirstName(e.target.value)} placeholder="First name *" />
            <Input value={lastName} onChange={e => setLastName(e.target.value)} placeholder="Last name *" />
          </div>
          <div style={{ display: "flex", gap: "8px" }}>
            <Input placeholder="Company" value={company} onChange={e => setCompany(e.target.value)} />

            <Input placeholder="Role" value={role} onChange={e => setRole(e.target.value)} />
          </div>
          <Input placeholder="Industry" value={industry} onChange={e => setIndustry(e.target.value)} />
          <Input placeholder="Phone" value={phone} onChange={e => setPhone(e.target.value)} />
          <Input value={email} onChange={e => setEmail(e.target.value)} placeholder="Work email *" />
          <Input placeholder="Country / Region" value={country} onChange={e => setCountry(e.target.value)} />
          <Label>
            <input type="checkbox" checked={isAgreed} onChange={e => setIsAgreed(e.target.checked)} />I agree to the
            Service Agreement *
          </Label>
          <SmallText>
            By registering, you confirm that you agree to the processing of your personal data by Boltzbit as described
            in the Privacy Statement.
          </SmallText>
          <BtnAndMsg>
            <StyledButton
              variant="highlighted"
              isDisabled={isSubmitting || !isAgreed || !email || !firstName || !lastName}
              onClick={async () => {
                setStatusMsg("");
                setIsSubmitting(true);
                const { error } = await postContactUsDetails({
                  firstName,
                  lastName,
                  email,
                  company,
                  role,
                  industry,
                  phone,
                  country,
                  product: "flow-businees",
                });
                if (error) {
                  setStatusMsg(error?.message);
                } else {
                  setStatusMsg("Your details have been submitted.");
                }
                setIsSubmitting(false);
              }}
              value="Submit"
            />
            <StatusMsg isVisible={!!statusMsg}>{statusMsg}</StatusMsg>
          </BtnAndMsg>
        </ContactFieldsCard>
        <Img src="/images/flow-contact-us.png" />
      </Container>
      <Footer />
    </>
  );
};

export default ContactBusinessPage;
