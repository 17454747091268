import React from "react";
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import styled from "styled-components";

const data = [
  {
    name: "",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

const Container = styled.div`
  width: 500px;
  height: 300px;
`;

const BenchmarksChart = () => {
  return (
    <Container>
      <LineChart
        width={500}
        height={300}
        data={data}
        margin={{
          top: 35,
          right: 10,
          left: 0,
          bottom: 0,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="name" />
        <YAxis />
        {/* <Tooltip />
        <Legend /> */}
        <Line dataKey="pv" stroke="#8884d8" activeDot={{ r: 8 }} />
        <Line dataKey="uv" stroke="#82ca9d" />
      </LineChart>
    </Container>
  );
};

export default BenchmarksChart;
