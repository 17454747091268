import { COLOR2, GRADIENT_TEXT, GRADIENT_TEXT_FLIPPED } from "pages/main-v2";
import styled from "styled-components";

const BORDER_STYLE_WHITE = `
  border-radius: 24px;
  background: linear-gradient(0deg, rgba(255, 255, 255, 0) 0%, #ffffff 100%) padding-box,
    linear-gradient(to bottom, #d1d1d1, #ffffff00 60%) border-box;
  border: 1px solid transparent;
`;

const Container = styled.div`
  margin: 0 auto;
  margin-top: 80px;
  margin-bottom: 40px;
  padding: 0 20px;

  @media (min-width: 1240px) {
    padding: 0;
    width: 1200px;
  }

  @media (max-width: 1240px) {
    margin-top: 200px;
  }
`;

const Title = styled.div`
  font-size: 36px;
  font-weight: 600;
  margin: 0 auto;
  padding-bottom: 40px;

  @media (min-width: 1240px) {
    width: 1350px;
    font-size: 36px;
  }
`;

const Papers = styled.div`
  display: grid;
  gap: 80px;
  margin-top: 40px;
  margin: 0 auto;
  grid-template-columns: 1fr 1fr;
  width: 100%;
  height: max-content;

  @media (min-width: 1240px) {
    flex-direction: row;
    gap: 20px;
  }

  @media (max-width: 1240px) {
    grid-template-columns: 1fr;
  }
`;

const PaperTitle = styled.div`
  font-size: 24px;
  font-weight: 500;
  line-height: 1.2;
  height: 3.8em;
  overflow: hidden;
  text-overflow: ellipsis;
  color: black;

  @media (max-width: 1240px) {
    height: auto;
  }
`;

const Paper = styled.a`
  background-color: #ffffff;
  padding: 45px;
  height: 200px;
  border-radius: 20px;
  align-content: start;
  gap: 32px;

  display: grid;
  text-decoration: none;
  * {
    text-decoration: none;
  }

  @media (min-width: 1240px) {
    width: 100%;
  }

  ${BORDER_STYLE_WHITE}

  @media (max-width: 1240px) {
    height: auto;
  }
`;

const Date = styled.div`
  font-size: 24px;
  font-weight: 600;
  color: black;
  width: max-content;
  ${GRADIENT_TEXT_FLIPPED};
`;

const DownloadText = styled.a`
  font-size: 16px;
  font-weight: 500;
  color: black;
  width: max-content;
  align-self: end;
  color: ${COLOR2};
  :hover {
    text-decoration: underline;
  }
`;

const WaveGraphic = styled.img`
  width: 100%;
  margin-top: -100px;
  pointer-events: none;

  opacity: 0;
  @media (min-width: 1240px) {
    opacity: 1;
  }
`;

const ResearchPapersSectionV2 = ({ title = "", papers = [] }) => {
  return (
    <>
      <Container>
        <Title>{title}</Title>
        <Papers>
          {papers?.map(paper => (
            <Paper>
              <Date>{paper?.date}</Date>
              <PaperTitle>{paper?.title}</PaperTitle>
              <DownloadText href={paper?.url} target="_blank">
                Download paper ▸
              </DownloadText>
            </Paper>
          ))}
        </Papers>
      </Container>
      <WaveGraphic src="/images/waves-research.png" />
    </>
  );
};

export default ResearchPapersSectionV2;
