import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: block;
  @media (min-width: 1240px) {
    display: block;
  }

  color: black;
  background-color: #f5f5f7;
  height: 100vh;
  align-content: center;
`;

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  gap: 80px;
  padding: 0px 20px;
  margin: 0 auto;

  @media (min-width: 1240px) {
    grid-template-columns: 1fr 2fr;
    width: 1200px;
    padding: 0px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  line-height: 1.5;
  font-weight: 400;
  text-align: left;
  color: #121314;
`;

const Text = styled.div`
  font-size: 38px;
  line-height: 1.5;
  font-style: normal;
  font-weight: 500;
  text-align: right;
`;

const RightText = styled.div`
  font-size: 38px;
  line-height: 1.5;
  font-style: normal;
  font-weight: 400;
  text-align: right;
`;

const StyledA = styled.a`
  color: white;
  text-decoration: underline;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
`;

const StyledLink = styled(Link)`
  width: max-content;
  color: black;
  text-decoration: underline;
  ${props => props.isDisabled && `opacity: 0.2; pointer-events: none;`}
  text-align: right;
  justify-self: end;
`;

const MessageSectionV1 = ({ title, leftTitle = "", text, isLinkVisible = false }) => {
  return (
    <Container>
      <Content>
        <Text style={{ textAlign: "left" }}>{leftTitle}</Text>
        <RightText
          style={leftTitle ? { textAlign: "left", fontSize: "32px" } : {}}
          dangerouslySetInnerHTML={{
            __html: text,
          }}
        />
        <Title>{title}</Title>
        {isLinkVisible && <StyledLink to="/research">Learn more</StyledLink>}
      </Content>
    </Container>
  );
};

export default MessageSectionV1;
