import { Gap } from "components/Layout";
import styled from "styled-components";

const Container = styled.div`
  color: black;

  display: grid;
  justify-content: center;
  align-items: start;
  align-content: start;
  min-height: 788px;
  height: 100vh;

  @media (max-width: 1240px) {
    min-height: 1800px;
  }
`;

const Title = styled.div`
  padding-top: 120px;
  text-align: center;
  font-family: Montserrat;
  font-size: 40px;
  margin-bottom: 80px;
  font-style: normal;
  font-weight: 400;
  font-weight: 600;

  background: linear-gradient(132.27deg, #424242 48.26%, #7a7979 64.59%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: center;
  justify-items: center;
  gap: 20px;
  align-content: start;
  align-items: start;
  /* justify-content: center; */

  @media (min-width: 1240px) {
    width: 1000px;
    grid-template-columns: 1fr 1fr 1fr;
  }

  @media (max-width: 1240px) {
    margin-bottom: 200px;
  }
`;

const PersonName = styled.div`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  color: black;
`;

const Role = styled.div`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 400;
  text-align: left;
  color: black;
`;

const Description = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
`;

const ShortDescription = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  color: #747474;
`;

const PersonImage = styled.img`
  height: 230px;
  width: 230px;
  object-fit: cover;
  border-radius: 24px;
`;

const Person = styled.a`
  display: grid;
  gap: 12px;
  text-decoration: none;
  filter: grayscale(100%);
  transition: filter 0.2s, opacity 0.2s;
  :hover {
    filter: grayscale(0%);
    /* opacity: 0.6; */
  }
`;

const PeopleSectionV2 = ({ title, people = [] }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Columns>
        {people?.map(person => (
          <Person href={person.url} target="_blank">
            <PersonImage src={person?.imageSrc} />
            <Gap />
            <PersonName>{person?.name}</PersonName>
            <Role>{person?.role}</Role>
            <Gap />
            <ShortDescription
              dangerouslySetInnerHTML={{
                __html: person?.shortDescription,
              }}
            />
          </Person>
        ))}
      </Columns>
    </Container>
  );
};

export default PeopleSectionV2;
