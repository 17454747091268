import styled from "styled-components";
import { LinkedinIcon, XIcon } from "components/ui/Icons";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 20px;
  padding-top: 40px;
  color: white;
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 100vw;
  /* padding-bottom: 1000px; */

  @media (min-width: 1240px) {
    padding: 0 40px;
    padding-top: 0px;
    width: 1200px;
  }
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 60px;
  gap: 15px;

  @media (min-width: 768px) {
    gap: 0;
    flex-wrap: nowrap;
  }
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  svg {
    fill: white;
    stroke: white;
  }

  @media (min-width: 768px) {
    justify-content: end;
  }
`;

const SeparatorContainer = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 5px 1fr 5px;
  gap: 8px;
  align-items: center;
  padding-bottom: 30px;
`;

const Box = styled.div`
  width: 5px;
  height: 5px;
  background-color: white;
  /* background-color: ${props => props.theme.color.primary}; */
`;

const Line = styled.div`
  height: 1px;
  background-color: ${props => props.theme.color.closer0};
`;

const BottomSeparator = () => (
  <SeparatorContainer>
    <Box />
    <Line />
    <Box />
  </SeparatorContainer>
);

const BottomLinks = styled.div`
  width: 100%;
  display: grid;
  gap: 20px;
  padding-bottom: 30px;
  justify-content: start;

  @media (min-width: 1240px) {
    grid-auto-flow: column;
  }
`;

const GreyText = styled.div`
  font-size: 10px;
  color: #b4b4b4;
`;

const GreyLink = styled.a`
  font-size: 10px;

  text-decoration: none;
  color: #b4b4b4;
  cursor: pointer;

  :hover {
    color: white;
  }
`;

const IconA = styled.a`
  :hover {
    opacity: 0.8;
  }
`;

const FooterSectionV1 = () => {
  return (
    <Container>
      <BottomLinks>
        <GreyText>© 2022 Copyright All Right Reserved.</GreyText>
        <GreyText>Boltzbit Ltd</GreyText>
        <GreyLink target="_blank" href="https://boltzbit.com/terms/">
          Terms & Conditions
        </GreyLink>
        <GreyLink target="_blank" href="https://boltzbit.com/privacy-policy/">
          Privacy Policy
        </GreyLink>
      </BottomLinks>
      <LinksContainer>
        <SocialIcons>
          <IconA href="https://twitter.com/boltzbit?lang=en" target="_blank" rel="noreferrer">
            <XIcon />
          </IconA>
          <IconA href="https://uk.linkedin.com/company/boltzbit" target="_blank" rel="noreferrer">
            <LinkedinIcon height="18px" />
          </IconA>
        </SocialIcons>
      </LinksContainer>
    </Container>
  );
};

export default FooterSectionV1;
