import styled from "styled-components";

const Container = styled.div`
  color: black;

  display: grid;
  justify-content: center;
  align-items: start;
  align-content: start;
  min-height: 100vh;
`;

const Title = styled.div`
  padding-top: 120px;
  text-align: justify;
  font-family: Montserrat;
  font-size: 40px;
  margin-bottom: 40px;
  font-style: normal;
  font-weight: 400;
`;

const Columns = styled.div`
  display: grid;
  grid-template-columns: auto;
  justify-content: space-between;
  gap: 20px;
  align-content: start;
  align-items: start;
  /* justify-content: center; */

  @media (min-width: 1240px) {
    width: 1200px;
    grid-template-columns: auto auto auto;
  }
`;

const PersonName = styled.div`
  font-family: Montserrat;
  font-size: 22px;
  font-weight: 600;
  text-align: left;
  color: black;
`;

const Role = styled.div`
  font-family: Montserrat;
  font-size: 16px;
  font-weight: 600;
  text-align: left;
  color: #497ff3;
`;

const Description = styled.div`
  font-family: Montserrat;
  font-size: 14px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
`;

const ShortDescription = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 1.5;
  text-align: left;
  color: #747474;
`;

const PersonImage = styled.img`
  height: 300px;
  width: 300px;
  object-fit: cover;
  border-radius: 10px;
`;

const Person = styled.a`
  display: grid;
  gap: 12px;
  text-decoration: none;
  transition: opacity 0.2s;
  :hover {
    opacity: 0.8;
  }
`;

const PeopleSection = ({ title, people = [] }) => {
  return (
    <Container>
      <Title>{title}</Title>
      <Columns>
        {people?.map(person => (
          <Person href={person.url} target="_blank">
            <PersonImage src={person?.imageSrc} />
            <PersonName>{person?.name}</PersonName>
            <Role>{person?.role}</Role>
            <ShortDescription>{person.shortDescription}</ShortDescription>
          </Person>
        ))}
      </Columns>
    </Container>
  );
};

export default PeopleSection;
