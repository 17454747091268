import { getFlowDatabaseTemplates } from "api/services/marketingService";
import SectionsViewer from "components/SectionsViewer";
import SectionsViewerMobileMinimal from "components/SectionsViewerMobileMinimal";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import styled from "styled-components";

const SECTIONS = [
  {
    type: "research-title",
    title: "We innovate to make a brighter GenAI future",
    subTitle: "We build the future, one GenAI solution at a time",
    isShowingCareersLink: true,
  },
  {
    type: "message-v1",
    leftTitle: "Our Goal",
    text: "GenAI should be <span style='color: #497FF3; font-size: inherit;'>accessible to all business</span>. Such a powerful technology must be in the hands of as many people as possible.",
  },
  {
    type: "three-cards",
    title: "Our Values",
    isButtonHidden: true,
    columns: [
      {
        title: "Support each other",
        text: "We work as a team to support each other's work and our customers.",
      },
      {
        title: "Take ownership",
        text: "The best results come from taking ownership of your work and your team's work.",
      },
      {
        title: "Build cool stuff",
        text: "We're here to build the coolest GenAI solutions on the planet.",
      },
    ],
  },
  {
    type: "people",
    title: "Leadership",
    people: [
      {
        name: "Dr. Yichuan Zhang",
        role: "CEO & Co-founder",
        shortDescription: "Ex-Google, University of Cambridge",
        imageSrc: "/images/yichuan.png",
        url: "https://uk.linkedin.com/in/dr-yichuan-zhang-46074955",
      },
      {
        name: "Dr. Jinli Hu",
        role: "CTO & Co-founder",
        shortDescription: "University of Edinburgh",
        imageSrc: "/images/jinli.png",
        url: "https://uk.linkedin.com/in/arkjinli",
      },
      {
        name: "Ollie Gough",
        role: "COO",
        shortDescription: "",
        imageSrc: "/images/ollie.png",
        url: "https://uk.linkedin.com/in/ollie-gough-75950990",
      },
    ],
  },
  {
    type: "teams",
    title: "Our Teams",
    description:
      "We are researchers, engineers, and operational leaders, with experience spanning a variety of disciplines, all working together to build reliable GenAI systems.",
    teams: [
      {
        title: "Research",
        imageSrc: "/images/research.png",
        description:
          "Our research team is made up of experts in machine learning, computer vision, and natural language processing, who are constantly pushing the boundaries of what’s possible with GenAI.",
      },
      {
        title: "Business",
        imageSrc: "/images/research.png",
        description:
          "Our business team is responsible for building relationships with our customers, ensuring that they have the support they need to get the most out of our GenAI solutions.",
      },
      {
        title: "Product",
        imageSrc: "/images/research.png",
        description:
          "The product team is responsible for turning our research into real-world solutions, working closely with our customers to understand their needs and build products that solve their problems.",
      },
      {
        title: "Engineering",
        imageSrc: "/images/research.png",
        description:
          "The engineering team is responsible for building and maintaining the infrastructure that powers our GenAI solutions, ensuring that they are reliable, scalable, and secure.",
      },
    ],
  },
  {
    type: "footer-v1",
  },
];

const DesktopContainer = styled.div`
  /* display: none; */
`;

const MobileContainer = styled.div``;

const Container = styled.div`
  /* @media (min-width: 1240px) {
    ${DesktopContainer} {
      display: block;
    }
    ${MobileContainer} {
      display: none;
    }
  } */
`;

const AboutUsPage = () => {
  const [searchParams] = useSearchParams();
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    doPopulateTemplates();
  }, []);

  const doPopulateTemplates = async () => {
    const { data } = await getFlowDatabaseTemplates();
    setTemplates(data || []);
  };

  const token = searchParams.get("token");

  // if (token !== "0ced6987-57da-4c35-938d-418acc5d328a") {
  //   return null;
  // }

  return (
    <Container>
      <DesktopContainer>
        <SectionsViewer sections={SECTIONS} templates={templates} isFooterHidden />
      </DesktopContainer>
      {/* <MobileContainer>
        <SectionsViewerMobileMinimal sections={SECTIONS} templates={templates} isFooterHidden />
      </MobileContainer> */}
    </Container>
  );
};

export default AboutUsPage;
