import styled from "styled-components";

import { Gap, Heading, SubHeading } from "components/Layout";
import { CalendarDownIcon, CalendarUpIcon, CommentTickIcon, GearIcon, ProcessorIcon } from "components/ui/Icons";
import { range } from "lodash";

const iconNameToComponent = {
  processor: <ProcessorIcon />,
  gear: <GearIcon />,
  comment_tick: <CommentTickIcon />,
  calendar_down: <CalendarDownIcon />,
  calendar_up: <CalendarUpIcon />,
};

const ColumnsSectionContainer = styled.div`
  display: grid;
  justify-content: center;
  justify-items: center;
  align-content: center;
  width: 100%;
  /* height: 100vh; */
  transition: opacity 0.4s ease-in-out;
  margin-top: 100px;
`;

const ColumnsContainer = styled.div`
  display: grid;
  max-width: 800px;
  row-gap: 20px;
  column-gap: 60px;
  justify-items: center;
`;

const ColumnIcon = styled.div`
  padding-bottom: 10px;
  svg {
    fill: ${props => props.theme.color.primary};
  }
`;

const ColumnText = styled.div`
  line-height: 1.5;
  text-align: center;
`;

const ColumnSubHeading = styled(SubHeading)`
  line-height: 1.2;
  text-align: center;

  ${props =>
    props.isGradientColor &&
    `
  background-image: linear-gradient(to right, ${props.theme.color.secondary}, ${props.theme.color.primary});
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent; 
  -moz-background-clip: text;
  -moz-text-fill-color: transparent;
  `};
`;

const TimelineContainer = styled.div`
  width: 1280px;
`;

const TimelineHeading = styled.div`
  background: ${props => props.bg};
  padding: 12px;
  font-weight: 600;
  color: white;
  text-align: left;
  font-size: 20px;
`;

const StageHeading = styled.div`
  font-weight: 600;
  font-size: 22px;
  padding-bottom: 20px;
`;

const StageText = styled.div`
  font-size: 18px;
  line-height: 1.2;
`;

const StageSubText = styled.div`
  padding-top: 20px;
  font-weight: 600;
`;

const StagesContainer = styled.div`
  display: flex;
  border: 1px solid ${props => props.theme.color.closer2}22;
  border-top: none;
  border-right: none;
`;

const Stage = styled.div`
  padding: 10px;
  text-align: center;
  border-right: 1px solid ${props => props.theme.color.closer2}22;
`;

const ColumnsAndTimeline = ({ heading = "", columns = [], timeline = { heading: "", headingBg: "", stages: [] } }) => {
  return (
    <ColumnsSectionContainer>
      <Heading style={{ fontSize: "60px", textAlign: "center" }}>{heading}</Heading>
      <Gap height="40px" />
      <ColumnsContainer>
        {/* {range(columns.length).map(i => (
          <ColumnIcon>{iconNameToComponent?.[columns?.[i]?.icon]}</ColumnIcon>
        ))}
        {range(columns.length).map(i => (
          <ColumnSubHeading>{columns?.[i]?.heading}</ColumnSubHeading>
        ))} */}
        {range(columns.length).map(i => (
          <ColumnText
            dangerouslySetInnerHTML={{
              __html: columns?.[i]?.text,
            }}
          />
        ))}
      </ColumnsContainer>
      <Gap height="80px" />
      <TimelineContainer>
        <TimelineHeading bg={timeline?.headingBg}>{timeline?.heading}</TimelineHeading>
        <StagesContainer>
          {timeline?.stages?.map(stage => (
            <Stage>
              <StageHeading>{stage?.heading}</StageHeading>
              <StageText>{stage?.text}</StageText>
              <StageSubText>{stage?.subText}</StageSubText>
            </Stage>
          ))}
        </StagesContainer>
      </TimelineContainer>
    </ColumnsSectionContainer>
  );
};

export default ColumnsAndTimeline;
