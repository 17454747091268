import { useState } from "react";
import styled from "styled-components";

import Button from "components/Button";
import { postNewsletterEmail } from "api/services/marketingService";
import { FacebookIcon, InstagramIcon, LinkedinIcon } from "components/ui/Icons";

const Container = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding: 0 40px;
  padding-bottom: 10px;

  margin-top: 60px;
  @media (min-width: ${props => props.theme.breakpoint.lg}) {
    margin-top: 0;
  }
`;

const SocialIcon = styled.div`
  width: 20px;
  height: 20px;
  background-color: lightgrey;
  grid-column: ${props => props.gridColumn};
`;

const LinksContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: start;
  padding-bottom: 60px;
  gap: 15px;

  @media (min-width: 768px) {
    gap: 0;
    flex-wrap: nowrap;
  }
`;

const JoinCommunityText = styled.div`
  font-size: 10px;
  font-weight: 600;
  padding-right: 20px;
  white-space: nowrap;
`;

const EmailInput = styled.input`
  height: 40px;
  max-width: 240px;
  border-radius: 0;
  border: 1px solid ${props => props.theme.color.closer0};
  padding: 0 15px;
  font-family: "Montserrat";
`;

const BlackButton = styled(Button)`
  background: ${props => props.theme.color.closest};
  color: ${props => props.theme.color.furthest};
  padding: 0 40px;
  height: 40px;
  font-weight: 400;
`;

const SocialIcons = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;

  @media (min-width: 768px) {
    justify-content: end;
  }
`;

const SeparatorContainer = styled.div`
  grid-column: span 2;
  display: grid;
  grid-template-columns: 5px 1fr 5px;
  gap: 8px;
  align-items: center;
  padding-bottom: 30px;
`;

const Box = styled.div`
  width: 5px;
  height: 5px;
  background-color: ${props => props.theme.color.primary};
`;

const Line = styled.div`
  height: 1px;
  background-color: ${props => props.theme.color.closer0};
`;

const BottomSeparator = () => (
  <SeparatorContainer>
    <Box />
    <Line />
    <Box />
  </SeparatorContainer>
);

const BottomLinks = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: auto auto auto 1fr;
  gap: 20px;
  padding-bottom: 30px;
`;

const GreyText = styled.div`
  font-size: 10px;
`;

const GreyLink = styled.a`
  font-size: 10px;

  text-decoration: none;
  color: ${props => props.theme.color.closest};

  :hover {
    color: ${props => props.theme.color.closer2};
  }
`;

const StatusMsg = styled.div`
  opacity: ${props => (props.isVisible ? 1 : 0)};
  height: 16px;
  transition: opacity 0.2s;
  white-space: nowrap;
  padding-left: 20px;
`;

const Footer = () => {
  const [email, setEmail] = useState("");

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [statusMsg, setStatusMsg] = useState("");

  return (
    <Container>
      <LinksContainer>
        <JoinCommunityText>Join the Community</JoinCommunityText>
        <EmailInput value={email} onChange={e => setEmail(e.target.value)} placeholder="Sign-up to newsletter" />
        <BlackButton
          value="Submit"
          isDisabled={isSubmitting}
          onClick={async () => {
            setStatusMsg("");
            setIsSubmitting(true);
            const { error } = await postNewsletterEmail({ email });
            if (error) {
              setStatusMsg(error?.message);
            } else {
              setStatusMsg("Your details have been submitted.");
            }
            setIsSubmitting(false);
          }}
        />
        <StatusMsg isVisible={!!statusMsg}>{statusMsg}</StatusMsg>
        <SocialIcons>
          <FacebookIcon />
          <LinkedinIcon />
          <InstagramIcon />
        </SocialIcons>
      </LinksContainer>
      <BottomSeparator />
      <BottomLinks>
        <GreyText>© Copyright Boltzbit 2024</GreyText>
        <GreyLink href="/flow/contact">Contact</GreyLink>
      </BottomLinks>
    </Container>
  );
};

export default Footer;
