import styled from "styled-components";

import { Gap } from "components/Layout";
import { BoltzbitLogoWithText, ChevronDown } from "components/ui/Icons";
import ButtonDark from "components/ButtonDark";
import ContactUsModal from "components/ContactUsModal";

const BackgroundContainer = styled.div`
  background-image: linear-gradient(
    135deg,
    ${props => props.theme.color.secondary},
    ${props => props.theme.color.primary}
  );
`;

const Content = styled.div`
  height: 100vh;
  display: grid;
  justify-content: center;
  align-content: center;
  gap: 40px;
  color: white;
  transition: opacity 1s;
`;

const TopBar = styled.div`
  position: fixed;
  top: 0;
  padding: 20px;
  z-index: 1;
  transition: opacity 1s;
`;

const Title = styled.div`
  font-size: 48px;
  padding: 0 20px;
  text-align: center;
  font-weight: 400;
  line-height: 1.2;

  background-image: linear-gradient(135deg, #ffffff 0%, #d9d9d9 40%, #787368 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const SubTitle = styled.div`
  font-size: 18px;
  font-weight: 300;
  text-align: center;
  line-height: 1.4;

  background-image: linear-gradient(0deg, #ffffff 0%, #d9d9d9 40%, #c4bdae 90%);
  background-clip: text;
  -webkit-text-fill-color: transparent;
`;

const ButtonsContainer = styled.div`
  display: flex;
  gap: 20px;
  justify-content: center;
  flex-wrap: wrap;
`;

const StyledChevronDown = styled(ChevronDown)`
  position: absolute;
  bottom: 40px;
  justify-self: center;
  margin-top: 100px;
  cursor: pointer;
  :hover {
    transform: scale(1.1);
  }
`;

const TopBarLogo = styled(BoltzbitLogoWithText)`
  cursor: pointer;
`;

const HeroSectionMobile = ({ title, subTitle, buttons = [] }) => {
  return (
    <BackgroundContainer>
      <TopBar>
        <TopBarLogo
          isGlowDisabled
          style={{
            height: "72px",
            fill: "white",
          }}
          onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
        />
      </TopBar>

      <Content
        style={{
          fill: "white",
        }}
      >
        <Gap />
        <Title>{title}</Title>
        <SubTitle>{subTitle}</SubTitle>
        <ButtonsContainer>
          <ContactUsModal
            triggerComponent={
              <ButtonDark style={{ width: "120px", height: "42px", fontSize: "14px" }}>{buttons?.[0].text}</ButtonDark>
            }
          />
          <ButtonDark
            style={{ width: "120px", height: "42px", fontSize: "14px" }}
            onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
          >
            {buttons?.[1].text}
          </ButtonDark>
        </ButtonsContainer>
        {/* <StyledChevronDown
          onClick={() => window.scrollTo({ top: window.innerHeight, behavior: "smooth" })}
          style={{ height: "40px", stroke: "white", strokeWidth: "2px" }}
        /> */}
      </Content>
    </BackgroundContainer>
  );
};

export default HeroSectionMobile;
