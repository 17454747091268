import { Gap } from "components/Layout";
import { BoltzbitLogo, BoltzhubLogoInner } from "components/ui/Icons";
import { COLOR1, COLOR2 } from "pages/main-v2";
import { useEffect } from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: none;
  @media (min-width: 1240px) {
    display: block;
  }

  position: relative;
  color: white;
  width: 100vw;
`;

const Content = styled.div`
  display: grid;
  align-content: center;
  gap: 0px;
  margin: 0 auto;
  padding: 80px 20px;

  @media (min-width: 1240px) {
    padding: 80px 0;
    width: 1200px;
  }
`;

const Title = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;

  @media (min-width: 1240px) {
    font-size: 51.41px;
    line-height: 56px;
  }
`;

const SubTitle = styled.div`
  font-family: Montserrat;
  font-size: 42px;
  font-weight: 500;
  color: #ffffff;
  text-align: center;

  span {
    font-size: inherit;
  }

  .gradient-text {
    background: linear-gradient(88.57deg, ${COLOR1} 17.21%, ${COLOR2} 62.45%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
    text-shadow: 0px 0px 25px rgba(0, 0, 0, 0.5);
  }
`;

const WordsLine = styled.div`
  display: flex;
  gap: 18px;
  left: 0;
  padding-bottom: 16px;
  max-width: calc(100vw - 40px);
  flex-wrap: wrap;

  @media (min-width: 1240px) {
    max-width: none;
    flex-wrap: nowrap;
    justify-content: center;
  }
`;

const Word = styled.div`
  gap: 10px;
  box-sizing: border-box;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  font-size: 15px;

  display: flex;
  align-items: center;
  text-align: center;
  cursor: default;
  transition: color 0.2s, opacity 0.2s;
  z-index: 1;
  color: #4f4f4f;
  opacity: ${props => props.alpha};

  white-space: nowrap;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px 24px;
  gap: 10px;

  border: 1px solid #b9b9b9;
  border-radius: 10px;

  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 21px;
  text-align: center;

  color: #ffffff;

  :hover {
    background: linear-gradient(transparent, transparent) padding-box,
      linear-gradient(to right, ${COLOR1}, ${COLOR2}) border-box;
    border: 1px solid transparent;
  }

  ${props =>
    props.isHighlighted &&
    `
    background: linear-gradient(#1c2027, #1c2027) padding-box,
      linear-gradient(to right, ${COLOR1}, ${COLOR2}) border-box;
    border-radius: 10px;
    border: 1px solid transparent;
  `}
`;

const Svg = styled.svg`
  position: absolute;
  width: 100vw;
  top: 40px;
  left: 40px;
  z-index: 0;

  display: none;
  @media (min-width: 1240px) {
    display: block;
  }
`;

const Cloud = styled.div`
  margin-top: 40px;
  margin-bottom: 50px;
`;

const BacklitPanel = styled.div`
  position: relative;
  width: 133px;
  height: 133px;
  justify-self: center;
`;

const Backlight = styled.div`
  position: absolute;
  background: linear-gradient(14.79deg, ${COLOR1} -0.75%, ${COLOR1} 10.66%, ${COLOR2} 25.22%, ${COLOR2} 44.11%);
  filter: blur(11.1px);
  border-radius: 20px;
  width: 133px;
  height: 133px;
`;

const PanelRect = styled.div`
  position: absolute;
  width: 133px;
  height: 133px;

  background: linear-gradient(273.79deg, #414149 2.81%, #1e1e1e 97.01%);
  box-shadow: 0px 0px 4.5px 3px rgba(255, 255, 255, 0.25);
  border-radius: 20px;
`;

const PanelLogo = styled(BoltzhubLogoInner)`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -42%);
  height: 70px;
  fill: #ffffff;
`;

const WordCloudSectionV2 = ({ title = "", subTitle = "", cloudLines = [], highlights = [] }) => {
  return (
    <Container>
      {/* <BgGrid /> */}
      <Content>
        <BacklitPanel>
          <Backlight />
          <PanelRect />
          <PanelLogo />
        </BacklitPanel>
        <Gap height="60px" />
        <Title
          dangerouslySetInnerHTML={{
            __html: title,
          }}
        />
        <Gap height="10px" />
        <SubTitle
          dangerouslySetInnerHTML={{
            __html: subTitle,
          }}
        />
        <Gap height="60px" />
        <Cloud>
          {cloudLines.map((line, i) => (
            <WordsLine key={i}>
              {line.map((word, j) => (
                <Word key={j} isHighlighted={highlights?.[i]?.[j]}>
                  {word}
                </Word>
              ))}
            </WordsLine>
          ))}
        </Cloud>
        {/* <Title
          style={{ justifySelf: "end", textAlign: "right" }}
          dangerouslySetInnerHTML={{
            __html: subTitle,
          }}
        /> */}
      </Content>
    </Container>
  );
};

export default WordCloudSectionV2;
