import styled from "styled-components";

const VerticalProgressBar = styled.div.attrs(props => ({
  style: {
    background: `linear-gradient(
        180deg,
        ${props.color} ${props.progressPercentage}%,
        rgba(255, 255, 255, 0.2) ${props.progressPercentage}%,
        rgba(255, 255, 255, 0.2) 100%
      )`,
  },
}))`
  grid-area: progressBar;
  justify-self: ${props => (props.textAlignment === "right" ? "end" : "start")};
  width: 2px;
  height: 100%;
`;

export default VerticalProgressBar;
