import Button from "components/Button";
import { RoundTickIcon } from "components/ui/Icons";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const Container = styled.div`
  display: flex;
  justify-content: center;
  padding-bottom: 80px;
  margin-top: 150px;
`;

const Table = styled.table`
  width: 90%;
  border-collapse: separate;
  border-spacing: 0 4px;
`;

const Td = styled.td`
  max-width: 780px;
  text-overflow: ellipsis;
  padding: 12px 16px;
  background-color: ${props => (props?.isLighter ? `${props.theme.color.closer0}77` : props.theme.color.closer0)};
  vertical-align: middle;
  line-height: 1.2;
  position: relative;

  ${props =>
    props.isPriceCell &&
    `
    font-weight: bold;
  `}
`;

const CornerRect = styled.div`
  position: absolute;
  width: 4px;
  height: 4px;
  background-color: ${props => props.theme.color.highlightGrey};
`;

const Tr = styled.tr`
  ${props => props.isDisabled && "opacity: 0.5; pointer-events: none;"}
`;

const HeaderTr = styled.tr`
  color: white;
  background: ${props => props.headerBg};
  font-weight: 600;
  text-align: center;

  ${Td} {
    background-color: transparent;
  }
`;

const ButtonTd = styled.td`
  padding: 2px;
  padding-left: 17px;
`;

const SmallButton = styled(Button)`
  padding: 10px 32px;
  background: ${props => props.theme.color.furthest};
  :hover {
    background: linear-gradient(
      to right,
      ${props => props.theme.color.secondary},
      ${props => props.theme.color.primary}
    );
    color: white;
  }
`;

const BlueRoundTickIcon = styled(RoundTickIcon)`
  fill: ${props => props.theme.color.primary};
`;

const GreyRoundTickIcon = styled(RoundTickIcon)`
  fill: ${props => props.theme.color.closer2}33;
`;

const cellIcon = {
  tick: <BlueRoundTickIcon />,
  "": <GreyRoundTickIcon />,
};

const TableSection = ({ rows, headerBg = "linear-gradient(to right, #9650ff 0%, #3769c8 100%)" }) => {
  const navigate = useNavigate();

  return (
    <Container>
      <Table>
        <thead>
          <HeaderTr headerBg={headerBg}>
            {rows?.[0]?.map(cell => (
              <Td>{cell}</Td>
            ))}
          </HeaderTr>
        </thead>
        <tbody>
          {rows?.slice(1).map((row, rowIndex) => (
            <Tr key={rowIndex}>
              {row?.map((cell, columnIndex) => (
                <Td
                  isLighter={rowIndex % 2 === 0}
                  isPriceCell={rowIndex === rows?.length - 2}
                  style={{ textAlign: columnIndex === 0 ? "left" : "center" }}
                >
                  {columnIndex === 0 && (
                    <>
                      <CornerRect style={{ top: 0, left: 0 }} />
                      <CornerRect style={{ bottom: 0, left: 0 }} />
                    </>
                  )}
                  {columnIndex === rows?.[0].length - 1 && (
                    <>
                      <CornerRect style={{ top: 0, right: 0 }} />
                      <CornerRect style={{ bottom: 0, right: 0 }} />
                    </>
                  )}
                  {cellIcon[cell] || cell}
                </Td>
              ))}
            </Tr>
          ))}
          <Tr>
            {rows?.[0]?.map((_, columnIndex) => {
              if (columnIndex === 0) {
                return <td />;
              }

              return (
                <ButtonTd>
                  <SmallButton
                    value="Join Waitlist"
                    onClick={() => {
                      navigate("contact");
                    }}
                  />
                </ButtonTd>
              );
            })}
          </Tr>
        </tbody>
      </Table>
    </Container>
  );
};

export default TableSection;
